<template>
	<div>
		<div id="main-text">
			<span>伝票登録（入庫）</span>
		</div>
		<div id="main-contents">
            <div class="step-contents" style="margin-bottom: 20px">
				<div class="step">
					<div class="step-circle" onclick="javascript:history.back()">
						<span><img src="../assets/img/check.png"></span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" onclick="javascript:history.back()">
						Step 1
					</div>
					<div class="step-word" style="font-weight: bold;">
						Step 2
					</div>
				</div>
			</div>
			<div id="list-contents" style="height: calc(100% - 100px); padding-top: 0px">
                <div class="step2-title">
                    {{ getItemCategoryNameById(registerParams.customer_id) }}
                </div>
                <div style="overflow: auto; height: calc(100% - 60px);">
                    <table class="list-table" style="table-layout: initial;">
                        <thead style="position: sticky; top: 0; z-index: 2;">
                            <tr class="list-table-header">
                                <th>
                                    <label class="check" for="all_check">
                                        <input type="checkbox" id="all_check" style="display: none;" v-model="all_check">
                                        <span class="checkbox" style="background-color: white;"><img v-if="all_check" src="../assets/img/checkbox.png"></span>
                                    </label>
                                </th>
                                <th>伝票番号</th>
                                <th>種別</th>
                                <th>商品名</th>
                                <th>管理番号</th>
                                <th>旧管理番号</th>
                                <th>単価</th>
                                <th>数量</th>
                                <th>入庫日</th>
                                <th colspan="2">備考</th>
                                <th>件数</th>
                                <th>合計数</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="slip_list.length !== 0">
                                <!-- チェックのみ表示 -->
                                <template v-if="filter_check && !is_search">
                                    <template v-for="(item, index) in slip_list">
                                        <!-- 他社はアコーディオンなし -->
                                        <template v-if="item.item_list === undefined || item.item_list === null">
                                            <tr class="list-table-row" :key="'list_1_' + index" v-if="item.is_check" @click="toggleAccordion(index)">
                                                <td>
                                                    <label class="check" :for="'slip_check0' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check0' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.old_control_number === null || item.old_control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.old_control_number">{{ item.old_control_number }}</span>
                                                </td>
                                                <td>
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" @input="item.price = inputNumberMaxLength(item.price, 9)" v-model="item.price">
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="item.count = inputNumberMaxLength(item.count, 9)" v-model="item.count">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                        <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                    </div>
                                                </td>
                                                <td colspan="4">
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="text" maxlength="200" v-model="item.note">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr class="list-table-row" :key="'list_1__' + index" @click="toggleAccordion(index)">
                                                <td>
                                                    <label class="check" :for="'slip_check1' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check1' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {{ accordionLength(item.item_list, true) }}
                                                </td>
                                                <td>
                                                    {{ accordionCount(item.item_list, true) }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-if="item.item_list !== undefined && item.item_list !== null">
                                            <template v-for="(data, data_index) in item.item_list">
                                                <tr :class="'list-table-row table-accordion accordion_' + index" style="background: #dddddd33" v-if="data.is_check" :key="'accordion_' + index + '_' + data_index">
                                                    <td>
                                                        <label class="check" :for="'slip_check1' + index + '_' + data_index" @change="toggleCheck(index, data.is_check ? false : true, data_index)">
                                                            <input type="checkbox" :id="'slip_check1' + index + '_' + data_index" style="display: none;" :checked="data.is_check">
                                                            <span class="checkbox" style="background-color: white;"><img v-if="data.is_check" src="../assets/img/checkbox.png"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_id === null || data.slip_id === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.slip_id">{{ data.slip_id }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_detail_type === null || data.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="slip_detail_label[data.slip_detail_type]">{{ slip_detail_label[data.slip_detail_type] }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.name === null || data.name === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.name">{{ data.name }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.control_number === null || data.control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.control_number">{{ data.control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.old_control_number === null || data.old_control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.old_control_number">{{ data.old_control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="data.price = inputNumberMaxLength(data.price, 9)" v-model="data.price">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="number" @input="data.count = inputNumberMaxLength(data.count, 9)" v-model="data.count">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.count === null || data.count === ''" v-tooltip="'1'">1</span>
                                                            <span v-else v-tooltip="data.count">{{ data.count }}</span>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="data.start_date"></datepicker>
                                                        </div>
                                                    </td>
                                                    <td colspan="4">
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="text" maxlength="200" v-model="data.note">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.note === null || data.note === ''" v-tooltip="'-'">-</span>
                                                            <span v-else v-tooltip="data.note">{{ data.note }}</span>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                                <!-- 検索結果表示 -->
                                <template v-else-if="!filter_check && is_search">
                                    <template v-for="(item, index) in slip_list">
                                        <!-- 他社はアコーディオンなし -->
                                        <template v-if="item.item_list === undefined || item.item_list === null">
                                            <tr class="list-table-row" :key="'list_2_' + index" v-if="item.is_search" @click="toggleAccordion(index)">
                                                <td>
                                                    <label class="check" :for="'slip_check1' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check1' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.old_control_number === null || item.old_control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.old_control_number">{{ item.old_control_number }}</span>
                                                </td>
                                                <td>
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" @input="item.price = inputNumberMaxLength(item.price, 9)" v-model="item.price">
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="item.count = inputNumberMaxLength(item.count, 9)" v-model="item.count">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                        <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                    </div>
                                                </td>
                                                <td colspan="4">
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="text" maxlength="200" v-model="item.note">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr class="list-table-row" :key="'list_2__' + index" @click="toggleAccordion(index)">
                                                <td>
                                                    <label class="check" :for="'slip_check2' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check2' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {{ accordionLength(item.item_list, false) }}
                                                </td>
                                                <td>
                                                    {{ accordionCount(item.item_list, false) }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-if="item.item_list !== undefined && item.item_list !== null">
                                            <template v-for="(data, data_index) in item.item_list">
                                                <tr :class="'list-table-row table-accordion accordion_' + index" style="background: #dddddd33" v-if="data.is_search" :key="'accordion_' + index + '_' + data_index">
                                                    <td>
                                                        <label class="check" :for="'slip_check2' + index + '_' + data_index" @change="toggleCheck(index, data.is_check ? false : true, data_index)">
                                                            <input type="checkbox" :id="'slip_check2' + index + '_' + data_index" style="display: none;" :checked="data.is_check">
                                                            <span class="checkbox" style="background-color: white;"><img v-if="data.is_check" src="../assets/img/checkbox.png"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_id === null || data.slip_id === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.slip_id">{{ data.slip_id }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_detail_type === null || data.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="slip_detail_label[data.slip_detail_type]">{{ slip_detail_label[data.slip_detail_type] }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.name === null || data.name === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.name">{{ data.name }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.control_number === null || data.control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.control_number">{{ data.control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.old_control_number === null || data.old_control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.old_control_number">{{ data.old_control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="data.price = inputNumberMaxLength(data.price, 9)" v-model="data.price">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="number" @input="data.count = inputNumberMaxLength(data.count, 9)" v-model="data.count">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.count === null || data.count === ''" v-tooltip="'1'">1</span>
                                                            <span v-else v-tooltip="data.count">{{ data.count }}</span>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="data.start_date"></datepicker>
                                                        </div>
                                                    </td>
                                                    <td colspan="4">
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="text" maxlength="200" v-model="data.note">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.note === null || data.note === ''" v-tooltip="'-'">-</span>
                                                            <span v-else v-tooltip="data.note">{{ data.note }}</span>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                                <!-- チェック済み検索結果表示 -->
                                <template v-else-if="filter_check && is_search">
                                    <template v-for="(item, index) in slip_list">
                                        <!-- 他社はアコーディオンなし -->
                                        <template v-if="item.item_list === undefined || item.item_list === null">
                                            <tr class="list-table-row" :key="'list_3_' + index" v-if="item.is_check && item.is_search" @click="toggleAccordion(index)">
                                                <td>
                                                    <label class="check" :for="'slip_check3' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check3' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.old_control_number === null || item.old_control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.old_control_number">{{ item.old_control_number }}</span>
                                                </td>
                                                <td>
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" @input="item.price = inputNumberMaxLength(item.price, 9)" v-model="item.price">
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="item.count = inputNumberMaxLength(item.count, 9)" v-model="item.count">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                        <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                    </div>
                                                </td>
                                                <td colspan="4">
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="text" maxlength="200" v-model="item.note">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr class="list-table-row" :key="'list_3__' + index" @click="toggleAccordion(index)">
                                                <td>
                                                    <label class="check" :for="'slip_check3' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check3' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {{ accordionLength(item.item_list, null, true) }}
                                                </td>
                                                <td>
                                                    {{ accordionCount(item.item_list, null, true) }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-if="item.item_list !== undefined && item.item_list !== null">
                                            <template v-for="(data, data_index) in item.item_list">
                                                <tr :class="'list-table-row table-accordion accordion_' + index" style="background: #dddddd33" v-if="data.is_check && data.is_search" :key="'accordion_' + index + '_' + data_index">
                                                    <td>
                                                        <label class="check" :for="'slip_check3' + index + '_' + data_index" @change="toggleCheck(index, data.is_check ? false : true, data_index)">
                                                            <input type="checkbox" :id="'slip_check3' + index + '_' + data_index" style="display: none;" :checked="data.is_check">
                                                            <span class="checkbox" style="background-color: white;"><img v-if="data.is_check" src="../assets/img/checkbox.png"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_id === null || data.slip_id === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.slip_id">{{ data.slip_id }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_detail_type === null || data.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="slip_detail_label[data.slip_detail_type]">{{ slip_detail_label[data.slip_detail_type] }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.name === null || data.name === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.name">{{ data.name }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.control_number === null || data.control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.control_number">{{ data.control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.old_control_number === null || data.old_control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.old_control_number">{{ data.old_control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="data.price = inputNumberMaxLength(data.price, 9)" v-model="data.price">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="number" @input="data.count = inputNumberMaxLength(data.count, 9)" v-model="data.count">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.count === null || data.count === ''" v-tooltip="'1'">1</span>
                                                            <span v-else v-tooltip="data.count">{{ data.count }}</span>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="data.start_date"></datepicker>
                                                        </div>
                                                    </td>
                                                    <td colspan="4">
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="text" maxlength="200" v-model="data.note">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.note === null || data.note === ''" v-tooltip="'-'">-</span>
                                                            <span v-else v-tooltip="data.note">{{ data.note }}</span>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-for="(item, index) in slip_list">
                                        <!-- 他社はアコーディオンなし -->
                                        <template v-if="item.item_list === undefined || item.item_list === null">
                                            <tr class="list-table-row" :key="'list_4_' + index">
                                                <td>
                                                    <label class="check" :for="'slip_check4' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check4' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.old_control_number === null || item.old_control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.old_control_number">{{ item.old_control_number }}</span>
                                                </td>
                                                <td>
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" @input="item.price = inputNumberMaxLength(item.price, 9)" v-model="item.price">
                                                    </div>
                                                </td>
                                                <td>
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="item.count = inputNumberMaxLength(item.count, 9)" v-model="item.count">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                        <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                    </div>
                                                </td>
                                                <td colspan="4">
                                                    <template v-if="item.rental_stock_id !== null">
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="text" maxlength="200" v-model="item.note">
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                    </template>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-else>
                                            <tr class="list-table-row" :key="'list_4__' + index" @click="toggleAccordion(index)">
                                                <td>
                                                    <label class="check" :for="'slip_check4' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                        <input type="checkbox" :id="'slip_check4' + index" style="display: none;" :checked="item.is_check">
                                                        <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td></td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {{ item.item_list.length + "件" }}
                                                </td>
                                                <td>
                                                    {{ getCount(item.item_list) + "個" }}
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-if="item.item_list !== undefined && item.item_list !== null">
                                            <template v-for="(data, data_index) in item.item_list">
                                                <tr :class="'list-table-row table-accordion accordion_' + index" style="background: #dddddd33" :key="'accordion_' + index + '_' + data_index">
                                                    <td>
                                                        <label class="check" :for="'slip_check4' + index + '_' + data_index" @change="toggleCheck(index, data.is_check ? false : true, data_index)">
                                                            <input type="checkbox" :id="'slip_check4' + index + '_' + data_index" style="display: none;" :checked="data.is_check">
                                                            <span class="checkbox" style="background-color: white;"><img v-if="data.is_check" src="../assets/img/checkbox.png"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_id === null || data.slip_id === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.slip_id">{{ data.slip_id }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.slip_detail_type === null || data.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="slip_detail_label[data.slip_detail_type]">{{ slip_detail_label[data.slip_detail_type] }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.name === null || data.name === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.name">{{ data.name }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.control_number === null || data.control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.control_number">{{ data.control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <span v-if="data.old_control_number === null || data.old_control_number === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="data.old_control_number">{{ data.old_control_number }}</span>
                                                    </td>
                                                    <td>
                                                        <div class="input" style="margin-top: 0px">
                                                            <input type="number" @input="data.price = inputNumberMaxLength(data.price, 9)" v-model="data.price">
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="number" @input="data.count = inputNumberMaxLength(data.count, 9)" v-model="data.count">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.count === null || data.count === ''" v-tooltip="'1'">1</span>
                                                            <span v-else v-tooltip="data.count">{{ data.count }}</span>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="data.start_date"></datepicker>
                                                        </div>
                                                    </td>
                                                    <td colspan="4">
                                                        <template v-if="data.rental_stock_id !== null">
                                                            <div class="input" style="margin-top: 0px">
                                                                <input type="text" maxlength="200" v-model="data.note">
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <span v-if="data.note === null || data.note === ''" v-tooltip="'-'">-</span>
                                                            <span v-else v-tooltip="data.note">{{ data.note }}</span>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
			</div>
			<div class="contents-filter" style="height: calc(100% - 100px);">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							伝票番号
						</div>
						<div class="input">
							<input type="text" placeholder="伝票番号" maxlength="63" v-model="filter_params.slip_id" @blur="searchWarehousingSlip()">
						</div>
					</div>
                    <div class="filter">
						<div class="title">
							商品名
						</div>
						<div class="input">
							<input type="text" placeholder="商品名" maxlength="64" v-model="filter_params.name" @blur="searchWarehousingSlip()">
						</div>
					</div>
					<div class="filter">
						<div class="title">
							管理番号
						</div>
						<div class="input">
							<input type="tel" placeholder="管理番号" maxlength="64" v-model="filter_params.control_number" @blur="searchWarehousingSlip()">
						</div>
					</div>
                    <div class="filter">
						<div class="title">
							旧管理番号
						</div>
						<div class="input">
							<input type="tel" placeholder="旧管理番号" maxlength="64" v-model="filter_params.old_control_number" @blur="searchWarehousingSlip()">
						</div>
					</div>
                    <div class="filter" style="text-align: right">
                        <label class="check" for="filter_check">
                            <div class="filter-check">
                                選択中のみ表示
                            </div>
                            <input type="checkbox" id="filter_check" style="display: none;" v-model="filter_check">
                            <span class="checkbox" style="background-color: white;"><img v-if="filter_check" style="margin-left: 0px" src="../assets/img/checkbox.png"></span>
                        </label>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-prev" onclick="javascript:history.back()">戻る</button>
            <button class="button-next" @click="preview()">確認</button>
		</div>
        <div id="preview-modal" v-show="is_preview" @click.self="is_preview = false">
            <div id="preview-modal-background">
                <div id="preview-modal-header">
                    <span class="title">確認</span>
                    <img src="../assets/img/close.png" @click="is_preview = false">
                </div>
                <div id="preview-modal-content">
                    <div id="preview-modal-main-contents">
                        <div id="list-contents" style="width: 100%; height: calc(100% - 100px); padding-top: 0px">
                            <div class="step2-title">
                                {{ getItemCategoryNameById(registerParams.customer_id) }}
                            </div>
                            <table class="list-table">
                                <tr class="list-table-header">
                                    <th>伝票番号</th>
                                    <th>種別</th>
                                    <th>商品名</th>
                                    <th>管理番号</th>
                                    <th>旧管理番号</th>
                                    <th>単価</th>
                                    <th>数量</th>
                                    <th>入庫日</th>
                                    <th>備考</th>
                                </tr>
                            </table>
                            <div class="list-table-scroller">
                                <table class="list-table-contents">
                                    <template v-if="regist_slip_list !== null">
                                        <template v-for="(item, index) in regist_slip_list">
                                            <tr class="list-table-row" :key="'preview' + index">
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.old_control_number === null || item.old_control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.old_control_number">{{ item.old_control_number }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.price === null || item.price === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.price">{{ item.price }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.start_date === null || item.start_date === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="dateFormat(item.start_date)">{{ dateFormat(item.start_date) }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="preview-modal-button">
                <button id="no" @click="is_preview = false">戻る</button>
                <button id="yes" @click="register()">登録</button>
            </div>
        </div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        入力に不備があります。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex'
import { inputNumberMaxLength, SLIP_DETAIL_LABEL } from '../util';
export default {
    components: {
        Datepicker,
    },
	data() {
		return {
            slip_detail_label: SLIP_DETAIL_LABEL,
            slip_list: [],
            regist_slip_list: [],
            filter_params: {
                location_id: null,
                customer_id: null,
                slip_id: null,
                control_number: null,
                old_control_number: null,
                name: null,
            },
            all_check: false,
            filter_check: false,
            is_preview: false,
            is_search: false,
            is_modal: false,
		}
	},
	created() {
        this.filter_params.location_id = this.registerParams.location_id
        this.filter_params.customer_id = this.registerParams.customer_id
        this.getWarehousingSlip()
	},
	mounted() {

	},
	methods: {
        getWarehousingSlip() {
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('slip/getWarehousingSlipApi', this.filter_params))
                .then(() => {
                    let start_date = this.registerParams.period !== null ? this.registerParams.period : this.registerParams.moving_date
                    this.warehousingSlipList.filter(item => {
                        let data = item
                        data.is_check = false
                        data.start_date = start_date
                        if (data.item_list !== undefined && data.item_list !== null) {
                            data.item_list.filter(child => {
                                child.is_check = false
                                child.start_date = start_date
                            })
                        }

                        // rental_stock_idがあったら他社商品なので備考を取得する
                        if (item.rental_stock_id !== null) {
                            this.$store.dispatch('stock/getRentalStockNoteApi', {name: item.name, customer_id: this.registerParams.customer_id})
                                .then(() => {
                                    data.note = this.rentalStockNote
                                })
                        }
                        this.slip_list.push(data)
                    })
                    this.saveParams()
					this.$store.dispatch('loading/endLoad')
				})
        },
        searchWarehousingSlip() {
            this.is_search = true
            this.slip_list.filter((item, index) => {
                item.is_search = false
                if (item.item_list !== undefined && item.item_list !== null) {
                    item.item_list.filter(data => {
                        data.is_search = false
                    })
                }
                // アコーディオンを閉じる
                $('.accordion_' + index).each((index, item) => {
                    $(item).css('display', 'none')
                })
            })
            // slip_idありの場合
            if (this.filter_params.slip_id !== null && this.filter_params.slip_id !== "") {
                // nameあるか確認
                if (this.filter_params.name !== null && this.filter_params.name !== "") {
                    // control_numberあるか確認
                    if (this.filter_params.control_number !== null && this.filter_params.control_number !== "") {
                        // slip_id, name, control_number, old_control_number検索
                        if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                        // slip_id, name, control_number検索
                        else {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                    }
                    else {
                        // slip_id, name, old_control_number検索
                        if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                        // slip_id, name検索
                        else {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                    }
                }
                else if (this.filter_params.control_number !== null && this.filter_params.control_number !== "") {
                    // slip_id, control_number, old_control_number検索
                    if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                    // slip_id, control_number検索
                    else {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                }
                // slip_id, old_control_number検索
                else if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                    this.slip_list.filter(item => {
                        if (item.item_list !== undefined && item.item_list !== null) {
                            item.item_list.filter(data => {
                                if (data.slip_id !== null && data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                    data.is_search = true
                                }
                            })
                        }
                        else {
                            if (item.slip_id !== null && item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                item.is_search = true
                            }
                        }
                    })
                }
                // slip_id検索
                else {
                    this.slip_list.filter(item => {
                        if (item.item_list !== undefined && item.item_list !== null) {
                            item.item_list.filter(data => {
                                if (data.slip_id !== null && data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1) {
                                    data.is_search = true
                                }
                            })
                        }
                        else {
                            if (item.slip_id !== null && item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1) {
                                item.is_search = true
                            }
                        }
                    })
                }
            }
            // nameあり
            else if (this.filter_params.name !== null && this.filter_params.name !== "") {
                if (this.filter_params.slip_id !== null && this.filter_params.slip_id !== "") {
                    if (this.filter_params.control_number !== null && this.filter_params.control_number !== "") {
                        // name, slip_id, control_number, old_control_number検索
                        if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                        // name, slip_id, control_number検索
                        else {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                    }
                    else {
                        // name, slip_id, old_control_number検索
                        if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                        // name, slip_id検索
                        else {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                    }
                }
                else if (this.filter_params.control_number !== null && this.filter_params.control_number !== "") {
                    // name, control_number, old_control_number検索
                    if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                    // name, control_number検索
                    else {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                }
                
                else {
                    // name, old_control_number検索
                    if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.name !== null && data.name.toString().indexOf(this.filter_params.name) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.name !== null && item.name.toString().indexOf(this.filter_params.name) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                    // name検索
                    else {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.name !== null && data.name.toString().indexOf(this.filter_params.name) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.name !== null && item.name.toString().indexOf(this.filter_params.name) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                }
            }
            else if (this.filter_params.control_number !== null && this.filter_params.control_number !== "") {
                if (this.filter_params.slip_id !== null && this.filter_params.slip_id !== "") {
                    if (this.filter_params.name !== null && this.filter_params.name !== "") {
                        // control_number, slip_id, name, old_control_number検索
                        if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                        // control_number, slip_id, name検索
                        else {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                    }
                    else {
                        // control_number, slip_id, old_control_number検索
                        if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                        // control_number, slip_id検索
                        else {
                            this.slip_list.filter(item => {
                                if (item.item_list !== undefined && item.item_list !== null) {
                                    item.item_list.filter(data => {
                                        if (data.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                            data.is_search = true
                                        }
                                    })
                                }
                                else {
                                    if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        item.is_search = true
                                    }
                                }
                            })
                        }
                    }
                }
                
                else if (this.filter_params.name !== null && this.filter_params.name !== "") {
                    // control_number, name, old_control_number検索
                    if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                    // control_number, name検索
                    else {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.name.toString().indexOf(this.filter_params.name) > -1 && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.name.toString().indexOf(this.filter_params.name) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                }
                else {
                    // control_number, old_control_number検索
                    if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.control_number !== null && data.control_number.toString().indexOf(this.filter_params.control_number) > -1 && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.control_number !== null && item.control_number.toString().indexOf(this.filter_params.control_number) > -1 && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                    // control_number検索
                    else {
                        this.slip_list.filter(item => {
                            if (item.item_list !== undefined && item.item_list !== null) {
                                item.item_list.filter(data => {
                                    if (data.control_number !== null && data.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                        data.is_search = true
                                    }
                                })
                            }
                            else {
                                if (item.control_number !== null && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                                    item.is_search = true
                                }
                            }
                        })
                    }
                }
            }
            // old_control_number検索
            else if (this.filter_params.old_control_number !== null && this.filter_params.old_control_number !== "") {
                this.slip_list.filter(item => {
                    if (item.item_list !== undefined && item.item_list !== null) {
                        item.item_list.filter(data => {
                            if (data.old_control_number !== null && data.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                                data.is_search = true
                            }
                        })
                    }
                    else {
                        if (item.old_control_number !== null && item.old_control_number.toString().indexOf(this.filter_params.old_control_number) > -1) {
                            item.is_search = true
                        }
                    }
                })
            }
            else {
                this.is_search = false
            }
        },
        // 取引先IDから取引先名を取得する
		getItemCategoryNameById(id) {
			let result = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
        preview() {
            let error = []
            this.regist_slip_list = []
            this.slip_list.filter(item => {
                // 他社
                if ((item.item_list === undefined || item.item_list === null) && item.rental_stock_id !== null) {
                    if (item.is_check) {
                        if (item.count !== '' && item.count > 0) {
                            this.regist_slip_list.push(item)
                        }
                        else {
                            error.push(true)
                        }
                    }
                }
                // 自社
                else {
                    if (item.item_list !== undefined && item.item_list !== null) {
                        item.item_list.filter(data => {
                            if (data.is_check) {
                                this.regist_slip_list.push(data)
                            }
                        })
                    }
                }
            })
            if (error.length === 0) {
                this.is_preview = true
            }
            else {
                this.is_modal = true
            }
        },
        register() {
            let params = this.registerParams
            params.slip = []
            this.regist_slip_list.filter((item, index) => {
                params.slip.push({
                    stock_id: item.stock_id,
                    rental_stock_id: item.rental_stock_id,
                    export_slip_id: item.slip_detail_id,
                    item_id: item.item_id,
                    count: item.count,
                    price: item.price,
                    note: item.note,
                    start_date: moment(item.start_date).format('YYYY-MM-DD'),
                    name: item.name,
                })
                if (item.in_house_stock_id !== undefined && item.in_house_stock_id !== null) {
                    params.slip[index].in_house_stock_id = item.in_house_stock_id
                }
            })
            this.$store.commit('loading/setIsFull', true)
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('slip/postSlipWarehousingApi', params))
                .then(() => {
                    this.$store.commit('slip/setRegisterParams', null)
					this.$store.dispatch('loading/endLoad')
                    this.$store.commit('loading/setIsFull', false)
                    this.$router.push('slip')
				})
        },
        saveParams() {
            this.$store.commit('slip/setWarehousingSlipList', this.slip_list)
        },
        toggleCheck(index, value, data_index = null) {
            if (data_index !== null) {
                this.slip_list[index].item_list[data_index].is_check = value
            }
            else {
                this.slip_list[index].is_check = value
                if (this.slip_list[index].item_list !== undefined && this.slip_list[index].item_list !== null) {
                    this.slip_list[index].item_list.filter((item) => {
                        item.is_check = value
                    })
                }
            }
            this.$forceUpdate()
        },
        inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        },
        toggleAccordion(index) {
            $('.accordion_' + index).each((index, item) => {
                if ($(item).css('display') === 'none') {
                    $(item).css('display', 'table-row')
                }
                else {
                    $(item).css('display', 'none')
                }
            })
        },
        accordionLength(list, is_check, is_check_search = false) {
            let length = 0
            if (list !== undefined && list !== null) {
                list.filter(item => {
                    if (is_check) {
                        if (item.is_check) {
                            length++
                        }
                    }
                    else if (!is_check) {
                        if (item.is_search) {
                            length++
                        }
                    }
                    else if (is_check === null && is_check_search) {
                        if (item.is_check && item.is_search) {
                            length++
                        }
                    }
                })
            }
            return length + "件"
        },
        accordionCount(list, is_check, is_check_search = false) {
            let count = 0
            if (list !== undefined && list !== null) {
                list.filter(item => {
                    if (is_check) {
                        if (item.is_check) {
                            if (item.count !== null) {
                                count += item.count
                            }
                            else {
                                count++
                            }
                        }
                    }
                    else if (!is_check) {
                        if (item.is_search) {
                            if (item.count !== null) {
                                count += item.count
                            }
                            else {
                                count++
                            }
                        }
                    }
                    else if (is_check === null && is_check_search) {
                        if (item.is_check && item.is_search) {
                            if (item.count !== null) {
                                count += item.count
                            }
                            else {
                                count++
                            }
                        }
                    }
                })
            }
            return count + "個"
        },
        getCount(list) {
            let count = 0
            list.filter(item => {
                if (item.count !== null) {
                    count += item.count
                }
                else {
                    count++
                }
            })
            return count
        },
	},
	computed: {
        language(){
            return ja;
        },
        ...mapState({
            registerParams: state => state.slip.registerParams,
            warehousingSlipList: state => state.slip.warehousingSlipList,
            customerMasterList: state => state.customerMaster.customerMasterList,
            stockList: state => state.stock.stockList,
            rentalStockNote: state => state.stock.rentalStockNote,
        })
	},
    watch: {
        slip_list: {
            handler() {
                this.saveParams()
            },
            deep: true,
        },
        all_check(value) {
            if (this.filter_check && !this.is_search) {
                this.slip_list.filter(item => {
                    if (!item.is_search && item.is_check) {
                        item.is_check = value
                        if (item.item_list !== undefined && item.item_list !== null) {
                            item.item_list.filter(data => {
                                data.is_check = value
                            })
                        }
                    }
                })
            }
            else if (!this.filter_check && this.is_search) {
                this.slip_list.filter(item => {
                    if (item.is_search) {
                        item.is_check = value
                        if (item.item_list !== undefined && item.item_list !== null) {
                            item.item_list.filter(data => {
                                data.is_check = value
                            })
                        }
                    }
                })
            }
            else if (this.filter_check && this.is_search) {
                this.slip_list.filter(item => {
                    if (item.is_search && item.is_check) {
                        item.is_check = value
                        if (item.item_list !== undefined && item.item_list !== null) {
                            item.item_list.filter(data => {
                                data.is_check = value
                            })
                        }
                    }
                })
            }
            else {
                this.slip_list.filter(item => {
                    item.is_check = value
                    if (item.item_list !== undefined && item.item_list !== null) {
                        item.item_list.filter(data => {
                            data.is_check = value
                        })
                    }
                })
            }
        },
        filter_check() {
            // チェックのみ表示のとき、アコーディオン展開状態だとおかしくなるので、切り替えるたびに閉じる
            this.slip_list.filter((item, index) => {
                if (item.item_list !== undefined && item.item_list !== null) {
                    $('.accordion_' + index).each((index, item) => {
                        $(item).css('display', 'none')
                    })
                }
            })
        },
    }
}
</script>

<style scoped src="../assets/css/sleep.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped src="../assets/css/preview.css"></style>
<style scoped>
.list-table-row {
    cursor: default;
}
.filter-check {
    display: inline-block;
    white-space: nowrap;
	line-height: 20px;
	text-align: center;
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: #808495;
    margin-right: 12px;
}
.list-table-row td {
    padding: 2vh 20px;
}
.list-table-row td input[type="number"] {
    width: 50px;
}
.list-table-row td input[type="text"] {
    width: 200px;
}
.register-datepicker {
    width: 100px;
}
</style>