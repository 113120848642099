import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import jQuery from 'jquery'
import store from './store'
import VTooltip from 'v-tooltip'

global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

Vue.use(VTooltip)

new Vue({
  store,
  router: Router,
  render: h => h(App),
  created() {
    const user_data = localStorage.getItem('userData')
		if (user_data !== null) {
			this.$store.commit('login/setUserData', JSON.parse(user_data))
      if (this.$route.path === '/') {
        if (JSON.parse(user_data).auth === 1) {
          this.$router.push('calendar')
        }
        else {
          this.$router.push('stock')
        }
      }
		}
    else {
      this.$router.push('/')
    }
  }
}).$mount('#app')