<template>
	<div id="app">
		<div v-show="loading" :class="isFull ? 'fullview_login' : 'fullview'">
			<Loading />
		</div>
		<Sidebar v-if="$route.path !== '/'" />
		<div v-if="$route.path !== '/'" id="main">
			<router-view />
		</div>
		<router-view v-else />
		<div id="modal" v-show="is_login_error" style="z-index: 1000">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeLoginErrorModal()">
                    <div id="modal-text">
                        ログインしてください。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeLoginErrorModal()">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_file_dl_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeModal()">
                    <div id="modal-text">
                        データが存在しないため、ダウンロードできませんでした。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeModal()">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_slip_in_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeModal()">
                    <div id="modal-text">
                        出庫日より前に入庫日が設定されています。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeModal()">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_slip_group_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeModal()">
                    <div id="modal-text">
                        出庫先、入庫先で同じ事業所が設定されています。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeModal()">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_stock_delete_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeModal()">
                    <div id="modal-text">
                        貸出中の在庫は取り消し出来ません。入庫後に取り消し操作をお願いします。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeModal()">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_stock_delete_status_5_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeModal()">
                    <div id="modal-text">
                        販売済みの在庫は取り消し出来ません。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeModal()">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_slip_delete_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeModal()">
                    <div id="modal-text">
                        既に請求書作成済みの伝票です。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeModal()">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_auth_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="./assets/img/close.png" @click="closeModal()">
                    <div id="modal-text">
                        権限がありません。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="closeModal()">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Sidebar from './components/Sidebar'
import Loading from './components/Loading.vue'
import { error_codes } from './store/error'
import { mapState } from 'vuex'
import { USER_TYPE } from './util'
export default {
	components: {
		Sidebar,
		Loading,
	},
	data() {
		return {
			USER_TYPE: USER_TYPE,
			is_login_error: false,
			is_file_dl_error: false,
			is_slip_in_error: false,
			is_slip_group_error: false,
			is_stock_delete_error: false,
			is_stock_delete_status_5_error: false,
			is_slip_delete_error: false,
			is_auth_error: false,
		}
	},
	created() {
		// 一般ユーザは在庫一覧、在庫更新、請求書一覧のみ遷移可能
		if (this.userData.auth === USER_TYPE.user) {
			if (this.$route.name !== 'stock' && this.$route.name !== 'stock_update' && this.$route.name !== 'invoices') {
				this.$router.push('stock')
			}
		}
	},
	methods: {
		closeModal() {
			this.is_file_dl_error = false
			this.is_slip_in_error = false
			this.is_slip_group_error = false
			this.is_stock_delete_error = false
			this.is_stock_delete_status_5_error = false
			this.is_slip_delete_error = false
			this.is_auth_error = false
			this.$store.commit('error/setErrorCode', null)
			this.$store.commit('error/setApiStatus', false)
			this.$store.dispatch('loading/endLoad')
		},
		closeLoginErrorModal() {
			this.is_login_error = false
			this.$store.commit('error/setErrorCode', null)
			this.$store.commit('error/setApiStatus', false)
			this.$store.dispatch('loading/endLoad')
			this.$router.push('/')
		},
	},
	computed: {
		...mapState({
			errorCode: state => state.error.errorCode,
			userData: state => state.login.userData,
		}),
        loading() {
            return this.$store.getters["loading/loading"];
        },
		isFull() {
			return this.$store.getters["loading/isFull"];
		},
		errorApiStatus() {
			return this.$store.getters['error/apiStatus']
		},
    },
	watch: {
		errorApiStatus(val) {
			if (this.errorCode === error_codes.LOGIN_ERROR) {
				if (val) {
					this.is_login_error = true
				}
			}
			else if (this.errorCode === error_codes.FILE_DL_ERROR) {
				if (val) {
					this.is_file_dl_error = true
				}
			}
			else if (this.errorCode === error_codes.SLIP_IN_ERROR) {
				if (val) {
					this.is_slip_in_error = true
				}
			}
			else if (this.errorCode === error_codes.SLIP_GROUP_ERROR) {
				if (val) {
					this.is_slip_group_error = true
				}
			}
			else if (this.errorCode === error_codes.STOCK_DELETE_ERROR) {
				if (val) {
					this.is_stock_delete_error = true
				}
			}
			else if (this.errorCode === error_codes.STOCK_DELETE_STATUS_5_ERROR) {
				if (val) {
					this.is_stock_delete_status_5_error = true
				}
			}
			else if (this.errorCode === error_codes.SLIP_DELETE_ERROR) {
				if (val) {
					this.is_slip_delete_error = true
				}
			}
			else if (this.errorCode === error_codes.AUTH_ERROR) {
				if (val) {
					this.is_auth_error = true
				}
			}
		},
		$route(value) {
			// 指定画面以外の画面に遷移したら検索条件を破棄する
			// 商品マスタ系
			if (value.name.indexOf('item_master') === -1) {
				this.$store.commit('itemMaster/setFilterParams', null)
			}
			// 商品カテゴリマスタ系
			if (value.name.indexOf('item_category_master') === -1) {
				this.$store.commit('itemCategoryMaster/setFilterParams', null)
			}
			// 取引先マスタ系
			if (value.name.indexOf('customer_master') === -1) {
				this.$store.commit('customerMaster/setFilterParams', null)
			}
			// 現場マスタ系
			if (value.name.indexOf('location_master') === -1) {
				this.$store.commit('locationMaster/setFilterParams', null)
			}
			// 伝票系
			if (value.name.indexOf('slip') === -1) {
				this.$store.commit('slip/setFilterParams', null)
			}
			// 在庫系
			if (value.name.indexOf('stock') === -1) {
				this.$store.commit('stock/setStockFilterParams', null)
				this.$store.commit('stock/setStockHistoryFilterParams', null)
			}
			// 請求系
			if (value.name.indexOf('invoices') === -1) {
				this.$store.commit('invoices/setFilterParams', null)
			}
		}
	},
}
</script>

<style src="./assets/css/tooltip.css"></style>
<style src="./assets/css/modal.css"></style>
<style scope>
body {
	width: 100vw;
	height: 100vh;
	margin: 0;
	background-color: #F0F0F7;
}
#app {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
#main {
	display: inline-block;
	width: calc(100% - 260px);
	height: 100%;
	overflow-y: auto;
}
.fullview {
    margin-left: 260px;
    width: calc(100% - 260px);
    height: 100%;
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 100;
}
.fullview_login {
	width: 100%;
    height: 100%;
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 100;
}
.vdp-datepicker {
	width: 100%;
	height: 40px;
    border: 1px solid #E8E9EC;
    border-radius: 5px;
	position: initial !important;
}
.vdp-datepicker input {
	cursor: default;
	width: calc(100% + 35px);
    height: 40px;
}
.vs__selected {
    height: 100%;
}
.vs__selected-options input {
	cursor: default;
}
input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;
	border: none;
	border-radius: 0;
	font: inherit;
	outline: none;
}
input::placeholder {
	white-space: nowrap;
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	color: #CECFD0;
}
.v-select {
	position: initial !important;
	border: none;
}
.vs__dropdown-toggle {
	width: calc(100% - 8px);
	height: 40px;
	border: none !important;
}
.vs__search {
    min-width: 75px;
}
.vs__search, .vs__search:focus {
	height: 40px;
}
.vs__dropdown-menu {
    position: absolute !important;
    top: auto !important;
    left: auto !important;
    width: auto !important;
	max-width: 90%;
	overflow-x: auto;
}
.vs__actions {
	padding: 4px 0 0 3px;
}
.vdp-datepicker__clear-button {
	position: absolute;
	top: 50%;
	right: 0.5vw;
	transform: translateY(-50%);
	pointer-events: initial;
}
.vdp-datepicker__calendar {
    white-space: initial;
	pointer-events: initial;
}
.vue__time-picker .controls, .vue__time-picker .controls>* {
	display: none;
}
.vue__time-picker {
	width: 100%;
}
.vue__time-picker input.display-time {
	border: none;
	margin-left: 16px;
	width: 100%;
    height: 40px;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button { 
	-webkit-appearance: none; 
	margin: 0; 
} 
input[type="number"] { 
	-moz-appearance:textfield; 
} 
</style>