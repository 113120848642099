<template>
	<div>
		<div id="main-text">
			<span>請求書登録</span>
		</div>
		<div id="main-contents" style="height: auto;">
			<div class="step-contents">
				<div class="step">
					<div class="step-circle">
						<span>1</span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle" @click="toNext()">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" style="font-weight: bold;">
						Step 1
					</div>
					<div class="step-word" @click="toNext()">
						Step 2
					</div>
				</div>
			</div>
			<div class="register-contents" style="margin: 100px auto 0px auto;">
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							請求日
						</div>
						<div class="register-datepicker" ref="date1_1">
							<datepicker ref="datepicker" :language="language" format="yyyy/MM/dd" placeholder="請求日" v-model="params.date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date1_2" @click="showCalendar('datepicker')">
					</div>
					<div class="register-item"></div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							取引先
						</div>
						<div class="input">
							<v-select :options="options_customer" :reduce="options_customer => options_customer.id" v-model="params.customer_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="register-item"></div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							対象期間（FROM）
						</div>
						<div class="register-datepicker" ref="date2_1">
							<datepicker ref="datepicker2" :language="language" format="yyyy/MM/dd" placeholder="対象期間（FROM）" v-model="params.start_date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date2_2" @click="showCalendar('datepicker2')">
					</div>
					<div class="register-item">
						<div class="register-title">
							対象期間（TO）
						</div>
						<div class="register-datepicker" ref="date3_1">
							<datepicker ref="datepicker3" :language="language" format="yyyy/MM/dd" placeholder="対象期間（TO）" v-model="params.end_date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date3_2" @click="showCalendar('datepicker3')">
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
			<button class="button-next" @click="toNext()">次へ</button>
		</div>
		<div id="modal" v-show="is_modal_validation">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal_validation = false">
                    <div id="modal-text">
                        以下の項目が入力されていません。<br>
						<span v-for="(item, index) in error_params" :key="'error_' + index">
							・{{item}}<br>
						</span>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal_validation = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex';
import moment from 'moment';
export default {
    components: {
        Datepicker,
		vSelect,
    },
	data() {
		return {
			params: {
				customer_id: null,
				date: null,
				start_date: null,
				end_date: null,
			},
			required_list: { // 入力必須リスト
				'customer_id': "取引先",
				'date': "請求日",
				'start_date': "対象期間（FROM）",
				'end_date': "対象期間（TO）",
			},
			options_customer: [],
			is_modal_validation: false,
			error_params: [],
			eventHandler: null,
		}
	},
	created() {
		if (this.registerParams !== null) {
			this.params.customer_id = this.registerParams.customer_id
			this.params.date = this.registerParams.date
			this.params.start_date = this.registerParams.start_date
			this.params.end_date = this.registerParams.end_date
		}
		else {
			if (this.$route.query.date !== undefined) {
				this.params.date = this.$route.query.date
				this.params.start_date = moment(this.$route.query.date.split('-')[0] + "-" + this.$route.query.date.split('-')[1], "YYYY-MM").startOf('month').format('YYYY-MM-DD')
				this.params.end_date = moment(this.$route.query.date.split('-')[0] + "-" + this.$route.query.date.split('-')[1], "YYYY-MM").endOf('month').format('YYYY-MM-DD')
			}
			else {
				this.params.start_date = moment().startOf('month').format('YYYY-MM-DD')
				this.params.end_date = moment().endOf('month').format('YYYY-MM-DD')
			}
			if (this.$route.query.customer_id !== undefined) {
				this.params.customer_id = Number(this.$route.query.customer_id)
			}
		}
		this.getCustomerMasterList()
	},
	mounted() {
		window.addEventListener('click', this.eventHandler = (event) => {
			if (!this.$refs.date1_1.contains(event.target) && !this.$refs.date1_2.contains(event.target)) {
				this.$refs.date1_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
					}
				})
			}
			if (!this.$refs.date2_1.contains(event.target) && !this.$refs.date2_2.contains(event.target)) {
				this.$refs.date2_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker2.showCalendar()
					}
				})
			}
			if (!this.$refs.date3_1.contains(event.target) && !this.$refs.date3_2.contains(event.target)) {
				this.$refs.date3_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker3.showCalendar()
					}
				})
			}
		})
	},
	methods: {
		// 検索用取引先マスタ取得
		getCustomerMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('customerMaster/getCustomerMasterApi', { name: null }))
                .then(() => {
					this.customerMasterList.list.filter(item => {
						if (item.floor !== "") {
							this.options_customer.push({label: item.floor, id: item.id})
						}
					})
					this.$store.dispatch('loading/endLoad')
				})
		},
		toNext() {
			this.error_params = []
			// 入力チェック
			Object.keys(this.params).filter(key => {
				Object.keys(this.required_list).filter(required_key => {
					if (required_key === key && (this.params[key] === null || this.params[key] === "")) {
						this.error_params.push(this.required_list[required_key])
					}
				})
			})
			if (this.error_params.length === 0) {
				const date = moment(this.params.date).format('YYYY-MM-DD')
				this.params.date = date
				this.params.start_date = moment(this.params.start_date).format('YYYY-MM-DD')
				this.params.end_date = moment(this.params.end_date).format('YYYY-MM-DD')
				this.$store.commit('invoices/setRegisterParams', this.params)
				this.$router.push('invoices_regist_step2')
			}
			else {
				this.is_modal_validation = true
			}
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
		},
	},
    computed: {
        language(){
            return ja;
        },
		...mapState({
			registerParams: state => state.invoices.registerParams,
			customerMasterList: state => state.customerMaster.customerMasterList,
		})
    },
	watch: {
		params: {
			handler() {
				this.$refs.date1_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
						this.$refs.datepicker.showCalendar()
					}
				})
				this.$refs.date2_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker2.showCalendar()
						this.$refs.datepicker2.showCalendar()
					}
				})
				this.$refs.date3_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker3.showCalendar()
						this.$refs.datepicker3.showCalendar()
					}
				})
			},
			deep : true,
		}
	},
	destroyed() {
		window.removeEventListener('click', this.eventHandler)
	}
}
</script>

<style scoped src="../assets/css/invoices.css"></style>