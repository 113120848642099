<template>
	<div>
		<div id="main-text">
			<span>在庫登録履歴一覧</span>
		</div>
		<div id="main-contents">
			<div id="list-contents">
				<table class="list-table">
					<tr class="list-table-header">
						<th>登録日</th>
						<th>数量</th>
						<th colspan="2">商品</th>
						<th>製品カテゴリ</th>
						<th>事業所名</th>
						<th>登録者</th>
					</tr>
				</table>
				<div class="list-table-scroller">
					<table class="list-table-contents">
						<template v-if="stockHistoryList !== null">
							<template v-for="(item, index) in stockHistoryList.list">
								<tr class="list-table-row" :key="'item_master_' + index" @click="toDetail(item.id)">
									<td>
										<span v-if="item.created_at === null || item.created_at === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.created_at">{{ item.created_at }}</span>
									</td>
									<td>
										<span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.count">{{ item.count }}</span>
									</td>
									<td colspan="2">
										<span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.name">{{ item.name }}</span>
									</td>
									<td>
										<span v-if="item.item_category_id === null || item.item_category_id === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="getItemCategoryNameById(item.item_category_id)">{{ getItemCategoryNameById(item.item_category_id) }}</span>
									</td>
									<td>
										<span v-if="item.office === null || item.office === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.office">{{ item.office }}</span>
									</td>
									<td>
										<span v-if="item.user_id === null || item.user_id === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="getUserNameById(item.user_id)">{{ getUserNameById(item.user_id) }}</span>
									</td>
								</tr>
							</template>
						</template>
					</table>
				</div>
				<div class="pager" v-if="page_num !== 0">
					<template v-if="current_page !== 1">
						<span class="page" @click="pager(current_page - 1)">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-if="page_num < 5">
						<template v-for="page in page_num">
							<span :class="{'page': true, 'current': current_page === page}" :key="'page_' + page" @click="pager(page)">
								<span>{{ page }}</span>
							</span>
						</template>
					</template>
					<template v-else>
						<template v-if="current_page < 4">
							<span :class="{'page': true, 'current': current_page === 1}" @click="pager(1)">
								<span>{{ 1 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 2}" @click="pager(2)">
								<span>{{ 2 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 3}" @click="pager(3)">
								<span>{{ 3 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 4}" @click="pager(4)">
								<span>{{ 4 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 5}" @click="pager(5)">
								<span>{{ 5 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 2) <= page_num">
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
							<span class="page" @click="pager(current_page + 2)">
								<span>{{ current_page + 2 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 1) <= page_num">
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
						</template>
						<template v-else-if="current_page === page_num">
							<span class="page" @click="pager(current_page - 4)">
								<span>{{ current_page - 4 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
						</template>
					</template>
					<template v-if="current_page !== page_num">
						<span class="page" @click="pager(current_page + 1)">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
				</div>
			</div>
			<div class="contents-filter">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							製品カテゴリ
						</div>
						<div class="input">
							<v-select :options="options_category" :reduce="options_category => options_category.id" v-model="filter_params.item_category_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							商品
						</div>
						<div class="input">
							<v-select :options="options_item" v-model="filter_params.name">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							事業所
						</div>
						<div class="input">
							<v-select :options="options_office" v-model="filter_params.office">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							登録者
						</div>
						<div class="input">
							<v-select :options="options_user" :reduce="options_user => options_user.id" v-model="filter_params.user_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							登録日
						</div>
						<div class="datepicker" ref="date1">
                            <datepicker clear-button ref="datepicker" :language="language" format="yyyy年MM月dd日" placeholder="登録日" v-model="created_at"></datepicker>
						</div>
                        <img src="../assets/img/date.png" ref="date2" @click="showCalendar('datepicker')">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
export default {
    components: {
        Datepicker,
		vSelect,
    },
	data() {
		return {
			filter_params: {
				created_at: null,
				count: null,
				item_category_id: null,
				name: null,
				office: null,
				user_id: null,
			},
			created_at: null,
			options_category: [],
			options_office: [],
			options_user: [],
			options_item: [],
			page_num: 0,
			current_page: 1,
			page_start: 1,
			page_end: 1,
			flags: {
				category: false,
				office: false,
				user: false,
			},
			eventHandler: false,
		}
	},
	created() {
		// 関連ページからの復帰で検索条件を保持
		if (this.stockHistoryFilterParams !== null) {
			this.filter_params = this.stockHistoryFilterParams
			this.created_at = this.filter_params.created_at
		}
		// リロード時にページを保持するように
		let params = this.filter_params
		params.page = 1
		if (this.$route.query.page !== undefined) {
			this.current_page = Number(this.$route.query.page)
			params.page = this.current_page
		}
		this.getItemCategoryMasterList(params)
		this.getOfficeList(params)
		this.getUserList(params)
		this.getItemMasterList()
	},
	mounted() {
		window.addEventListener('click', this.eventHandler = (event) => {
			if (!this.$refs.date1.contains(event.target) && !this.$refs.date2.contains(event.target)) {
				document.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
					}
				})
			}
		})
	},
	methods: {
		// 在庫履歴一覧取得
		async getStockHistoryList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('stock/getStockHistoryApi', params))
                .then(() => {
					this.$store.dispatch('loading/endLoad')
					if (this.stockHistoryList.page !== undefined) {
						this.page_num = this.stockHistoryList.page

						// 最大ページ数が最大表示数の5を上回っていた場合
						if (this.page_num > 5) {
							this.page_start = this.current_page - 2 < 0 ? 1 : this.current_page - 2
							this.page_end = this.page_end > this.current_page + 2 ? this.page_num : this.page_end > this.current_page + 2
						}
						else {
							this.page_start = 1
							this.page_end = this.page_num
						}
					}
				})
		},
		// 検索用商品カテゴリ取得
		async getItemCategoryMasterList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi', { name: null }))
                .then(() => {
					this.itemCategoryMasterList.category.filter(item => {
						if (item.name !== "") {
							this.options_category.push({label: item.name, id: item.id})
						}
					})
					this.flags.category = true
					if (this.flags.category && this.flags.office && this.flags.user) {
						if (params !== null) {
							this.getStockHistoryList(params)
						}
						else {
							this.getStockHistoryList(this.filter_params)
						}
					}
				})
		},
		// 検索用事業所一覧取得
		getOfficeList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
                .then(() => {
					this.officeMasterList.office.filter(item => {
						if (item.name !== "") {
							this.options_office.push(item.name)
						}
					})
					this.flags.office = true
					if (this.flags.category && this.flags.office && this.flags.user) {
						if (params !== null) {
							this.getStockHistoryList(params)
						}
						else {
							this.getStockHistoryList(this.filter_params)
						}
					}
				})
		},
		// 検索用ユーザー一覧取得
		getUserList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('userList/getUserListApi', {}))
                .then(() => {
					this.userList.user.filter(item => {
						if (item.name !== "") {
							this.options_user.push({label: item.name, id: item.id})
						}
					})
					this.flags.user = true
					if (this.flags.category && this.flags.office && this.flags.user) {
						if (params !== null) {
							this.getStockHistoryList(params)
						}
						else {
							this.getStockHistoryList(this.filter_params)
						}
					}
				})
		},
		// 更新画面へ
		toDetail(id) {
			this.$router.push('stock_history_detail?page=' + this.current_page + '&id=' + id)
		},
		// ページ切り替え
		pager(page) {
			this.$router.push('stock_history?page=' + page)
			this.current_page = page
			let params = this.filter_params
			params.page = this.current_page
			this.getStockHistoryList(params)
		},
		// カテゴリIDからカテゴリ名を取得する
		getItemCategoryNameById(id) {
			let result = ""
			this.itemCategoryMasterList.category.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		// ユーザーIDからユーザー名を取得する
		getUserNameById(id) {
			let result = ""
			this.userList.user.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
			this.toEndScroll()
		},
		toEndScroll() {
			setTimeout(() => {
				this.$nextTick(() => {
					const obj = document.getElementsByClassName('contents-filter')[0]
					obj.scrollTop = obj.scrollHeight
				})
			}, 10)
		},
		// 検索用商品マスタ取得
		getItemMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemMaster/getItemMasterApi'))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.itemMasterList.item.filter(item => {
						this.options_item.push(item.name)
					})
				})
		},
	},
	computed: {
        language(){
            return ja;
        },
		...mapState({
			stockApiStatus: state => state.stock.apiStatus,
			stockHistoryList: state => state.stock.stockHistoryList,
			stockHistoryFilterParams: state => state.stock.stockHistoryFilterParams,
			itemMasterList: state => state.itemMaster.itemMasterList,
			itemCategoryMasterApiStatus: state => state.itemCategoryMaster.apiStatus,
			itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
			officeMasterApiStatus: state => state.officeMaster.apiStatus,
			officeMasterList: state => state.officeMaster.officeMasterList,
			userList: state => state.userList.userList,
		})
    },
	watch: {
		// 検索結果取得
		filter_params: {
			handler(value) {
				let params = value
				params.page = 1
				this.current_page = 1
				this.page_num = 0
				this.getStockHistoryList(params)
				this.$store.commit('stock/setStockHistoryFilterParams', value)
			},
			deep : true,
		},
		created_at(value) {
			if (value === null) {
				this.filter_params.created_at = null
			}
			else {
				this.filter_params.created_at = moment(value).format('YYYY-MM-DD')
			}
			this.$refs.datepicker.showCalendar()
			this.$refs.datepicker.showCalendar()
		}
	},
	destroyed() {
		window.removeEventListener('click', this.eventHandler)
	}
}
</script>

<style scoped src="../assets/css/stock.css"></style>
<style scoped src="../assets/css/list_table.css"></style>