import { saveAs } from 'file-saver'
import { getApi, OK, postApi, strArray } from '../util'
import Encoding from 'encoding-japanese'

const state = {
    apiStatus: null,
    itemMasterList: null,
    itemMasterDetail: null,
    itemPrice: null,
    filterParams: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setItemMasterList(state, data) {
        state.itemMasterList = data
    },
    setItemMasterDetail(state, data) {
        state.itemMasterDetail = data
    },
    setItemPrice(state, data) {
        state.itemPrice = data
    },
    setFilterParams(state, data) {
        state.filterParams = data
    },
}

const actions = {
    // 商品マスタ一覧取得
    async getItemMasterApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setItemMasterList', null)
        const response = await getApi('get_mst_item_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setItemMasterList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品マスタ作成、更新
    async postItemMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_mst_item', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品マスタ削除
    async deleteItemMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('delete_mst_item', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品マスタ詳細取得
    async getItemMasterDetailApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_mst_item_detail', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setItemMasterDetail', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品マスタCSV取得
    async getItemMasterCsvApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('import_item_csv', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            let mineType = 'text/csv'
            const name = response.data.data.name
            const shiftJisCodeList = Encoding.convert(strArray(response.data.data.csv), 'sjis', 'unicode')
            const uInt8List = new Uint8Array(shiftJisCodeList)
            const blob = new Blob([uInt8List], { type: mineType })
            saveAs(blob, name)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品単価取得
    async getItemPriceApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_item_price', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setItemPrice', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}