<template>
	<div>
		<div id="main-contents" style="height: calc(100vh - 200px);">
			<div class="detail-contents" style="height: calc(100% - 30px); margin: 0;">
                <div class="invoices-title">
                    御請求書
                </div>
                <div class="date">
                    {{ setFormat($route.query.date) }}
                </div>
                <div class="right-side">
                    <div class="name">
                        宏陽株式会社
                    </div>
                    <div class="location">
                        <div>札幌市東区東苗穂8条3丁目9-11</div>
                        <div>電話（011）214-9083</div>
                        <div>FAX （011）214-9082</div>
                        <div>取引銀行 商工中金 札幌支店 (普)1111111</div>
                    </div>
                </div>
                <div class="left-side" v-if="invoicesDetail !== null">
                    <div class="location">
                        <div>{{ invoicesDetail.list.zip_code }}</div>
                        <div>{{ invoicesDetail.list.address }}</div>
                    </div>
                    <div class="name">
                        {{ invoicesDetail.list.name }}
                    </div>
                </div>
                <div class="table-and-preview">
                    <div class="money-table">
                        <table>
                            <tr>
                                <th>当月売上額</th>
                                <th>消費税</th>
                                <th>当月ご請求額</th>
                            </tr>
                            <tr>
                                <td>{{ result_1.toLocaleString() }}</td>
                                <td>{{ result_2.toLocaleString() }}</td>
                                <td>{{ result_3.toLocaleString() }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <table class="list-table slip-regist-step2-table">
                    <tr class="list-table-header">
                        <th>日付</th>
                        <th colspan="2">商品名</th>
                        <th style="width: 5vw">型式</th>
                        <th>タイプ</th>
                        <th>請求種別</th>
                        <th colspan="2">貸出期間</th>
                        <th>日数</th>
                        <th>数量</th>
                        <th>単価</th>
                        <th>税率</th>
                        <th>金額</th>
                        <th>消費税額</th>
                        <th colspan="2">備考</th>
                    </tr>
                </table>
                <div class="table-scroller" style="height: 38vh">
                    <table class="contents-scroll-table">
                        <template v-for="(item, index) in invoices_list">
                            <tr class="list-table-row" style="cursor: default;" :key="'tr_' + index">
                                <td>
                                    <!-- 日付 -->
                                    <span v-if="item.date === null || item.date === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="setFormat(item.date, true)">{{ setFormat(item.date, true) }}</span>
                                </td>
                                <td colspan="2">
                                    <!-- 商品名 -->
                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                </td>
                                <td style="width: 5vw">
                                    <!-- 型式 -->
                                    <span v-if="item.model_number === null || item.model_number === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.model_number">{{ item.model_number }}</span>
                                </td>
                                <td>
                                    <!-- タイプ -->
                                    <span v-if="item.type === null || item.type === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="type_label[item.type - 1]">{{ type_label[item.type - 1] }}</span>
                                </td>
                                <td>
                                    <!-- 請求種別 -->
                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                    <span v-else style="text-overflow: initial;" v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                </td>
                                <td colspan="2">
                                    <!-- 貸出期間 -->
                                    <span v-if="item.start_date === null || item.start_date === '' || item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true)">{{ setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true) }}</span>
                                </td>
                                <template v-if="item.pause_status === 1 || item.pause_status === 3">
                                    <td colspan="2">
                                        <span><div class="stop">{{ item.pause_status === 1 ? '休止中' : '修理中' }}</div></span>
                                    </td>
                                </template>
                                <template v-else>
                                    <td>
                                        <!-- 日数 -->
                                        <span v-if="item.days === null || item.days === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="item.days">{{ item.days }}</span>
                                    </td>
                                    <td>
                                        <!-- 数量 -->
                                        <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                    </td>
                                </template>
                                <td>
                                    <!-- 単価 -->
                                    <span v-if="item.price === null || item.price === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.price">{{ item.price }}</span>
                                </td>
                                <td>
                                    <!-- 税率 -->
                                    <span v-if="item.tax === null || item.tax === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.tax">{{ item.tax }}</span>
                                </td>
                                <td>
                                    <!-- 金額 -->
                                    <span v-if="item.result === null || item.result === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.result">{{ item.result }}</span>
                                </td>
                                <td>
                                    <!-- 消費税額 -->
                                    <span v-if="item.result_tax === null || item.result_tax === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.result_tax">{{ item.result_tax }}</span>
                                </td>
                                <td colspan="2">
                                    <!-- 備考 -->
                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                </td>
                            </tr>
                            <template v-if="item.options !== null">
                                <tr v-for="(option, option_index) in item.options" class="list-table-row" style="cursor: default;" :key="'tr_' + index + '_' + option_index">
                                    <td>
                                        <!-- 日付 -->
                                        <span v-if="item.date === null || item.date === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="setFormat(item.date, true)">{{ setFormat(item.date, true) }}</span>
                                    </td>
                                    <td colspan="2">
                                        <!-- 商品名 -->
                                        <span v-if="option.name === null || option.name === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="option.name">{{ option.name }}</span>
                                    </td>
                                    <td style="width: 5vw">
                                        <!-- 型式 -->
                                        <span v-tooltip="'-'">-</span>
                                    </td>
                                    <td>
                                        <!-- タイプ -->
                                        <span v-tooltip="'-'">-</span>
                                    </td>
                                    <td>
                                    <!-- 請求種別 -->
                                        <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                        <span v-else style="text-overflow: initial;" v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                    </td>
                                    <td colspan="2">
                                        <!-- 貸出期間 -->
                                        <span v-if="item.start_date === null || item.start_date === '' || item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true)">{{ setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true) }}</span>
                                    </td>
                                    <template v-if="item.pause_status === 1 || item.pause_status === 3">
                                        <td colspan="2">
                                            <span><div class="stop">{{ item.pause_status === 1 ? '休止中' : '修理中' }}</div></span>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td>
                                            <!-- 日数 -->
                                            <span v-if="item.days === null || item.days === ''" v-tooltip="'-'">-</span>
                                            <span v-else v-tooltip="item.days">{{ item.days }}</span>
                                        </td>
                                        <td>
                                            <!-- 数量 -->
                                            <span v-if="option.count === null || option.count === ''" v-tooltip="'-'">-</span>
                                            <span v-else v-tooltip="option.count">{{ option.count }}</span>
                                        </td>
                                    </template>
                                    <td>
                                        <!-- 単価 -->
                                        <span v-if="option.price === null || option.price === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="option.price">{{ option.price }}</span>
                                    </td>
                                    <td>
                                        <!-- 税率 -->
                                        <template v-if="option.tax !== undefined && option.tax !== null">
                                            <span v-if="option.tax === null || option.tax === ''" v-tooltip="'-'">-</span>
                                            <span v-else v-tooltip="option.tax + '%'">{{ option.tax + '%' }}</span>
                                        </template>
                                        <template v-else>
                                            <span v-if="item.tax === null || item.tax === ''" v-tooltip="'-'">-</span>
                                            <span v-else v-tooltip="item.tax + '%'">{{ item.tax + '%' }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 金額 -->
                                        <span v-if="option.result === null || option.result === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="option.result">{{ option.result }}</span>
                                    </td>
                                    <td>
                                        <!-- 消費税額 -->
                                        <span v-if="option.result_tax === null || option.result_tax === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="option.result_tax">{{ option.result_tax }}</span>
                                    </td>
                                    <td colspan="2">
                                        <!-- 備考 -->
                                        <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </table>
                </div>
			</div>
		</div>
        <div style="text-align: right; margin-top: 10px; margin-right: 50px;">
            <div style="display: inline-block; width: 200px; background-color: white;">
                <v-select :options="options_select" :clearable="false" :reduce="options_select => options_select.id" v-model="option_value"></v-select>
            </div>
        </div>
		<div class="buttons" style="margin-top: 50px;">
            <button class="button-cancel" @click="openModal()">取消</button>
            <button class="button-prev" @click="getCsv()">CSV出力</button>
            <button class="button-prev" @click="getChecklist()">チェックリスト出力</button>
            <button class="button-next" @click="getInvoicesPdf()">ダウンロード</button>
		</div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        取消処理を行うと、元に戻すことは出来ません。
                        本当に取消処理を行って宜しいですか？
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="modalCancel()">いいえ</button>
                    <button id="yes" @click="deleteInvoices()">はい</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment'
import { mapState } from 'vuex'
import { SLIP_DETAIL_LABEL } from '../util';
export default {
    components: {
        vSelect,
    },
	data() {
		return {
            slip_detail_label: SLIP_DETAIL_LABEL,
            params: null,
            is_modal: false,
            invoices_list: [],
            result_1: 0, // 当月売上額
            result_2: 0, // 消費税合計
            result_3: 0, // 当月売上額 + 消費税合計
            type_label: [
                "新規",
                "繰越",
                "返却"
            ],
            options_select: [
                {label: "オプション無", id: 1},
                {label: "現場計を有効", id: 2},
                {label: "現場毎に分割", id: 3},
            ],
            option_value: 1,
		}
	},
	created() {
        this.params = {
            id: this.$route.query.id,
            date: this.$route.query.date,
            customer_id: this.$route.query.customer_id,
            start_date: this.$route.query.start_date,
            end_date: this.$route.query.end_date,
        }
        this.getInvoicesDetailList()
	},
	mounted() {

	},
	methods: {
        getInvoicesDetailList() {
            let api = "invoices/getInvoicesListApi"
            if (this.$route.query.old !== undefined && this.$route.query.old) {
                api = "invoices/getOldInvoicesListApi"
                const invoice_id = this.params.id
                const customer_id = this.params.customer_id
                const date = this.params.date
                this.params = {
                    invoice_id: invoice_id,
                    customer_id: customer_id,
                    date: date,
                }
            }
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch(api, this.params))
                .then(() => {
                    if (this.invoicesDetail.list.length !== 0) {
                        this.invoicesDetail.list.data.filter((item, index) => {
                            this.invoices_list.push(item)
                            if (item.slip_detail_type == 8 || item.count < 0) {
                                this.invoices_list[index].result = 0
                            }
                            else {
                                if (item.days !== null) {
                                    this.invoices_list[index].result = item.pause_status === 1 ? 0 : ((item.price * item.count) * item.days)
                                }
                                else {
                                    this.invoices_list[index].result = item.pause_status === 1 ? 0 : (item.price * item.count)
                                }
                            }
                            let tax = Math.floor((this.invoices_list[index].result * (item.tax === 8 ? 108 : 110) / 100)) - this.invoices_list[index].result
                            // 数量がマイナスなら消費税マイナス請求
                            if (item.count < 0) {
                                if (item.days !== null) {
                                    this.invoices_list[index].result_tax = item.pause_status === 1 ? 0 : ((item.price * item.count) * item.days)
                                }
                                else {
                                    this.invoices_list[index].result_tax = item.pause_status === 1 ? 0 : (item.price * item.count)
                                }
                            }
                            else {
                                this.invoices_list[index].result_tax = item.pause_status === 1 ? 0 : Math.ceil(tax)
                            }

                            this.result_1 += this.invoices_list[index].result
                            this.result_2 += this.invoices_list[index].result_tax
                            if (item.options !== undefined && item.options !== null) {
                                let options = []
                                item.options.filter((option, options_index) => {
                                    options.push(option)
                                    options[options_index].result = item.pause_status === 1 ? 0 : (option.price * option.count)
                                    let tax = 0
                                    if (option.tax !== undefined && option.tax !== null) {
                                        tax = Math.floor((options[options_index].result * (option.tax === 8 ? 108 : 110) / 100)) - options[options_index].result
                                    }
                                    else {
                                        tax = Math.floor((options[options_index].result * (item.tax === 8 ? 108 : 110) / 100)) - options[options_index].result
                                    }
                                    options[options_index].result_tax = item.pause_status === 1 ? 0 : Math.ceil(tax)
                                    this.result_1 += options[options_index].result
                                    this.result_2 += options[options_index].result_tax
                                })
                                this.invoices_list[index].options = options
                                options = []
                            }
                        })
                        this.result_3 = this.result_1 + this.result_2
                    }
                    this.$store.dispatch('loading/endLoad')
				})
        },
        setFormat(date, is_month_day = false) {
            return is_month_day ? moment(date).format('MM/DD') : moment(date).format('YYYY年MM月DD日')
        },
        openModal() {
            this.is_modal = true
        },
        modalCancel() {
            this.is_modal = false
        },
        deleteInvoices() {
            this.is_modal = false
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch("invoices/deleteInvoices", { id: this.params.id }))
                .then(() => {
                    this.$store.dispatch('loading/endLoad')
                    this.$router.push('invoices')
				})
        },
        getInvoicesPdf() {
            const params = {
                ...this.params,
                type: this.option_value,
            }
            let api = "invoices/getInvoicesPdfApi"
            if (this.$route.query.old !== undefined && this.$route.query.old) {
                api = "invoices/getOldInvoicesPdfApi"
            }
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch(api, params))
                .then(() => {
                    this.$store.dispatch('loading/endLoad')
				})
        },
        getChecklist() {
            let api = "invoices/getInvoicesChecklistApi"
            if (this.$route.query.old !== undefined && this.$route.query.old) {
                api = "invoices/getOldInvoicesChecklistApi"
            }
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch(api, {id: this.$route.query.id}))
                .then(() => {
                    this.$store.dispatch('loading/endLoad')
				})
        },
        getCsv() {
            let api = "invoices/getInvoicesCsvApi"
            if (this.$route.query.old !== undefined && this.$route.query.old) {
                api = "invoices/getOldInvoicesCsvApi"
            }
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch(api, {id: this.$route.query.id}))
                .then(() => {
                    this.$store.dispatch('loading/endLoad')
				})
        }
	},
    computed: {
        ...mapState({
            invoicesDetail: state => state.invoices.invoicesDetail,
        })
    }
}
</script>

<style scoped src="../assets/css/invoices.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped>
.money-table table {
    margin: auto;
    border-collapse: collapse;
}
.money-table th {
    width: 170px;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #A3A6B4;
    background-color: #F5F6FA;
    text-align: center;
    padding: 5px 0;
    border: 1px solid #BCBCCB;
    border-bottom: none;
}
.money-table td {
    width: 160px;
    white-space: nowrap;
    text-align: right;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #4D4F5C;
    background-color: #FFFFFF;
    padding: 10px 10px 10px 0;
    border: 1px solid #BCBCCB;
    border-top: none;
}
.contents-scroll-table td {
    height: 40px;
}
.contents-scroll-table .stop {
	background-color: #F70202;
	white-space: nowrap;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #fff;
	text-align: center;
	padding: 8px 35px;
	border-radius: 5px;
}
.option td {
	background-color: #FF6565;
    color: #FFFFFF;
}
.option td input {
    width: 20px;
	background-color: #FFFFFF;
    text-overflow: initial;
}
.slip-regist-step2-table th:first-child,
.contents-scroll-table td:first-child {
    width: auto;
}
</style>