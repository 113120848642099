<template>
    <vue-loading
        type="spiningDubbles"
        color="#aaa"
        :size="{ width: '100px', height: '100px' }"
        class="loading"
    />
</template>

<script>
import { VueLoading } from 'vue-loading-template'

export default {
    name: 'loading',
    components: {
        VueLoading,
    },
}
</script>

<style>
.loading {
    position: absolute;
    top: calc(50%);
    left: calc(50%);
    transform: translate(-50%, -50%);
}
</style>