<template>
	<div>
		<div id="main-text">
			<span>商品マスタ</span>
		</div>
        <button class="export" @click="getItemMasterCsv()">
            エクスポート
        </button>
		<div id="main-contents">
			<div id="list-contents">
				<table class="list-table">
					<tr class="list-table-header">
						<th>商品名</th>
						<th>型番</th>
						<th>商品カテゴリ</th>
						<th>耐用年数</th>
						<th>税率</th>
					</tr>
				</table>
				<div class="list-table-scroller">
					<table class="list-table-contents">
						<template v-if="itemMasterList !== null">
							<template v-for="(item, index) in itemMasterList.item">
								<tr class="list-table-row" :key="'item_master_' + index" @click="toUpdate(item.id)">
									<td>
										<span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.name">{{ item.name }}</span>
									</td>
									<td>
										<span v-if="item.model_number === null || item.model_number === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.model_number">{{ item.model_number }}</span>
									</td>
									<td>
										<span v-if="item.item_category_id === null || item.item_category_id === ''" v-tooltip="'-'">-</span>
										<template v-else>
											<span v-if="itemCategoryMasterApiStatus" v-tooltip="getItemCategoryNameById(item.item_category_id)">{{ getItemCategoryNameById(item.item_category_id) }}</span>
										</template>
									</td>
									<td>
										<span v-if="item.service_life === null || item.service_life === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.service_life + '年'">{{ item.service_life + '年' }}</span>
									</td>
									<td>
										<span v-if="item.tax === null || item.tax === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.tax + '%'">{{ item.tax + '%' }}</span>
									</td>
								</tr>
							</template>
						</template>
					</table>
				</div>
				<div class="pager" v-if="page_num !== 0">
					<template v-if="current_page !== 1">
						<span class="page" @click="pager(current_page - 1)">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-if="page_num < 5">
						<template v-for="page in page_num">
							<span :class="{'page': true, 'current': current_page === page}" :key="'page_' + page" @click="pager(page)">
								<span>{{ page }}</span>
							</span>
						</template>
					</template>
					<template v-else>
						<template v-if="current_page < 4">
							<span :class="{'page': true, 'current': current_page === 1}" @click="pager(1)">
								<span>{{ 1 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 2}" @click="pager(2)">
								<span>{{ 2 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 3}" @click="pager(3)">
								<span>{{ 3 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 4}" @click="pager(4)">
								<span>{{ 4 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 5}" @click="pager(5)">
								<span>{{ 5 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 2) <= page_num">
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
							<span class="page" @click="pager(current_page + 2)">
								<span>{{ current_page + 2 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 1) <= page_num">
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
						</template>
						<template v-else-if="current_page === page_num">
							<span class="page" @click="pager(current_page - 4)">
								<span>{{ current_page - 4 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
						</template>
					</template>
					<template v-if="current_page !== page_num">
						<span class="page" @click="pager(current_page + 1)">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
				</div>
			</div>
			<div class="contents-filter">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							商品名
						</div>
						<div class="input">
							<input type="text" placeholder="商品名" maxlength="64" v-model="filter_params.name">
						</div>
					</div>
					<div class="filter">
						<div class="title">
							型番
						</div>
						<div class="input">
							<input type="text" placeholder="型番" maxlength="64" v-model="filter_params.model_number">
						</div>
					</div>
                    <div class="filter">
						<div class="title">
							商品カテゴリ
						</div>
						<div class="input">
							<v-select :options="options" :reduce="options => options.id" v-model="filter_params.category">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							耐用年数
						</div>
						<div class="input">
							<input type="number" placeholder="耐用年数" @input="filter_params.service_life = inputNumberMaxLength(filter_params.service_life, 9)" v-model="filter_params.service_life">
						</div>
					</div>
                    <div class="filter">
						<div class="title">
							税率
						</div>
						<div class="input">
							<v-select :options="options_tax" :reduce="options_tax => options_tax.value" v-model="filter_params.tax">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
			<router-link to="item_master_regist">
				<button class="button-next">新規登録</button>
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { inputNumberMaxLength } from '../util';
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			filter_params: {
				name: null,
				model_number: null,
				category: null,
				service_life: null,
				tax: null,
			},
			options: [],
			options_tax: [
				{label: '8%', value: 8},
				{label: '10%', value: 10},
			],
			page_num: 0,
			current_page: 1,
			page_start: 1,
			page_end: 1,
		}
	},
	created() {
		// 関連ページからの復帰で検索条件を保持
		if (this.itemFilterParams !== null) {
			this.filter_params = this.itemFilterParams
		}
		// リロード時にページを保持するように
		let params = this.filter_params
		params.page = 1
		if (this.$route.query.page !== undefined) {
			this.current_page = Number(this.$route.query.page)
			params.page = this.current_page
		}
		this.getItemCategoryMasterList(params)
	},
	mounted() {

	},
	methods: {
		// 商品マスタ一覧取得
		async getItemMasterList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(async () => {
                    await this.$store.dispatch('itemMaster/getItemMasterApi', params)
					if (this.itemMasterApiStatus) {
						if (this.itemMasterList.page !== undefined) {
							this.page_num = this.itemMasterList.page

							// 最大ページ数が最大表示数の5を上回っていた場合
							if (this.page_num > 5) {
								this.page_start = this.current_page - 2 < 0 ? 1 : this.current_page - 2
								this.page_end = this.page_end > this.current_page + 2 ? this.page_num : this.page_end > this.current_page + 2
							}
							else {
								this.page_start = 1
								this.page_end = this.page_num
							}
						}
					}
                })
                .then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 検索用商品カテゴリ取得
		async getItemCategoryMasterList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(async () => {
                    await this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi', { name: null })
					if (this.itemCategoryMasterApiStatus) {
						this.itemCategoryMasterList.category.filter(item => {
							this.options.push({label: item.name, id: item.id})
						})
						if (params !== null) {
							this.getItemMasterList(params)
						}
						else {
							this.getItemMasterList(this.filter_params)
						}
					}
                })
                .then(() => this.$store.dispatch('loading/endLoad'))
		},
		// CSVダウンロード
		async getItemMasterCsv() {
			const params = {
				name: this.filter_params.name,
				model_number: this.filter_params.model_number,
				category: this.filter_params.category,
				service_life: this.filter_params.service_life,
				tax: this.filter_params.tax
			}
			this.$store.dispatch('loading/startLoad')
                .then(async () => {
                    await this.$store.dispatch('itemMaster/getItemMasterCsvApi', params)
                })
                .then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 更新画面へ
		toUpdate(id) {
			this.$router.push('item_master_update?page=' + this.current_page + '&id=' + id)
		},
		// ページ切り替え
		pager(page) {
			this.$router.push('item_master?page=' + page)
			this.current_page = page
			let params = this.filter_params
			params.page = this.current_page
			this.getItemMasterList(params)
		},
		// カテゴリIDからカテゴリ名を取得する
		getItemCategoryNameById(id) {
			let result = ""
			this.itemCategoryMasterList.category.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
	},
	computed: {
		...mapState({
			itemMasterApiStatus: state => state.itemMaster.apiStatus,
			itemMasterList: state => state.itemMaster.itemMasterList,
			itemFilterParams: state => state.itemMaster.filterParams,
			itemCategoryMasterApiStatus: state => state.itemCategoryMaster.apiStatus,
			itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
		})
	},
	watch: {
		// 検索結果取得
		filter_params: {
			handler(value) {
				let params = value
				params.page = 1
				this.current_page = 1
				this.page_num = 0
				this.getItemMasterList(params)
				this.$store.commit('itemMaster/setFilterParams', value)
			},
			deep : true,
		}
	}
}
</script>

<style scoped src="../assets/css/item_master.css"></style>
<style scoped src="../assets/css/list_table.css"></style>