import { getApi, OK } from '../util'

const state = {
    apiStatus: null,
    holidayData: null,
    invoicesData: [],
    paymentData: [],
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setHoliday(state, data) {
        state.holidayData = data
    },
    setInvoicesData(state, data) {
        if (Object.keys(state.invoicesData).indexOf(data.date) === -1) {
            state.invoicesData[data.date] = data.data.invoices
        }
    },
    setPaymentData(state, data) {
        if (Object.keys(state.paymentData).indexOf(data.date) === -1) {
            state.paymentData[data.date] = data.data.payment
        }
    },
    resetData(state) {
        state.invoicesData = []
        state.paymentData = []
    },
}

const actions = {
    // 祝日取得
    async getHolidayApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_calendar_holiday', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setHoliday', response.data.data.holiday)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 請求日取得
    async getCalendarInvoicesApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_calendar_invoices', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setInvoicesData', {date: params.date, data: response.data.data})
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 入金日取得
    async getCalendarPaymentApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_calendar_payment', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setPaymentData', {date: params.date, data: response.data.data})
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}