<template>
	<div>
		<div id="main-text">
			<span>商品マスタ登録</span>
		</div>
		<div id="main-contents" style="height: auto">
			<div class="register-contents">
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							商品名
						</div>
						<div class="input">
							<input type="text" placeholder="商品名" maxlength="64" v-model="params.name">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							型番
						</div>
						<div class="input">
							<input type="text" placeholder="型番" maxlength="64" v-model="params.model_number">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							商品カテゴリ
						</div>
						<div class="input">
							<v-select :options="options" :reduce="options => options.id" v-model="params.item_category_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							耐用年数
						</div>
						<div class="input">
							<input type="number" placeholder="耐用年数" @input="params.service_life = inputNumberMaxLength(params.service_life, 9)" v-model="params.service_life">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							税率
						</div>
						<div class="input">
							<v-select :options="options_tax" :reduce="options_tax => options_tax.value" v-model="params.tax">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							請求タイプ
						</div>
						<div class="input">
							<v-select :options="options_billing_type" :reduce="options_billing_type => options_billing_type.value" v-model="params.billing_type">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							日額単価
						</div>
						<div class="input">
							<input type="number" placeholder="日額単価" v-model="params.daily_price">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							月額単価
						</div>
						<div class="input">
							<input type="number" placeholder="月額単価" v-model="params.month_price">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-prev" @click="register(true)">登録して在庫追加</button>
			<button class="button-next" @click="register(false)">登録</button>
		</div>
		<div id="modal" v-show="is_modal_validation">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal_validation = false">
                    <div id="modal-text">
                        以下の項目が入力されていません。<br>
						<span v-for="(item, index) in error_params" :key="'error_' + index">
							・{{item}}<br>
						</span>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal_validation = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { mapState } from 'vuex';
import { inputNumberMaxLength } from '../util';
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			params: {
				name: null,
				model_number: null,
				item_category_id: null,
				service_life: null,
				tax: null,
				billing_type: null,
				daily_price: null,
				month_price: null,
			},
			required_list: { // 入力必須リスト
				'name': "商品名",
				'item_category_id': "商品カテゴリ",
				'tax': "税率",
				'billing_type': "請求タイプ",
				"daily_price": "日額単価",
				"month_price": "月額単価",
			},
			options: [],
			options_tax: [
				{label: '8%', value: 8},
				{label: '10%', value: 10},
			],
			options_billing_type: [
				{label: '貸出', value: 1},
				{label: '販売', value: 2},
			],
			is_modal_validation: false,
			error_params: [],
		}
	},
	created() {
		this.getItemCategoryMasterList()
	},
	mounted() {

	},
	methods: {
		async register(is_stock) {
			this.error_params = []
			// 入力チェック
			Object.keys(this.params).filter(key => {
				Object.keys(this.required_list).filter(required_key => {
					if (required_key === key && (this.params[key] === null || this.params[key] === "")) {
						this.error_params.push(this.required_list[required_key])
					}
				})
			})
			if (this.error_params.length === 0) {
				this.$store.dispatch('loading/startLoad')
					.then(async () => {
						await this.$store.dispatch('itemMaster/postItemMasterApi', this.params)
						if (this.itemMasterApiStatus) {
							if (is_stock) {
								this.$router.push('stock_regist')
							}
							else {
								this.$router.push('item_master')
							}
						}
					})
					.then(() => this.$store.dispatch('loading/endLoad'))
			}
			else {
				this.is_modal_validation = true
			}
		},
		// 検索用商品カテゴリ取得
		async getItemCategoryMasterList() {
			this.$store.dispatch('loading/startLoad')
				.then(async () => {
					await this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi', { name: null })
					if (this.itemCategoryMasterApiStatus) {
						this.itemCategoryMasterList.category.filter(item => {
							this.options.push({label: item.name, id: item.id})
						})
					}
				})
				.then(() => this.$store.dispatch('loading/endLoad'))
		},
		inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
	},
	computed: {
		...mapState({
			itemMasterApiStatus: state => state.itemMaster.apiStatus,
			itemCategoryMasterApiStatus: state => state.itemCategoryMaster.apiStatus,
			itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
		})
	},
}
</script>

<style scoped src="../assets/css/item_master.css"></style>
<style scoped src="../assets/css/modal.css"></style>