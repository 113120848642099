<template>
	<div>
		<div id="main-text">
			<span>設置現場マスタ更新</span>
		</div>
		<div id="main-contents" style="height: auto; margin-top: 200px">
			<div class="register-contents">
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							取引先名
						</div>
						<div class="input">
							<v-select :options="options" :reduce="options => options.id" v-model="params.customer_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							設置現場名
						</div>
						<div class="input">
							<input type="text" placeholder="設置現場名" maxlength="64" v-model="params.name">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							電話番号
						</div>
						<div class="input">
							<input type="tel" pattern="[\d\-]*" placeholder="電話番号" maxlength="15" v-model="params.phone_number">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							FAX番号
						</div>
						<div class="input">
							<input type="tel" pattern="[\d\-]*" placeholder="FAX番号" maxlength="15" v-model="params.fax_number">
						</div>
					</div>
				</div>
                <div class="update-text">
                    <div class="update-text-title">住所</div>
                    <textarea v-model="params.address" maxlength="200"></textarea>
                </div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-cancel" @click="openModal()">削除</button>
			<button class="button-next" @click="update()">更新</button>
		</div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        取消処理を行うと、元に戻すことは出来ません。
                        本当に取消処理を行って宜しいですか？
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="modalCancel()">いいえ</button>
                    <button id="yes" @click="deleteLocationMaster()">はい</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_modal_validation">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal_validation = false">
                    <div id="modal-text">
                        以下の項目が入力されていません。<br>
						<span v-for="(item, index) in error_params" :key="'error_' + index">
							・{{item}}<br>
						</span>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal_validation = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { mapState } from 'vuex';
import { getFormatPhone } from '../util';
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			is_modal: false,
			params: {
				id: 0,
				customer_id: null,
				name: null,
				phone_number: null,
				fax_number: null,
				address: null,
			},
			required_list: { // 入力必須リスト
				'name': "設置現場名",
				'customer_id': "取引先名",
			},
			options: [],
			is_modal_validation: false,
			error_params: [],
		}
	},
	created() {
		this.params.id = this.$route.query.id
		this.getCustomerMasterList()
		this.getLocationMasterDetail()
	},
	mounted() {

	},
	methods: {
		// 設置現場マスタ詳細取得
		async getLocationMasterDetail() {
			this.$store.dispatch('loading/startLoad')
				.then(async () => {
					await this.$store.dispatch('locationMaster/getLocationMasterDetailApi', { id: this.params.id })
					if (this.locationMasterApiStatus) {
						this.params = this.locationMasterDetail
						this.params.id = this.$route.query.id
					}
				})
				.then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 検索用取引先マスタ取得
		async getCustomerMasterList() {
			this.$store.dispatch('loading/startLoad')
				.then(async () => {
					await this.$store.dispatch('customerMaster/getCustomerMasterApi', null)
					if (this.customerMasterApiStatus) {
						this.customerMasterList.list.filter(item => {
							this.options.push({label: item.floor, id: item.id})
						})
					}
				})
				.then(() => this.$store.dispatch('loading/endLoad'))
		},
		async update() {
			this.error_params = []
			let params = this.params
			// 電話番号、FAX番号の入力チェック
			let phone_number = params.phone_number
			let fax_number = params.fax_number
			params.phone_number = getFormatPhone(phone_number)
			params.fax_number = getFormatPhone(fax_number)
			if (!params.phone_number) {
				params.phone_number = null
			}
			if (!params.fax_number) {
				params.fax_number = null
			}
			// 入力チェック
			Object.keys(params).filter(key => {
				Object.keys(this.required_list).filter(required_key => {
					if (required_key === key && (params[key] === null || params[key] === "")) {
						this.error_params.push(this.required_list[required_key])
					}
				})
			})
			if (this.error_params.length === 0) {
				this.$store.dispatch('loading/startLoad')
					.then(async () => {
						await this.$store.dispatch('locationMaster/postLocationMasterApi', this.params)
						if (this.locationMasterApiStatus) {
							this.$router.push('location_master')
						}
					})
				.then(() => this.$store.dispatch('loading/endLoad'))
			}
			else {
				this.is_modal_validation = true
			}
		},
		// 削除API
		async deleteLocationMaster() {
			this.$store.dispatch('loading/startLoad')
				.then(async () => {
					await this.$store.dispatch('locationMaster/deleteLocationMasterApi', {id: this.params.id})
					if (this.locationMasterApiStatus) {
						this.$router.push('location_master')
					}
				})
				.then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 削除モーダル表示
        openModal() {
            this.is_modal = true
        },
		// 削除モーダル非表示
        modalCancel() {
            this.is_modal = false
        },
	},
	computed: {
		...mapState({
			locationMasterApiStatus: state => state.locationMaster.apiStatus,
			locationMasterDetail: state => state.locationMaster.locationMasterDetail,
			customerMasterApiStatus: state => state.customerMaster.apiStatus,
			customerMasterList: state => state.customerMaster.customerMasterList,
		})
	},
}
</script>

<style scoped src="../assets/css/location_master.css"></style>
<style scoped src="../assets/css/modal.css"></style>