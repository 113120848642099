<template>
	<div>
		<div id="main-text">
			<span>商品カテゴリーマスタ登録</span>
		</div>
		<div id="main-contents" style="height: auto">
			<div class="register-contents">
                <div class="register-item" style="margin-left: 50px; width: 300px">
					<div class="title">
						カテゴリー名
					</div>
					<div class="input">
						<input type="text" placeholder="カテゴリー名" v-model="params.name">
					</div>
				</div>
                <div>
                    <div class="title" style="margin-top: 40px; margin-left: 50px;">
                        オプション費用
                    </div>
                    <table class="list-table">
                        <tr class="list-table-header">
                            <th>単価</th>
                            <th colspan="2">名称</th>
                            <th>数量</th>
                            <th>税率</th>
                            <th></th>
                        </tr>
                    </table>
                    <div class="list-table-scroller">
                        <table class="list-table-contents">
                            <template v-for="(option, index) in option_list">
                                <tr class="list-table-row" :key="'option_' + index" style="cursor: default;">
                                    <td style="padding: 0px 20px; text-overflow: initial;">
                                        <span class="input" style="display: inline-block; margin: 10px 0; width: 100px; text-align: right; border-radius: 5px;">
                                            <input type="number" placeholder="単価" @input="option.default_amount = inputNumberMaxLength(option.default_amount, 9)" v-model="option.default_amount">
                                        </span>
                                    </td>
                                    <td colspan="2" style="padding: 0px 20px; text-overflow: initial;">
                                        <span class="input" style="display: inline-block; margin: 10px 0; width: 100px; text-align: right; border-radius: 5px;">
                                            <input type="text" placeholder="名称" maxlength="64" v-model="option.name">
                                        </span>
                                    </td>
                                    <td style="padding: 0px 20px; text-overflow: initial;">
                                        <span class="input" style="display: inline-block; margin: 10px 0; width: 100px; text-align: right; border-radius: 5px;">
                                            <input type="number" placeholder="数量" @input="option.default_num = inputNumberMaxLength(option.default_num, 9)" v-model="option.default_num">
                                        </span>
                                    </td>
                                    <td style="padding: 0px 20px; text-overflow: initial;">
                                        <v-select style="border: 1px solid #E8E9EC; border-radius: 5px;" :options="options_tax" :clearable="false" :reduce="options_tax => options_tax.value" v-model="option.tax">
                                            <template #no-options>データが存在しません</template>
                                        </v-select>
                                    </td>
                                    <td><img v-if="option_list.length > 0" class="table-close-button" src="../assets/img/delete.png" @click="deleteOption(index)"></td>
                                </tr>
                            </template>
                        </table>
                        <div class="button">
                            <button class="button-next" @click="addOption()">追加</button>
                        </div>
                    </div>
                </div>
            </div>
		</div>
		<div class="buttons">
			<button class="button-next" @click="register()">登録</button>
		</div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        カテゴリー名を入力してください。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="yes" style="width: 100%" @click="is_modal = false">はい</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_modal_option">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal_option = false">
                    <div id="modal-text">
                        入力されていないオプションがあります。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal_option = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { mapState } from 'vuex'
import { inputNumberMaxLength } from '../util'
export default {
    components: {
		vSelect,
	},
	data() {
		return {
            params: {
                name: "",
                option: null,
            },
            option_list: [],
            required_list: { // 入力必須リスト
				'name': "カテゴリー名",
			},
            is_modal: false,
            is_modal_option: false,
            options_tax: [
				{label: '10%', value: 10},
                {label: '8%', value: 8},
			],
        }
	},
	created() {

	},
	mounted() {

	},
	methods: {
        async register() {
            // カテゴリ名の入力チェック
            if (this.params.name !== "") {
                // オプションあり
                if (this.option_list.length > 0) {
                    // オプションのチェック
                    let flags = []
                    this.option_list.filter(option => {
                        Object.keys(option).filter(key => {
                            // 入力されていないオプションが一つでもあったら失敗
                            if (option[key] === null || option[key] === "") {
                                flags.push(false)
                            }
                        })
                    })
                    // オプションの入力漏れなし
                    if (flags.length === 0) {
                        this.params.option = this.option_list
                        this.$store.dispatch('loading/startLoad')
                            .then(async () => {
                                await this.$store.dispatch('itemCategoryMaster/postItemCategoryMasterApi', this.params)
                                if (this.itemCategoryMasterApiStatus) {
                                    this.$router.push('item_category_master')
                                }
                            })
                            .then(() => this.$store.dispatch('loading/endLoad'))
                    }
                    else {
                        this.is_modal_option = true
                    }
                }
                // オプションなし
                else {
                    this.$store.dispatch('loading/startLoad')
                        .then(async () => {
                            await this.$store.dispatch('itemCategoryMaster/postItemCategoryMasterApi', this.params)
                            if (this.itemCategoryMasterApiStatus) {
                                this.$router.push('item_category_master')
                            }
                        })
                        .then(() => this.$store.dispatch('loading/endLoad'))
                }
            }
            else {
                this.is_modal = true
            }
        },
        // オプション追加
        addOption() {
            this.option_list.push({
                name: "",
                default_amount: "",
                default_num: "",
                tax: 10,
            })
        },
        // オプション削除
        deleteOption(index) {
            this.option_list.splice(index, 1)
        },
        inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
	},
    computed: {
        ...mapState({
            itemCategoryMasterApiStatus: state => state.itemCategoryMaster.apiStatus,
        })
    }
}
</script>

<style scoped src="../assets/css/item_category_master.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped>
.button {
    text-align: right;
    margin-top: 20px;
    margin-right: 50px;
}
.list-table-scroller {
    height: 30vh;
}
</style>