import { postApi, OK } from '../util'

const state = {
    apiStatus: null,
    userData: null,
    isLoginError: false,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setUserData(state, data) {
        state.userData = data
    },
    setIsLoginError(state, data) {
        state.isLoginError = data
    },
}

const actions = {
    // ログイン
    async loginApi(context, params) {
        context.commit('setIsLoginError', false)
        context.commit('setApiStatus', null)
        const response = await postApi('login', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setUserData', response.data.data)
            return false
        }

        context.commit('setApiStatus', true)
        context.commit('setIsLoginError', true)
    },
    // ログアウト
    async logoutApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('logout', params)
        if (response.data.code === OK) {
            if (response.data.code === OK) {
                context.commit('setApiStatus', true)
            }
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}