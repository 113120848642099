import { saveAs } from 'file-saver'
import { getApi, OK, postApi, strArray } from '../util'
import Encoding from 'encoding-japanese'

const state = {
    apiStatus: null,
    locationMasterList: null,
    locationMasterDetail: null,
    slipAddLocationId: null,
    filterParams: null,
    filterLocationMasterList: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setLocationMasterList(state, data) {
        state.locationMasterList = data
    },
    setLocationMasterDetail(state, data) {
        state.locationMasterDetail = data
    },
    setSlipAddLocationId(state, data) {
        state.slipAddLocationId = data
    },
    setFilterParams(state, data) {
        state.filterParams = data
    },
    setFilterLocationMasterList(state, data) {
        state.filterLocationMasterList = data
    },
}

const actions = {
    // 設置現場マスタ一覧取得
    async getLocationMasterApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setLocationMasterList', null)
        const response = await getApi('get_mst_location_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setLocationMasterList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 設置現場マスタ作成、更新
    async postLocationMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_mst_location', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setSlipAddLocationId', response.data.data.id)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 設置現場マスタ削除
    async deleteLocationMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('delete_mst_location', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 設置現場マスタ詳細取得
    async getLocationMasterDetailApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_mst_location_detail', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setLocationMasterDetail', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 設置現場マスタCSV取得
    async getLocationMasterCsvApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('import_location_csv', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            let mineType = 'text/csv'
            const name = response.data.data.name
            const shiftJisCodeList = Encoding.convert(strArray(response.data.data.csv), 'sjis', 'unicode')
            const uInt8List = new Uint8Array(shiftJisCodeList)
            const blob = new Blob([uInt8List], { type: mineType })
            saveAs(blob, name)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 検索用設置現場マスタ一覧取得
    async getFilterLocationMasterApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setFilterLocationMasterList', null)
        const response = await getApi('get_mst_location_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setFilterLocationMasterList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}