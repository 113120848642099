import { saveAs } from 'file-saver'
import { getApi, OK, postApi, strArray } from '../util'
import Encoding from 'encoding-japanese'

const state = {
    apiStatus: null,
    stockList: null,
    stockHistoryList: null,
    stockDetail: null,
    stockHistoryDetail: null,
    rentalStockNote: null,
    stockFilterParams: null,
    stockHistoryFilterParams: null,
    isDeleteError: false,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setStockList(state, data) {
        state.stockList = data
    },
    setStockHistoryList(state, data) {
        state.stockHistoryList = data
    },
    setStockDetail(state, data) {
        state.stockDetail = data
    },
    setStockHistoryDetail(state, data) {
        state.stockHistoryDetail = data
    },
    setRentalStockNote(state, data) {
        state.rentalStockNote = data
    },
    setStockFilterParams(state, data) {
        state.stockFilterParams = data
    },
    setStockHistoryFilterParams(state, data) {
        state.stockHistoryFilterParams = data
    },
    setIsDeleteError(state, data) {
        state.isDeleteError = data
    },
}

const actions = {
    // 在庫管理一覧取得
    async getStockApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setStockList', null)
        const response = await getApi('get_stocks_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setStockList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 在庫管理履歴一覧取得
    async getStockHistoryApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setStockHistoryList', null)
        const response = await getApi('get_stock_logs_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setStockHistoryList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 在庫管理作成、更新
    async postStockApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_stocks', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 在庫管理削除
    async deleteStockApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setIsDeleteError', false)
        const response = await postApi('delete_stocks', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }
        else {
            context.commit('setIsDeleteError', true)
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 在庫管理詳細取得
    async getStockDetailApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_stocks_detail', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setStockDetail', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 在庫管理履歴詳細取得
    async getStockHistoryDetailApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_stock_logs_detail', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setStockHistoryDetail', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 在庫管理CSV取得
    async getStockCsvApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('import_stocks_csv', params)

        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            let mineType = 'text/csv'
            const name = response.data.data.name
            const shiftJisCodeList = Encoding.convert(strArray(response.data.data.csv), 'sjis', 'unicode')
            const uInt8List = new Uint8Array(shiftJisCodeList)
            const blob = new Blob([uInt8List], { type: mineType })
            saveAs(blob, name)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 他社借りの最新備考取得
    async getRentalStockNoteApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_rental_stock_note', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setRentalStockNote', response.data.data.note)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}