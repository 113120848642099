<template>
	<div v-if="stockDetail !== null">
		<div id="main-text">
			<span>管理番号:{{ stockDetail.control_number }}</span>
		</div>
		<div id="main-contents" style="height: auto">
            <div class="register-contents">
                <div class="update-item">
					<div class="register-title">
						仕入れ日
					</div>
					<div class="update-contents">
                        {{ stockDetail.purchase_date }}
					</div>
				</div>
                <div class="update-item">
					<div class="register-title">
						ステータス
					</div>
                    <div class="update-contents">
                        {{ getStockStatus(stockDetail.status) }}
                        <button v-if="stockDetail.status !== STOCK_STATUS.payd && stockDetail.status !== STOCK_STATUS.delete" ref="popup_button" @click="is_popup = !is_popup">{{ stockDetail.status !== STOCK_STATUS.repair ? '修理' : '修理完了' }}</button>
						<template v-if="stockDetail.status === STOCK_STATUS.repair && stockDetail.repair_start !== null">
							<span style="display: block">開始日 : {{ stockDetail.repair_start }}</span>
						</template>
					</div>
                    <div class="popup" v-if="is_popup" ref="popup">
                        <div class="register-datepicker" style="margin-bottom: 30px;" ref="date1">
                            <datepicker ref="datepicker" :language="language" format="yyyy年MM月dd日" :placeholder="stockDetail.status !== STOCK_STATUS.repair ? '修理開始日' : '修理終了日'" v-model="status_date"></datepicker>
                        </div>
                        <img style="margin-left: 15px; vertical-align: bottom; margin-bottom: 30px;" src="../assets/img/date.png" ref="date2" @click="showCalendar('datepicker')">
                        <button class="button-next" style="float: right; margin-right: 60px;" @click="submitRepair()">登録</button>
                    </div>
				</div>
				<div class="update-item">
					<div class="register-title">
						商品カテゴリー
					</div>
                    <div class="update-contents">
                        {{ item_category_name }}
					</div>
				</div>
				<div class="update-item">
					<div class="register-title">
						商品
					</div>
                    <div class="update-contents">
                        {{ stockDetail.name }}
					</div>
				</div>
                <div class="update-item">
					<div class="register-title">
						事業所
					</div>
                    <div class="update-contents">
                        {{ stockDetail.office }}
					</div>
				</div>
				<div class="update-item" v-if="stockDetail.rental_customer_name !== undefined && stockDetail.rental_customer_name !== null">
					<div class="register-title">
						貸出先
					</div>
					<div class="update-contents">
                        {{ stockDetail.rental_customer_name }}
					</div>
				</div>
				<div class="update-item" v-if="stockDetail.rental_location_name !== undefined && stockDetail.rental_location_name !== null">
					<div class="register-title">
						現場名
					</div>
					<div class="update-contents">
                        {{ stockDetail.rental_location_name }}
					</div>
				</div>
				<div class="update-item">
					<div class="register-title">
						仕入れ元
					</div>
					<div class="update-contents">
                        {{ stockDetail.importer }}
					</div>
				</div>
				<div class="update-item">
					<div class="register-title">
						仕入れ単価
					</div>
                    <div class="update-contents">
                        {{ stockDetail.purchase_price }}
					</div>
				</div>
				<div class="update-item">
					<div class="register-title">
						入力者
					</div>
					<div class="update-contents">
                        {{ stockDetail.user_name }}
					</div>
				</div>
				<div class="update-item">
					<div class="register-title">
						登録日
					</div>
					<div class="update-contents">
                        {{ stockDetail.purchase_date }}
					</div>
				</div>
				<div class="update-item">
					<div class="register-title">
						ステータス更新日
					</div>
					<div class="update-contents">
                        {{ stockDetail.status_update_date !== null ? stockDetail.status_update_date : "未更新" }}
					</div>
				</div>
                <div class="update-text">
                    <div class="update-text-title">備考</div>
                    <textarea v-model="params.note" maxlength="200"></textarea>
                </div>
            </div>
		</div>
		<div class="buttons">
			<template v-if="userData.auth === USER_TYPE.admin">
				<button class="button-cancel" @click="is_modal = true">取消</button>
			</template>
			<button class="button-next" @click="update()">更新</button>
		</div>
		<div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        取消処理を行うと、元に戻すことは出来ません。
                        本当に取消処理を行って宜しいですか？
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="is_modal = false">いいえ</button>
                    <button id="yes" @click="deleteStock()">はい</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_status_date_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_status_date_error = false">
                    <div id="modal-text">
                        修理終了日が修理開始日より前に設定されています。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_status_date_error = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex';
import { STOCK_STATUS, STOCK_STATUS_LABEL, USER_TYPE } from '../util';
export default {
    components: {
        Datepicker,
    },
	data() {
		return {
			STOCK_STATUS: STOCK_STATUS,
			USER_TYPE: USER_TYPE,
            is_modal: false,
            is_popup: false,
			params: {
				id: 0,
				status: 0,
				status_date: null,
				note: null,
			},
			status_date: null,
			eventHandler: null,
			closePopupHandler: null,
			is_status_date_error: false,
			item_category_name: "",
		}
	},
	created() {
		this.params.id = this.$route.query.id
		this.getStockDetail()
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('click', this.eventHandler = (event) => {
				if (this.is_popup) {
					if (!this.$refs.date1.contains(event.target) && !this.$refs.date2.contains(event.target)) {
						document.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
							// カレンダー展開状態かどうか
							if (element.style.display === '') {
								this.$refs.datepicker.showCalendar()
							}
						})
					}
				}
			})
			window.addEventListener('click', this.closePopupHandler = (event) => {
				if (this.$refs.popup !== undefined && this.is_popup && !this.$refs.popup_button.contains(event.target) && !this.$refs.popup.contains(event.target)) {
					this.is_popup = false
				}
			})
		})
	},
	methods: {
		getStockDetail() {
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('stock/getStockDetailApi', {id: this.params.id}))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.params.id = this.$route.query.id
					this.params.status = this.stockDetail.status
					this.params.note = this.stockDetail.note
					this.getItemCategory()
				})
		},
        update() {
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('stock/postStockApi', this.params))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.$router.push('stock')
				})
        },
        deleteStock() {
			this.is_modal = false
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('stock/deleteStockApi', {id: this.params.id, type: 1}))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					if (!this.isDeleteError) {
						this.$router.push('stock')
					}
				})
        },
		submitRepair() {
			// 更新ステータスを設定
			if (this.stockDetail.status === STOCK_STATUS.repair) {
				if (this.stockDetail.status_flag === 1) {
					this.params.status = STOCK_STATUS.none
				}
				else {
					this.params.status = STOCK_STATUS.rental
				}

				// 修理開始日 < 終了日のチェック
				if (this.stockDetail.repair_start !== null) {
					if (moment(this.stockDetail.repair_start).unix() < moment(this.status_date).unix()) {
						this.params.status_date = moment(this.status_date).format('YYYY-MM-DD')
					}
					else {
						this.is_status_date_error = true
					}
				}
				else {
					this.params.status_date = moment(this.status_date).format('YYYY-MM-DD')
				}
			}
			else {
				this.params.status = STOCK_STATUS.repair
				this.params.status_date = moment(this.status_date).format('YYYY-MM-DD')
			}
			
			this.is_popup = false
		},
		// 出庫ステータスを返す
		getStockStatus(status) {
			return STOCK_STATUS_LABEL[status]
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
		},
		getItemCategory() {
			if (this.itemCategoryMasterList === null) {
				this.$store.dispatch('loading/startLoad')
					.then(() => this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi', { name: null }))
					.then(() => {
						this.$store.dispatch('loading/endLoad')
						this.itemCategoryMasterList.category.filter(item => {
							if (item.id === Number(this.stockDetail.control_number.split('-')[0])) {
								this.item_category_name = item.name
							}
						})
					})
			}
			else {
				this.itemCategoryMasterList.category.filter(item => {
					if (item.id === Number(this.stockDetail.control_number.split('-')[0])) {
						this.item_category_name = item.name
					}
				})
			}
		},
	},
    computed: {
        language(){
            return ja;
        },
		...mapState({
			userData: state => state.login.userData,
			stockApiStatus: state => state.apiStatus,
			stockDetail: state => state.stock.stockDetail,
			isDeleteError: state => state.stock.isDeleteError,
			itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
		})
    },
	watch: {
		status_date() {
			this.$refs.datepicker.showCalendar();
			this.$refs.datepicker.showCalendar();
		}
	},
	destroyed() {
		window.removeEventListener('click', this.eventHandler)
		window.removeEventListener('click', this.closePopupHandler)
	}
}
</script>

<style scoped src="../assets/css/stock.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped>
.register-contents {
	width: calc(100% - 200px);
	margin: auto;
    padding: 50px 0;
}
</style>