<template>
	<div>
		<div id="main-text">
			<span>取引先マスタ登録</span>
		</div>
		<div id="main-contents" style="height: auto">
			<div class="register-contents" style="padding: 10px 0">
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							取引先名
						</div>
						<div class="input">
							<input type="text" placeholder="取引先名" maxlength="64" v-model="params.floor">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							担当部署
						</div>
						<div class="input">
							<input type="text" placeholder="担当部署" maxlength="64" v-model="params.name">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							担当者
						</div>
						<div class="input">
							<input type="text" placeholder="担当者" maxlength="64" v-model="params.author">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							メールアドレス
						</div>
						<div class="input">
							<input type="text" placeholder="メールアドレス" maxlength="200" v-model="params.mail">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							電話番号
						</div>
						<div class="input">
							<input type="tel" pattern="[\d\-]*" placeholder="電話番号" maxlength="15" v-model="params.phone_number">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							FAX番号
						</div>
						<div class="input">
							<input type="tel" pattern="[\d\-]*" placeholder="FAX番号" maxlength="15" v-model="params.fax_number">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							締め日
						</div>
						<div class="input">
							<input type="number" placeholder="締め日" @input="params.deadline = inputNumberMaxLength(params.deadline, 2)" v-model="params.deadline">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							決済月
						</div>
						<div class="input">
							<input type="number" placeholder="決済月" @input="params.closing_month = inputNumberMaxLength(params.closing_month, 2)" v-model="params.closing_month">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							入金月/日
						</div>
						<div class="input" style="display: inline-block;">
							<input type="number" style="width: 50px;" @input="params.deposit_month = inputNumberMaxLength(params.deposit_month, 2)" v-model="params.deposit_month">
						</div>
						<span class="register-item-text">
							月後
						</span>
						<div class="input" style="display: inline-block;">
							<input type="number" style="width: 50px;" @input="params.deposit_day = inputNumberMaxLength(params.deposit_day, 2)" v-model="params.deposit_day">
						</div>
						<span class="register-item-text">
							日
						</span>
					</div>
					<div class="register-item">
						<div class="register-title">
							担当事業所
						</div>
						<div class="input">
							<v-select :options="options" :reduce="options => options.id" v-model="params.office">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							郵便番号
						</div>
						<div class="input">
							<input type="tel" placeholder="郵便番号" @input="zip_code = inputNumberMaxLength(zip_code, 8)" v-model="zip_code">
						</div>
					</div>
					<div class="register-item"></div>
				</div>
                <div class="update-text">
                    <div class="update-text-title">住所</div>
                    <textarea v-model="params.address" maxlength="200"></textarea>
                </div>
                <div class="update-text">
                    <div class="update-text-title">備考</div>
                    <textarea v-model="params.text" maxlength="200"></textarea>
                </div>
                <div class="regist-checkbox">
                    <label class="check" for="check">
                        <input type="checkbox" id="check" style="display: none;" v-model="params.month_30_flag">
                        <span class="check-text">最大日数を30日とする</span>
                        <span class="checkbox"><img v-if="params.month_30_flag" src="../assets/img/checkbox.png"></span>
                    </label>
                </div>
			</div>
		</div>
		<div class="buttons">
			<button class="button-next" @click="register()">登録</button>
		</div>
		<div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        以下の項目が入力されていません。<br>
						<span v-for="(item, index) in error_params" :key="'error_' + index">
							・{{item}}<br>
						</span>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_number_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_number_error = false">
                    <div id="modal-text">
                        {{ number_error_text }}
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_number_error = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { getFormatPhone, inputNumberMaxLength } from '../util'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			params: {
				floor: null,
				name: null,
				author: null,
				office: null,
				mail: null,
				phone_number: null,
				fax_number: null,
				deposit_month: null,
				deposit_day: null,
				deadline: null,
				closing_month: null,
				month_30_flag: false,
				address: null,
				text: null,
				zip_code: null,
			},
			zip_code: null,
			required_list: { // 入力必須リスト
				'floor': "取引先名",
				'office': "担当事業所",
				'phone_number': "電話番号",
				'deposit_month': "入金月",
				'deposit_day': "入金日",
				'deadline': "締め日",
				'address': "住所",
				'zip_code': "郵便番号",
			},
			options: [],
			is_modal: false,
			error_params: [],
			number_error_text: "",
			is_number_error: false,
		}
	},
	created() {
		this.getOfficeList()
	},
	mounted() {

	},
	methods: {
		async register() {
			// 月、日の入力文字チェック
			if (this.params.deposit_month !== null && (this.params.deposit_month < 1 || this.params.deposit_month > 12)) {
				this.number_error_text = "入金月を正しく入力してください。"
				this.is_number_error = true
				return
			}
			if (this.params.deposit_day !== null && (this.params.deposit_day < 1 || this.params.deposit_day > 31)) {
				this.number_error_text = "入金日を正しく入力してください。"
				this.is_number_error = true
				return
			}
			if (this.params.deadline !== null && (this.params.deadline < 1 || this.params.deadline > 31)) {
				this.number_error_text = "締め日を正しく入力してください。"
				this.is_number_error = true
				return
			}
			if (this.params.closing_month !== null && (this.params.closing_month < 1 || this.params.closing_month > 12)) {
				this.number_error_text = "決済月を正しく入力してください。"
				this.is_number_error = true
				return
			}

			this.error_params = []
			let params = this.params
			params.zip_code = this.zip_code
			// 電話番号、FAX番号の入力チェック
			let phone_number = params.phone_number
			let fax_number = params.fax_number
			params.phone_number = getFormatPhone(phone_number)
			params.fax_number = getFormatPhone(fax_number)
			if (!params.phone_number) {
				params.phone_number = null
			}
			if (!params.fax_number) {
				params.fax_number = null
			}
			// 入力チェック
			Object.keys(params).filter(key => {
				Object.keys(this.required_list).filter(required_key => {
					if (required_key === key && (params[key] === null || params[key] === "")) {
						this.error_params.push(this.required_list[required_key])
					}
				})
			})
			if (this.error_params.length === 0) {
				this.$store.dispatch('loading/startLoad')
					.then(async () => {
						await this.$store.dispatch('customerMaster/postCustomerMasterApi', this.params)
						if (this.customerMasterApiStatus) {
							this.$router.push('customer_master')
						}
					})
					.then(() => this.$store.dispatch('loading/endLoad'))
			}
			else {
				this.is_modal = true
			}
		},
		// 事業所一覧取得
		async getOfficeList() {
			this.$store.dispatch('loading/startLoad')
                .then(async () => {
					await this.$store.dispatch('officeMaster/getOfficeMasterApi', {})
					if (this.officeMasterApiStatus) {
						this.officeMasterList.office.filter(item => {
							this.options.push({label: item.name, id: item.id})
						})
					}
				})
                .then(() => this.$store.dispatch('loading/endLoad'))
		},
		inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
	},
	computed: {
		...mapState({
			customerMasterApiStatus: state => state.customerMaster.apiStatus,
			officeMasterApiStatus: state => state.officeMaster.apiStatus,
			officeMasterList: state => state.officeMaster.officeMasterList,
		})
	},
	watch: {
		zip_code(value, old_value) {
			// ハイフン自動挿入処理
			if (value.indexOf('-') === -1 && (value.length === 4 || value.length === 5)) {
				this.zip_code = value.slice(0, 3) + "-" + value.slice(3)
			}
			if (value.length === 3 && old_value.length === 4) {
				this.zip_code = value.slice(0, 2)
			}
			else if (value.length === 3) {
				this.zip_code += "-"
			}
		},
	}
}
</script>

<style scoped src="../assets/css/customer_master.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped>
.register-item-text {
    white-space: nowrap;
    text-align: left;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #808495;
    margin-left: 10px;
    margin-right: 20px;
}
</style>