<template>
	<div>
		<div id="main-text">
			<span>伝票更新（出庫）</span>
            <span class="step2-slip-no">No:{{ $route.query.id }}</span>
		</div>
		<div id="main-contents" style="height: auto;">
			<div class="step-contents">
				<div class="step">
                    <router-link to="slip_update_step1?type=1">
                        <div class="step-circle">
                            <span><img src="../assets/img/check.png"></span>
                        </div>
                    </router-link>
					<span class="step-border"></span>
					<div class="step-circle">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
                    <router-link to="slip_update_step1?type=1">
                        <div class="step-word">
                            Step 1
                        </div>
                    </router-link>
					<div class="step-word" style="font-weight: bold;">
						Step 2
					</div>
				</div>
			</div>
			<div class="register-step2-contents">
                <div class="slip-register-step2-title">
                    {{ getItemCategoryNameById(updateParams.customer_id) }}
                </div>
                <div class="register-button">
                    <button @click="addRow()">
                        追加
                    </button>
                    <button style="margin-left: 20px" @click="openAddModal()">
                        まとめて追加
                    </button>
                    <button style="margin-left: 20px; width: 200px;" @click="openAddRentalModal()">
                        他社借りまとめて追加
                    </button>
                </div>
                <div class="table-scroller2 contents-scroll-table" style="margin-top: 30px;">
                    <table class="list-table slip-regist-step2-table" style="width: auto; margin-top: auto;">
                        <thead style="position: sticky; top: 0; z-index: 2;">
                            <tr class="list-table-header">
                                <th></th>
                                <th style="width: 30px; min-width: 30px;">No.</th>
                                <th style="width: 5.5vw">管理タイプ</th>
                                <th style="width: 8vw">請求タイプ</th>
                                <th>商品種別</th>
                                <th>金額</th>
                                <th>管理番号</th>
                                <th>商品名</th>
                                <th>数量</th>
                                <th>開始</th>
                                <th>終了</th>
                                <th>貸出期間</th>
                                <th>備考</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(param, index) in params">
                                <tr class="list-table-row" style="cursor: default;" :key="'tr_' + index" :id="'tr_' + index">
                                    <td>
                                        <img :style="{'opacity': param.update_flag && params.length > 1 ? 1 : 0}" class="table-close-button" style="left: 0; right: auto;" src="../assets/img/delete.png" @click="deleteRow(index)">
                                    </td>
                                    <td style="width: 30px; min-width: 30px;">
                                        <!-- ID -->
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <!-- 管理タイプ -->
                                        <template v-if="param.update_flag">
                                            <span @click="toScroll(index)">
                                                <v-select class="v-select-border" style="width: 5.5vw" :clearable="false" :options="options_manage_type" :reduce="options_manage_type => options_manage_type.id" v-model="manage_type[index]" @input="statusUpdate(index, false, true)">
                                                    <template #no-options>データが存在しません</template>
                                                </v-select>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="manage_type_label[manage_type[index] - 1]">{{ manage_type_label[manage_type[index] - 1] }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 請求タイプ -->
                                        <template v-if="param.update_flag">
                                            <span @click="toScroll(index)">
                                                <v-select class="v-select-border" style="width: 8vw" :clearable="false" :options="options_demand_type[index]" :reduce="options_demand_type => options_demand_type.id" v-model="demand_type[index]" @input="statusUpdate(index)">
                                                    <template #no-options>データが存在しません</template>
                                                </v-select>
                                            </span>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="demand_type_label[demand_type[index - 1]]">{{ demand_type_label[demand_type[index] - 1] }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 商品種別 -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].item_category">
                                                <span @click="toScroll(index)">
                                                    <v-select class="v-select-border" style="width: 10vw" :clearable="false" :options="options_item_category" :reduce="options_item_category => options_item_category.id" v-model="item_category[index]">
                                                        <template #no-options>データが存在しません</template>
                                                    </v-select>
                                                </span>
                                            </template>
                                            <template v-else>
                                                <input type="number" class="readonly" style="width: 10vw" readonly>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span v-if="itemCategoryMasterList !== null" v-tooltip="getCategoryById(item_category[index])">{{ getCategoryById(item_category[index]) }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 金額 -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].price">
                                                <input type="number" @input="param.price = inputNumberMaxLength(param.price, 9)" v-model="param.price" style="width: 4vw">
                                            </template>
                                            <template v-else>
                                                <input type="number" class="readonly" style="width: 4vw" readonly>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="param.price">{{ param.price }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 管理番号 -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].control_number && item_category[index] !== null">
                                                <input type="number" @input="control_number[index] = inputNumberMaxLength(control_number[index], 5)" v-model="control_number[index]" @blur="searchItem(index)">
                                            </template>
                                            <template v-else>
                                                <input type="text" class="readonly" readonly>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="control_number[index]">{{ control_number[index] }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 商品名(プルダウンの場合もあり) -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].name && !params_flag[index].name_pulldown">
                                                <input type="text" maxlength="64" v-model="param.name" @blur="getRentalStockNote(index)" style="width: 12vw">
                                            </template>
                                            <template v-else-if="!params_flag[index].name && params_flag[index].name_pulldown">
                                                <v-select class="v-select-border" style="display: inline-block; width: 12vw" :options="options_item" :reduce="options_item => options_item.id" v-model="item_id[index]" @input="getRentalStockNote(index)">
                                                    <template #no-options>データが存在しません</template>
                                                </v-select>
                                            </template>
                                            <template v-else-if="!params_flag[index].name && !params_flag[index].name_pulldown && params_flag[index].name_readonly">
                                                <span v-tooltip="param.name" style="width: 12vw">{{ param.name }}</span>
                                            </template>
                                            <template v-else>
                                                <input type="text" class="readonly" style="width: 12vw" readonly>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="param.name" style="width: 12vw">{{ param.name }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 数量 -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].count">
                                                <input type="number" style="width: 1.5vw" @input="param.count = inputNumberMaxLength(param.count, 9)" v-model="param.count">
                                            </template>
                                            <template v-else>
                                                <input type="number" style="width: 1.5vw" class="readonly" readonly>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="param.count">{{ param.count }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 開始 -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].start_date">
                                                <datepicker :language="language" format="yyyy/MM/dd" v-model="start_date[index]" style="width: 5vw" />
                                            </template>
                                            <template v-else>
                                                <input type="text" class="readonly" readonly>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="start_date[index]">{{ start_date[index] }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 終了 -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].end_date">
                                                <datepicker :language="language" format="yyyy/MM/dd" v-model="end_date[index]" style="width: 5vw" />
                                            </template>
                                            <template v-else>
                                                <input type="text" class="readonly" readonly>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="end_date[index]">{{ end_date[index] }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 請求間隔（月） -->
                                        <template v-if="param.update_flag">
                                            <template v-if="params_flag[index].season_month && demand_type[index] !== 13">
                                                <input style="width: 15px" type="number" maxlength="2" v-model="season_1[index]">月～
                                                <input style="width: 15px" type="number" maxlength="2" v-model="season_2[index]">月
                                            </template>
                                            <template v-else-if="params_flag[index].season_month && demand_type[index] === 13">
                                                <span @click="toScroll(index)">
                                                    <datepicker :language="language" format="yyyy/MM/dd" placeholder="開始日" v-model="season_1[index]" style="width: 5vw; display: inline-block;" />
                                                    <datepicker :language="language" format="yyyy/MM/dd" placeholder="終了日" v-model="season_2[index]" style="width: 5vw; display: inline-block;" />
                                                </span>
                                            </template>
                                            <template v-else>
                                                <input class="readonly" style="width: 15px" type="number" readonly>月～
                                                <input class="readonly" style="width: 15px" type="number" readonly>月
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-if="season_1[index] !== null && season_2[index] !== null">
                                                <span v-tooltip="season_1[index] + '月～ ' + season_2[index] + '月'">{{ season_1[index] + '月～ ' + season_2[index] + '月' }}</span>
                                            </template>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 備考 -->
                                        <template v-if="param.update_flag">
                                            <input type="text" maxlength="200" v-model="param.note" style="width: 10vw">
                                        </template>
                                        <template v-else>
                                            <span v-tooltip="param.note">{{ param.note }}</span>
                                        </template>
                                    </td>
                                </tr>
                                <template v-if="param.option !== undefined && param.option !== null">
                                    <tr class="option-background" :key="'tr_back_1_' + index">
                                        <td></td>
                                        <td style="width: 30px; min-width: 30px;"></td>
                                        <td class="add-button" v-if="params_flag[index].is_add">
                                            <button @click="addOption(index)">
                                                追加
                                            </button>
                                        </td>
                                        <td v-else></td>
                                        <td colspan="10"></td>
                                    </tr>
                                    <template v-for="(option, option_index) in param.option">
                                        <tr class="list-table-row option-table" style="cursor: default;" :key="index + 'options_' + option_index">
                                            <td class="background"></td>
                                            <td style="width: 30px; min-width: 30px;"></td>
                                            <td></td>
                                            <td>
                                                <label class="check" :for="'check' + index + '_' + option_index">
                                                    <input type="checkbox" :id="'check' + index + '_' + option_index" style="display: none;" v-model="option.flag">
                                                    <div class="check-text">有効</div>
                                                    <div class="checkbox"><img v-if="option.flag" src="../assets/img/checkbox.png"></div>
                                                </label>
                                            </td>
                                            <td></td>
                                            <td>
                                                <input type="number" placeholder="金額" @input="option.price = inputNumberMaxLength(option.price, 9)" v-model="option.price">
                                            </td>
                                            <td>
                                                <template v-if="params_flag[index].is_add">
                                                    <v-select :options="options_tax" class="v-select-border" :clearable="false" :reduce="options_tax => options_tax.value" v-model="option.tax">
                                                        <template #no-options>データが存在しません</template>
                                                    </v-select>
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="manage_type[index] === 2">
                                                    <input type="text" v-model="option.name" placeholder="オプション名" style="width: 12vw">
                                                </template>
                                                <template v-else>
                                                    <span v-tooltip="option.name">{{ option.name }}</span>
                                                </template>
                                            </td>
                                            <td>
                                                <input type="number" style="width: 1.5vw" placeholder="数量" @input="option.count = inputNumberMaxLength(option.count, 9)" v-model="option.count">
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </template>
                                    <tr class="option-background" :key="'tr_back_2_' + index">
                                        <td colspan="13"></td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-cancel" v-if="is_cancel" @click="is_cancel_modal = true">取消</button>
            <button class="button-prev" onclick="javascript:history.back()">戻る</button>
            <button class="button-next" @click="update()">更新</button>
		</div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        <div v-for="index in error_index" :key="index + '_error'">
                            No.{{ index + 1 }}の入力に不備があります。
                        </div>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_cancel_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_cancel_modal = false">
                    <div id="modal-text">
                        取消処理を行うと、元に戻すことは出来ません。
                        本当に取消処理を行って宜しいですか？
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="is_cancel_modal = false">いいえ</button>
                    <button id="yes" @click="cancel()">はい</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_control_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_control_modal = false">
                    <div id="modal-text">
                        入力した管理番号は存在しません。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_control_modal = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_stock_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_stock_error = false">
                    <div id="modal-text">
                        入力した在庫は貸出中です。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_stock_error = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_stock_repair_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_stock_repair_error = false">
                    <div id="modal-text">
                        入力した在庫は修理中です。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_stock_repair_error = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_stock_delete_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_stock_delete_error = false">
                    <div id="modal-text">
                        対象在庫は滅失処理済の為、選択出来ません。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_stock_delete_error = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_add_modal">
            <div id="modal-background-large" style="height: 50%">
                <div id="modal-content">
                    <div id="modal-title">
                        まとめて追加
                    </div>
                    <img src="../assets/img/close.png" @click="closeAddModal()">
                    <div id="modal-text" style="height: auto;">
                        <table class="list-table slip-regist-step2-table">
                            <tr class="list-table-header">
                                <th style="width: auto">請求タイプ</th>
                                <th>商品種別</th>
                                <th>開始日</th>
                                <th>終了日</th>
                                <th>貸出期間</th>
                                <th>備考</th>
                            </tr>
                        </table>
                        <div class="table-scroller" style="height: auto;">
                            <table class="contents-scroll-table">
                                <tr class="list-table-row" style="cursor: default; position: initial;">
                                    <td style="width: auto">
                                        <!-- 請求タイプ -->
                                        <v-select class="v-select-border" :clearable="false" :options="add_options_demand_type" :reduce="add_options_demand_type => add_options_demand_type.id" v-model="add_params.demand_type">
                                            <template #no-options>データが存在しません</template>
                                        </v-select>
                                    </td>
                                    <td>
                                        <!-- 商品種別 -->
                                        <v-select class="v-select-border" :clearable="false" :options="options_item_category" :reduce="options_item_category => options_item_category.id" v-model="add_item_category">
                                            <template #no-options>データが存在しません</template>
                                        </v-select>
                                    </td>
                                    <td>
                                        <datepicker :language="language" format="yyyy/MM/dd" v-model="add_params.start_date" style="width: 5vw" />
                                    </td>
                                    <td>
                                        <template v-if="add_params.manage_type === 2">
                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="add_params.end_date" style="width: 5vw" />
                                        </template>
                                        <template v-else>
                                            <input class="readonly" type="number" readonly>
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="add_params.demand_type === 3 || add_params.demand_type === 9">
                                            <input style="width: 30px" type="number" maxlength="2" v-model="add_params.season_1">
                                            <input style="width: 30px" type="number" maxlength="2" v-model="add_params.season_2">
                                        </template>
                                        <template v-if="add_params.demand_type === 13">
                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="add_params.season_1" style="width: 5vw; display: inline-block;" />
                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="add_params.season_2" style="width: 5vw; display: inline-block;" />
                                        </template>
                                        <template v-else>
                                            <input class="readonly" style="width: 30px" type="number" readonly>
                                            <input class="readonly" style="width: 30px" type="number" readonly>
                                        </template>
                                    </td>
                                    <td>
                                        <input type="text" maxlength="200" v-model="add_params.note">
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="add-modal-textarea">
                            <div id="lines">
                                <template v-for="(item, index) in lines_num">
                                    <span class="line-number" :key="'lines_' + index">{{ index + 1 }}.</span>
                                </template>
                            </div>
                            <textarea id="control-number-area" data-lines=”number” cols="10" rows="7" ref="textarea" v-model="add_textarea" @keydown.enter="checkTextarea()" @blur="searchAddItemList()"></textarea>
                            <div id="items">
                                <template v-for="(item, index) in add_items">
                                    <span class="line-number" :key="'items_' + index">
                                        <template v-if="item !== null && item.status == 1">
                                            {{ item.name }}
                                        </template>
                                        <template v-else-if="item !== null && item.status === 2">
                                            {{ item.name + "(貸出中)"}}
                                        </template>
                                        <template v-else-if="item !== null && item.status === 3">
                                            {{ item.name + "(修理中)"}}
                                        </template>
                                        <template v-else>
                                            <template v-if="search_add_params[index].split('-')[1].match(/[^0-9]/) === null">
                                                該当なし
                                            </template>
                                            <template v-else>
                                                不正な文字列があります
                                            </template>
                                        </template>
                                    </span>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="closeAddModal()">閉じる</button>
                    <button id="yes" @click="openAddConfirmModal()">追加</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_add_rental_modal">
            <div id="modal-background-large">
                <div id="modal-content">
                    <div id="modal-title">
                        他社借りまとめて追加
                    </div>
                    <img src="../assets/img/close.png" @click="closeAddRentalModal()">
                    <div id="modal-text" style="height: auto;">
                        <div class="table-scroller2 contents-scroll-table" style="margin-top: 30px;">
                            <table class="list-table slip-regist-step2-table" style="width: auto; margin-top: auto;">
                                <thead style="position: sticky; top: 0; z-index: 2;">
                                    <tr class="list-table-header">
                                        <th style="width: auto">請求タイプ</th>
                                        <th>金額</th>
                                        <th>商品名</th>
                                        <th>数量</th>
                                        <th>開始日</th>
                                        <th>終了日</th>
                                        <th>備考</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="list-table-row" style="cursor: default;">
                                        <td style="width: 8vw">
                                            <!-- 請求タイプ -->
                                            <v-select class="v-select-border" :clearable="false" :options="add_rental_options_demand_type" :reduce="add_rental_options_demand_type => add_rental_options_demand_type.id" v-model="add_params.demand_type">
                                                <template #no-options>データが存在しません</template>
                                            </v-select>
                                        </td>
                                        <td>
                                            <input type="number" @input="add_params.price = inputNumberMaxLength(add_params.price, 9)" v-model="add_params.price">
                                        </td>
                                        <td>
                                            <input type="text" style="width: 10vw" maxlength="64" v-model="add_params.name">
                                        </td>
                                        <td>
                                            <input type="number" style="width: 1.5vw" @input="add_params.count = inputNumberMaxLength(add_params.count, 9)" v-model="add_params.count">
                                        </td>
                                        <td>
                                            <datepicker :language="language" format="yyyy/MM/dd" v-model="add_params.start_date" style="width: 5vw" />
                                        </td>
                                        <td>
                                            <template v-if="add_params.manage_type === 2 && (add_params.demand_type === 3 || add_params.demand_type === 9 || add_params.demand_type === 13)">
                                                <datepicker :language="language" format="yyyy/MM/dd" v-model="add_params.end_date" style="width: 5vw" />
                                            </template>
                                            <template v-else>
                                                <input class="readonly" type="number" style="width: 5vw" readonly>
                                            </template>
                                        </td>
                                        <td>
                                            <input type="text" maxlength="200" style="width: 12vw" v-model="add_params.note">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="closeAddRentalModal()">閉じる</button>
                    <button id="yes" @click="openRentalAddConfirmModal()">追加</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_add_modal_confirm">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        確認
                    </div>
                    <img src="../assets/img/close.png" @click="is_add_modal_confirm = false">
                    <div id="modal-text" v-if="itemMasterList !== null && itemCategoryMasterList !== null">
                        {{ getCategoryName() }}を{{ add_items.length }}個登録します。よろしいですか
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="is_add_modal_confirm = false">閉じる</button>
                    <button id="yes" @click="addRowModal()">登録
                    </button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_add_rental_modal_confirm">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        確認
                    </div>
                    <img src="../assets/img/close.png" @click="is_add_rental_modal_confirm = false">
                    <div id="modal-text">
                        {{ add_params.name }}を{{ add_params.count }}個登録します。よろしいですか
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="is_add_rental_modal_confirm = false">閉じる</button>
                    <button id="yes" @click="addRentalRowModal()">登録</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_add_modal_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_add_modal_error = false">
                    <div id="modal-text">
                        <template v-for="(item, index) in add_modal_error_texts">
                            <span :key="'add_error_texts_' + index">{{ item }}<br></span>
                        </template>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_add_modal_error = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex'
import moment from 'moment';
import { inputNumberMaxLength } from '../util';
import $ from 'jquery'
export default {
    components: {
        Datepicker,
        vSelect,
    },
	data() {
		return {
            params: [
                {
                    control_number: null, // 管理番号
                    manage_type: null, // 管理タイプ
                    stock_id: null, // 在庫ID
                    item_id: null, // アイテムID
                    demand_type: null, // 請求タイプ
                    price: null, // 金額
                    count: null, // 数量
                    start_date: null, // 開始日時
                    end_date: null, // 終了日時
                    name: null, // 商品名 他社借りの場合入る
                    season_month: null, // シーズン選択時に開始、終了を選択する
                    note: null, // 備考
                    option: null, // オプション
                },
            ],
            params_flag: [
                {
                    control_number: false, // 管理番号
                    name: false, // 商品名
                    name_pulldown: false, // 商品名プルダウン
                    name_readonly: false, // 商品名編集不可
                    price: false, // 金額
                    count: false, // 数量
                    start_date: false, // 開始日時
                    end_date: false, // 終了日時
                    season_month: false, // シーズン選択時に開始、終了を選択する
                    note: false, // 備考
                    is_add: false, // オプションの追加ボタン
                    item_category: true, // 商品種別プルダウン
                },
            ],
            control_number: [null],
            manage_type: [null],
            demand_type: [null],
            item_id: [null],
            start_date: [null],
            end_date: [null],
            season_1: [null],
            season_2: [null],
            options_manage_type: [
                { label: '自社', id: 1 },
                { label: '他社', id: 2 },
            ],
            options_demand_type: [[
                { label: '日額', id: 1 },
                { label: '月額', id: 2 },
                { label: 'シーズン(日額)', id: 13 },
                { label: 'シーズン(月額)', id: 9 },
                { label: 'シーズン(一括)', id: 3 },
                { label: '一括', id: 4 },
                { label: '販売(消耗品)', id: 5 },
                { label: '販売(管理品)', id: 6 },
                { label: '付帯', id: 7 },
                { label: '滅失', id: 8 },
                { label: '日額(管理なし)', id: 10 },
                { label: '月額(管理なし)', id: 11 },
                { label: '一括(管理なし)', id: 12 },
            ]],
            options_item: [],
            manage_type_label: [
                '自社',
                '他社'
            ],
            demand_type_label: [
                '日額',
                '月額',
                'シーズン',
                '一括',
                '販売(消耗品)',
                '販売(管理品)',
                '付帯',
                '滅失',
            ],
            is_modal: false,
            is_cancel: false,
            is_cancel_modal: false,
            delete_ids: [],
            is_control_modal: false,
            error_index: [],
            is_add_modal: false,
            add_params: {
                control_number: null, // 管理番号
                manage_type: 1, // 管理タイプ
                stock_id: null, // 在庫ID
                item_id: null, // アイテムID
                demand_type: 1, // 請求タイプ
                price: null, // 金額
                count: null, // 数量
                start_date: null, // 開始日時
                end_date: null, // 終了日時
                name: null, // 商品名 他社借りの場合入る
                season_month: null, // シーズン選択時に開始、終了を選択する
                season_1: null,
                season_2: null,
                note: null, // 備考
                option: null, // オプション
                in_house_stock_id: null, // 自社管理なし在庫ID
            },
            add_options_demand_type: [
                { label: '日額', id: 1 },
                { label: '月額', id: 2 },
                { label: 'シーズン(日額)', id: 13 },
                { label: 'シーズン(月額)', id: 9 },
                { label: 'シーズン(一括)', id: 3 },
                { label: '一括', id: 4 },
                { label: '販売(消耗品)', id: 5 },
                { label: '販売(管理品)', id: 6 },
                { label: '付帯', id: 7 },
                { label: '滅失', id: 8 },
                { label: '日額(管理なし)', id: 10 },
                { label: '月額(管理なし)', id: 11 },
                { label: '一括(管理なし)', id: 12 },
            ],
            is_add_modal_error: false,
            is_add_modal_confirm: false,
            is_add_rental_modal: false,
            add_rental_options_demand_type: [
                { label: '日額', id: 1 },
                { label: '月額', id: 2 },
                { label: 'シーズン(日額)', id: 13 },
                { label: 'シーズン(月額)', id: 9 },
                { label: 'シーズン(一括)', id: 3 },
            ],
            is_add_rental_modal_confirm: false,
            options_item_category: [],
            item_category: [null],
            add_item_category: null,
            add_textarea: "",
            lines_num: 1,
            scroll_event: null,
            add_items: [],
            add_model_number_list: [],
            add_modal_error_texts: [],
            search_add_params: [],
            is_stock_error: false,
            is_stock_repair_error: false,
            options_tax: [
				{label: '10%', value: 10},
                {label: '8%', value: 8},
			],
            is_stock_delete_error: false,
		}
	},
	created() {
        // 復元処理
        if (this.updateParams.slip !== undefined) {
            this.restoreParams()
        }

        // 販売商品検索
        this.getBillingItemMasterList()

        // 商品種別設定
        this.getItemMasterList()

        // まとめて追加のstart_date初期設定
        this.add_params.start_date = this.updateParams.moving_date
	},
	methods: {
        update() {
            let slip = []
            let delete_option_ids = []
            this.params.filter((item, index) => {
                let data = item
                data.manage_type = this.manage_type[index]
                data.demand_type = this.demand_type[index]
                data.item_id = this.item_id[index]
                if (this.start_date[index] !== null) {
                    data.start_date = moment(this.start_date[index]).format('YYYY-MM-DD')
                }
                if (this.end_date[index] !== null) {
                    data.end_date = moment(this.end_date[index]).format('YYYY-MM-DD')
                }
                if (this.season_1[index] !== null && this.season_1[index] !== "" && this.season_2[index] !== null && this.season_2[index] !== "") {
                    if (this.demand_type[index] === 13) {
                        let season_1 = this.season_1[index]
                        let season_2 = this.season_2[index]
                        data.season_month = moment(season_1).format('YYYYMMDD') + "," + moment(season_2).format('YYYYMMDD')
                    }
                    else {
                        data.season_month = this.season_1[index] + "," + this.season_2[index]
                    }
                }

                if (data.end_date === "Invalid date") {
                    data.end_date = null
                }
                if (item.option !== undefined && item.option !== null) {
                    item.option.filter((option => {
                        if (!option.flag && option.id !== undefined) {
                            delete_option_ids.push(option.id)
                        }
                    }))
                }
                slip.push(data)
            })

            // 入力チェック
            let is_error = false
            this.error_index = []
            slip.filter((item, index) => {
                if (!this.checkParam(item)) {
                    is_error = true
                    this.error_index.push(index)
                }
            })

            if (!is_error) {
                let params = {
                    ...this.updateParams,
                    slip: slip,
                }
                params.slip.filter(item => {
                    if (item.option !== undefined && item.option !== null) {
                        if (item.demand_type === 1) {
                            item.option.filter(item => {
                                item.rental_flag = false
                            })
                        }
                        else {
                            item.option.filter(item => {
                                item.rental_flag = true
                            })
                        }
                    }
                })
                this.delete_ids.filter(item => {
                    if (item !== undefined && item !== null) {
                        this.$store.dispatch('loading/startLoad')
                            .then(() => this.$store.dispatch('slip/deleteAPi', {id: item, type: 2}))
                            .then(() => {
                                this.$store.commit('slip/setUpdateParams', null)
                                this.$store.dispatch('loading/endLoad')
                            })
                    }
                })

                delete_option_ids.filter(item => {
                    this.$store.dispatch('loading/startLoad')
                        .then(() => this.$store.dispatch('slip/deleteAPi', {id: item, type: 3}))
                        .then(() => {
                            this.$store.commit('slip/setUpdateParams', null)
                            this.$store.dispatch('loading/endLoad')
                        })
                })

                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('slip/postSlipApi', params))
                    .then(() => {
                        this.$store.commit('slip/setUpdateParams', null)
                        this.$store.commit('slip/setBaseData', null)
                        this.$store.dispatch('loading/endLoad')
                        this.$router.push('slip')
                    })
            }
            else {
                this.is_modal = true
            }
        },
        // 入力チェック処理
        checkParam(param) {
            let result = false
            // 自社 日額 ＊金額、管理番号、開始日時
            if (param.manage_type === 1 && param.demand_type === 1) {
                if (param.price !== null && param.control_number !== null && param.start_date !== null &&
                    param.price !== "" && param.control_number !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 自社 月額 ＊金額、管理番号、開始日時
            else if (param.manage_type === 1 && param.demand_type === 2) {
                if (param.price !== null && param.control_number !== null && param.start_date !== null &&
                    param.price !== "" && param.control_number !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 自社 シーズン ＊金額、管理番号、開始日時、貸出期間(月数)
            else if (param.manage_type === 1 && (param.demand_type === 3 || param.demand_type === 9 || param.demand_type === 13)) {
                if (param.price !== null && param.control_number !== null && param.start_date !== null && param.season_month !== null &&
                    param.price !== "" && param.control_number !== "" && param.start_date !== "" && param.season_month !== "") {
                    result = true
                }
            }
            // 自社 一括 ＊金額、管理番号、開始日時
            else if (param.manage_type === 1 && param.demand_type === 4) {
                if (param.price !== null && param.control_number !== null && param.start_date !== null &&
                    param.price !== "" && param.control_number !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 自社 販売（消耗品） ＊金額、商品名プルダウン、数量、開始日時
            else if (param.manage_type === 1 && param.demand_type === 5) {
                if (param.price !== null && param.item_id !== null && param.count !== null && param.start_date !== null &&
                    param.price !== "" && param.item_id !== "" && param.count !== "" & param.start_date !== "") {
                    result = true
                }
            }
            // 自社 販売（管理品） ＊金額、管理番号、数量、開始日時
            else if (param.manage_type === 1 && param.demand_type === 6) {
                if (param.price !== null && param.control_number !== null && param.count !== null && param.start_date !== null &&
                    param.price !== "" && param.control_number !== "" && param.count !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 自社 付帯 ＊金額、商品名、数量、開始日時
            else if (param.manage_type === 1 && param.demand_type === 7) {
                if (param.price !== null && param.name !== null && param.count !== null && param.start_date !== null &&
                    param.price !== "" && param.name !== "" && param.count !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 自社 滅失 ＊金額、管理番号、開始日時
            else if (param.manage_type === 1 && param.demand_type === 8) {
                if (param.price !== null && param.control_number !== null && param.start_date !== null &&
                    param.price !== "" && param.control_number !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 自社 日額、月額、一括（管理なし） ＊金額、商品名、開始日時
            else if (param.manage_type === 1 && (param.demand_type === 10 || param.demand_type === 11 || param.demand_type === 12)) {
                if (param.price !== null && param.name !== null && param.start_date !== null &&
                    param.price !== "" && param.name !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 他社 日額 ＊金額、商品名、開始日時
            else if (param.manage_type === 2 && param.demand_type === 1) {
                if (param.price !== null && param.name !== null && param.start_date !== null &&
                    param.price !== "" && param.name !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 他社 月額 ＊金額、商品名、開始日時
            else if (param.manage_type === 2 && param.demand_type === 2) {
                if (param.price !== null && param.name !== null && param.start_date !== null &&
                    param.price !== "" && param.name !== "" && param.start_date !== "") {
                    result = true
                }
            }
            // 他社 シーズン ＊金額、商品名、開始日時、終了日時
            else if (param.manage_type === 2 && (param.demand_type === 3 || param.demand_type === 9 || param.demand_type === 13)) {
                if (param.price !== null && param.name !== null && param.start_date !== null && param.end_date !== null &&
                    param.price !== "" && param.name !== "" && param.start_date !== "" && param.end_date !== "") {
                    result = true
                }
            }
            // 他社 滅失 ＊金額、商品名プルダウン、開始日時
            else if (param.manage_type === 2 && param.demand_type === 8) {
                if (param.price !== null && param.item_id !== null && param.start_date !== null &&
                    param.price !== "" && param.item_id !== "" && param.start_date !== "") {
                    result = true
                }
            }

            return result
        },
        // 取引先IDから取引先名を取得する
		getItemCategoryNameById(id) {
			let result = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
        // 管理タイプ、請求タイプに応じて入力可能箇所の表示切り替え
        statusUpdate(index, is_restore = false, is_all_clear = false, is_search_item = false) {
            // 請求タイプのプルダウン内容を更新する
            if (this.options_demand_type[index] !== undefined) {
                this.options_demand_type[index].splice(0, this.options_demand_type[index].length)
            
                if (this.manage_type[index] === 1) {
                    this.options_demand_type[index].push(...[
                        { label: '日額', id: 1 },
                        { label: '月額', id: 2 },
                        { label: 'シーズン(日額)', id: 13 },
                        { label: 'シーズン(月額)', id: 9 },
                        { label: 'シーズン(一括)', id: 3 },
                        { label: '一括', id: 4 },
                        { label: '販売(消耗品)', id: 5 },
                        { label: '販売(管理品)', id: 6 },
                        { label: '付帯', id: 7 },
                        { label: '滅失', id: 8 },
                        { label: '日額(管理なし)', id: 10 },
                        { label: '月額(管理なし)', id: 11 },
                        { label: '一括(管理なし)', id: 12 },
                    ])
                }
                else {
                    this.options_demand_type[index].push(...[
                        { label: '日額', id: 1 },
                        { label: '月額', id: 2 },
                        { label: 'シーズン(日額)', id: 13 },
                        { label: 'シーズン(月額)', id: 9 },
                        { label: 'シーズン(一括)', id: 3 },
                    ])
                }
            }

            let flag = this.params[index].update_flag
            if (is_restore) {
                this.params[index].update_flag = flag
            }
            else {
                const start_date = this.params[index].start_date
                if (is_all_clear) {
                    let param = {
                        control_number: null,
                        manage_type: null,
                        stock_id: null,
                        item_id: null,
                        demand_type: null,
                        price: null,
                        count: null,
                        start_date: start_date,
                        end_date: null,
                        name: null,
                        season_month: null,
                        note: null,
                        option: null,
                        update_flag: flag,
                    }
                    this.$set(this.params, index, param)
                    this.$set(this.control_number, index, null)
                }
                else {
                    const control_number = this.params[index].control_number
                    const name = this.params[index].name
                    const price = this.params[index].price
                    const note = this.params[index].note
                    const end_date = this.params[index].end_date
                    const season_month = this.params[index].season_month
                    let param = {
                        control_number: control_number,
                        manage_type: null,
                        stock_id: null,
                        item_id: null,
                        demand_type: null,
                        price: price,
                        count: null,
                        start_date: start_date,
                        end_date: end_date,
                        name: name,
                        season_month: season_month,
                        note: note,
                        option: null,
                        update_flag: flag,
                    }
                    this.$set(this.params, index, param)
                    this.$set(this.control_number, index, control_number)
                }
                this.$set(this.item_id, index, null)
                this.$set(this.end_date, index, null)
                this.$set(this.season_1, index, null)
                this.$set(this.season_2, index, null)
            }

            this.params_flag[index] = {
                control_number: false,
                name: false,
                name_pulldown: false,
                price: false,
                count: false,
                start_date: false,
                end_date: false,
                season_month: false,
                note: false,
                is_add: false,
                item_category: false,
            }
            // 自社 日額
            if (this.manage_type[index] === 1 && this.demand_type[index] === 1) {
                this.params_flag[index].item_category = true
                this.params_flag[index].control_number = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                if (is_search_item && this.params[index].control_number !== null) {
                    this.searchItem(index)
                }
            }
            // 自社 月額
            else if (this.manage_type[index] === 1 && this.demand_type[index] === 2) {
                this.params_flag[index].item_category = true
                this.params_flag[index].control_number = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                if (is_search_item && this.params[index].control_number !== null) {
                    this.searchItem(index)
                }
            }
            // 自社 シーズン
            else if (this.manage_type[index] === 1 && (this.demand_type[index] === 3 || this.demand_type[index] === 9 || this.demand_type[index] === 13)) {
                this.params_flag[index].item_category = true
                this.params_flag[index].control_number = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                this.params_flag[index].season_month = true
                if (is_search_item && this.params[index].control_number !== null) {
                    this.searchItem(index)
                }
            }
            // 自社 一括
            else if (this.manage_type[index] === 1 && this.demand_type[index] === 4) {
                this.params_flag[index].item_category = true
                this.params_flag[index].control_number = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                if (is_search_item && this.params[index].control_number !== null) {
                    this.searchItem(index)
                }
            }
            // 自社 販売(消耗品)
            else if (this.manage_type[index] === 1 && this.demand_type[index] === 5) {
                this.params_flag[index].name_pulldown = true
                this.params_flag[index].price = true
                // this.params_flag[index].count = true
                this.params_flag[index].start_date = true
                this.params[index].count = 1
            }
            // 自社 販売(管理品)
            else if (this.manage_type[index] === 1 && this.demand_type[index] === 6) {
                this.params_flag[index].item_category = true
                this.params_flag[index].control_number = true
                this.params_flag[index].price = true
                // this.params_flag[index].count = true
                this.params_flag[index].start_date = true
                this.params[index].count = 1
                if (is_search_item && this.params[index].control_number !== null) {
                    this.searchItem(index)
                }
            }
            // 自社 付帯
            else if (this.manage_type[index] === 1 && this.demand_type[index] === 7) {
                this.params_flag[index].name = true
                this.params_flag[index].price = true
                // this.params_flag[index].count = true
                this.params_flag[index].start_date = true
                this.params[index].count = 1
            }
            // 自社 滅失
            else if (this.manage_type[index] === 1 && this.demand_type[index] === 8) {
                this.params_flag[index].item_category = true
                this.params_flag[index].control_number = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                if (is_search_item && this.params[index].control_number !== null) {
                    this.searchItem(index)
                }
            }
            // 自社 日額、月額、一括（管理なし）
            else if (this.manage_type[index] === 1 && (this.demand_type[index] === 10 || this.demand_type[index] === 11 || this.demand_type[index] === 12)) {
                this.params_flag[index].name = true
                this.params_flag[index].price = true
                this.params_flag[index].count = true
                this.params_flag[index].start_date = true
            }
            // 他社 日額
            else if (this.manage_type[index] === 2 && this.demand_type[index] === 1) {
                this.params_flag[index].name = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                this.params_flag[index].is_add = true
                this.params_flag[index].count = true
                this.params[index].count = 1
                if (this.params[index].option === undefined || this.params[index].option === null) {
                    this.params[index].option = []
                }
            }
            // 他社 月額
            else if (this.manage_type[index] === 2 && this.demand_type[index] === 2) {
                this.params_flag[index].name = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                this.params_flag[index].is_add = true
                this.params_flag[index].count = true
                this.params[index].count = 1
                if (this.params[index].option === undefined || this.params[index].option === null) {
                    this.params[index].option = []
                }
            }
            // 他社 シーズン
            else if (this.manage_type[index] === 2 && (this.demand_type[index] === 3 || this.demand_type[index] === 9 || this.demand_type[index] === 13)) {
                this.params_flag[index].name = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                this.params_flag[index].end_date = true
                this.params_flag[index].is_add = true
                this.params_flag[index].count = true
                this.params[index].count = 1
                if (this.params[index].option === undefined || this.params[index].option === null) {
                    this.params[index].option = []
                }
            }
            // 他社 滅失
            else if (this.manage_type[index] === 2 && this.demand_type[index] === 8) {
                this.params_flag[index].name_pulldown = true
                this.params_flag[index].price = true
                this.params_flag[index].start_date = true
                this.params_flag[index].end_date = true
                this.params_flag[index].count = true
                this.params[index].count = 1
            }
        },
        searchItem(index) {
            // 商品種別、管理番号が入力されているなら検索する
            if (this.control_number[index] !== null && this.control_number[index] !== "" && this.item_category[index] !== null && this.item_category[index] !== "") {
                // 商品種別、管理番号の先頭を０埋めする
                const item_category = ("0000" + this.item_category[index]).slice(-4)
                const control_number = ("00000" + this.control_number[index]).slice(-5)
                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('stock/getStockApi', {control_number: item_category + "-" + control_number}))
                    .then(() => {
                        if (this.stockList.list.length > 0) {
                            // 滅失だった場合、オプション検索しない
                            if (this.demand_type[index] === 8) {
                                let param = this.params[index]
                                param.stock_id = this.stockList.list[0].id
                                param.item_id = this.stockList.list[0].item_id
                                param.name = this.stockList.list[0].name
                                this.params_flag[index].name_readonly = true
                                if (this.manage_type[index] === 1 && this.demand_type[index] === 1 || this.demand_type[index] === 2) {
                                    this.$store.dispatch('itemMaster/getItemPriceApi', {item_id: param.item_id, type: this.demand_type[index]})
                                        .then(() => {
                                            if (this.itemPrice !== undefined && this.itemPrice !== null && this.itemPrice.price !== undefined) {
                                                this.params[index].price = this.itemPrice.price
                                            }
                                            this.params.splice(index, 1, param)
                                        })
                                }
                                else {
                                    this.params.splice(index, 1, param)
                                }
                            }
                            else {
                                const stock_id_list = this.slipDetailBaseData.slip.map(item => item["stock_id"])
                                if (stock_id_list.indexOf(this.stockList.list[0].id) !== -1 || this.stockList.list[0].status === 1) {
                                    let param = this.params[index]
                                    param.stock_id = this.stockList.list[0].id
                                    param.item_id = this.stockList.list[0].item_id
                                    param.name = this.stockList.list[0].name
                                    this.params_flag[index].name_readonly = true
                                    const category_id = this.stockList.list[0].item_category_id
                                    this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterDetailApi', {id: category_id})
                                        .then(() => {
                                            if (this.itemCategoryMasterDetail.option === undefined || this.itemCategoryMasterDetail.option === null || this.itemCategoryMasterDetail.option.length === 0) {
                                                this.params[index].option = null
                                            }
                                            else {
                                                let options = []
                                                let ids = []
                                                let option_ids = []
                                                if (this.slipDetailBaseData.slip[index].option !== undefined && this.slipDetailBaseData.slip[index].option !== null) {
                                                    ids = this.slipDetailBaseData.slip[index].option.map(item => item["id"])
                                                    option_ids = this.slipDetailBaseData.slip[index].option.map(item => item["option_id"])
                                                }
                                                this.itemCategoryMasterDetail.option.filter((option, option_index) => {
                                                    if (this.demand_type[index] === 1) {
                                                        options.push({
                                                            id: ids[option_index] !== undefined ? ids[option_index] : null,
                                                            option_id: option.id,
                                                            price: option.default_amount,
                                                            count: option.default_num,
                                                            name: option.name,
                                                            flag: option_ids.indexOf(option.id) !== -1,
                                                            rental_flag: false,
                                                            tax: option.tax,
                                                        })
                                                    }
                                                    else {
                                                        options.push({
                                                            id: ids[option_index] !== undefined ? ids[option_index] : null,
                                                            option_id: option.id,
                                                            price: option.default_amount,
                                                            count: option.default_num,
                                                            name: option.name,
                                                            flag: option_ids.indexOf(option.id) !== -1,
                                                            rental_flag: true,
                                                            tax: option.tax,
                                                        })
                                                    }
                                                })
                                                param.option = options
                                            }
                                        })
                                    if (this.manage_type[index] === 1 && this.demand_type[index] === 1 || this.demand_type[index] === 2) {
                                        this.$store.dispatch('itemMaster/getItemPriceApi', {item_id: param.item_id, type: this.demand_type[index]})
                                            .then(() => {
                                                if (this.itemPrice !== undefined && this.itemPrice !== null && this.itemPrice.price !== undefined) {
                                                    this.params[index].price = this.itemPrice.price
                                                }
                                                this.params.splice(index, 1, param)
                                            })
                                    }
                                    else {
                                        this.params.splice(index, 1, param)
                                    }
                                }
                                else if (this.stockList.list[0].status === 2) {
                                    // 貸出済み在庫なのでエラー
                                    this.is_stock_error = true
                                    this.control_number[index] = null
                                }
                                else if (this.stockList.list[0].status === 3) {
                                    // 修理中在庫なのでエラー
                                    this.is_stock_repair_error = true
                                    this.control_number[index] = null
                                }
                                else if (this.stockList.list[0].status === 6) {
                                    // 滅失在庫なのでエラー
                                    this.is_stock_delete_error = true
                                    this.model_number[index] = null
                                }
                            }
                            this.$store.dispatch('loading/endLoad')
                        }
                        else {
                            // 入力内容をリセット
                            this.params[index].stock_id = null
                            this.params[index].item_id = null
                            this.params[index].price = null
                            this.params[index].count = null
                            this.params[index].end_date = null
                            this.params[index].name = null
                            this.params[index].season_month = null
                            this.params[index].note = null
                            this.params[index].option = null

                            this.is_control_modal = true
                            this.control_number[index] = null
                            this.item_id[index] = null
                            this.end_date[index] = null
                            this.season_1[index] = null
                            this.season_2[index] = null

                            this.$store.dispatch('loading/endLoad')
                        }
                    })
            }
        },
        addRow() {
            this.params.push({
                manage_type: 1,
                stock_id: null,
                item_id: null,
                demand_type: 1,
                price: null,
                count: null,
                start_date: null,
                end_date: null,
                name: null,
                season_month: null,
                note: null,
                option: null,
                update_flag: true,
            },)
            this.params_flag.push({
                control_number: true,
                name: false,
                name_pulldown: false,
                name_readonly: false,
                price: true,
                count: false,
                start_date: true,
                end_date: false,
                season_month: false,
                note: false,
                is_add: false,
                item_category: true,
            },)
            this.control_number.push('')
            this.manage_type.push(1)
            this.demand_type.push(1)
            this.item_id.push(null)
            this.start_date.push(this.updateParams.moving_date)
            this.end_date.push(null)
            this.season_1.push(null)
            this.season_2.push(null)
            this.item_category.push(null)
            this.options_demand_type.push([...[
                { label: '日額', id: 1 },
                { label: '月額', id: 2 },
                { label: 'シーズン(日額)', id: 13 },
                { label: 'シーズン(月額)', id: 9 },
                { label: 'シーズン(一括)', id: 3 },
                { label: '一括', id: 4 },
                { label: '販売(消耗品)', id: 5 },
                { label: '販売(管理品)', id: 6 },
                { label: '付帯', id: 7 },
                { label: '滅失', id: 8 },
                { label: '日額(管理なし)', id: 10 },
                { label: '月額(管理なし)', id: 11 },
                { label: '一括(管理なし)', id: 12 },
            ]])
        },
        deleteRow(index) {
            if (this.params.length > 1) {
                this.delete_ids.push(this.params[index].id)
                this.params.splice(index, 1)
                this.params_flag.splice(index, 1)
                this.control_number.splice(index, 1)
                this.manage_type.splice(index, 1)
                this.demand_type.splice(index, 1)
                this.item_id.splice(index, 1)
                this.start_date.splice(index, 1)
                this.end_date.splice(index, 1)
                this.season_1.splice(index, 1)
                this.season_2.splice(index, 1)
                this.options_demand_type.splice(index, 1)
                this.item_category.splice(index, 1)
            }
        },
        addOption(index) {
            if (this.params[index].option === null) {
                this.params[index].option = []
            }
            this.params[index].option.push({
                option_id: null,
                price: null,
                count: null,
                name: null,
                flag: true,
                rental_flag: true,
                tax: 10,
            })
            this.$forceUpdate()
        },
        saveParams() {
            let params = {
                slip: [],
                ...this.updateParams,
            }
            this.params.filter((item, index) => {
                params.slip[index] = item
                params.slip[index].control_number = this.control_number[index]
                params.slip[index].manage_type = this.manage_type[index]
                params.slip[index].demand_type = this.demand_type[index]
                params.slip[index].item_id = this.item_id[index]
                params.slip[index].item_category = this.item_category[index]
                if (this.start_date[index] !== null) {
                    params.slip[index].start_date = moment(this.start_date[index]).format('YYYY-MM-DD')
                }
                else {
                    params.slip[index].start_date = null
                }
                if (this.end_date[index] !== null) {
                    params.slip[index].end_date = moment(this.end_date[index]).format('YYYY-MM-DD')
                }
                else {
                    params.slip[index].end_date = null
                }
                if (this.season_1[index] !== null && this.season_2[index] !== null) {
                    if (this.demand_type[index] === 13) {
                        let season_1 = this.season_1[index]
                        let season_2 = this.season_2[index]
                        params.slip[index].season_month = moment(season_1).format('YYYYMMDD') + "," + moment(season_2).format('YYYYMMDD')
                    }
                    else {
                        params.slip[index].season_month = this.season_1[index] + "," + this.season_2[index]
                    }
                }
            })
            this.$store.commit('slip/setUpdateParams', params)
        },
        restoreParams() {
            this.item_category = []
            this.manage_type = []
            this.control_number = []
            this.demand_type = []
            this.item_id = []
            this.start_date = []
            this.end_date = []
            this.season_1 = []
            this.season_2 = []

            let update = []
            this.params = this.updateParams.slip
            this.params.filter(item => {
                if (item.option !== null && item.option !== undefined) {
                    item.option.filter(option => {
                        option.flag = true
                    })
                }

                update.push(item.update_flag)
            })
            // update_flagがすべてtrueなら取消が可能
            if (update.indexOf(false) === -1) {
                this.is_cancel = true
            }

            this.params.filter((item, index) => {
                // 他社
                if (item.rental_stock_id !== undefined && item.rental_stock_id !== null) {
                    this.manage_type.push(2)
                }
                // 自社
                else if (item.stock_id !== undefined && item.stock_id !== null) {
                    this.manage_type.push(1)
                }
                // 自社
                else if (item.item_id !== undefined && item.item_id !== null) {
                    this.manage_type.push(1)
                }
                // 自社
                else if (item.in_house_stock_id !== undefined && item.in_house_stock_id !== null) {
                    this.manage_type.push(1)
                }
                if (item.control_number !== undefined && item.control_number.length === 10) {
                    this.item_category.push(item.control_number.split('-')[0])
                    this.control_number.push(item.control_number.split('-')[1])
                }
                else {
                    this.item_category.push(item.item_category)
                    this.control_number.push(item.control_number)
                }
                this.demand_type.push(item.demand_type)
                this.item_id.push(item.item_id)
                this.start_date.push(item.start_date)
                this.end_date.push(item.end_date)
                if (item.season_month !== undefined && item.season_month !== null) {
                    this.season_1.push(item.season_month.split(',')[0])
                    this.season_2.push(item.season_month.split(',')[1])
                }
                else {
                    this.season_1.push(null)
                    this.season_2.push(null)
                }
                this.statusUpdate(index, true)

                if (this.manage_type[index] === 1) {
                    this.options_demand_type.push([...[
                        { label: '日額', id: 1 },
                        { label: '月額', id: 2 },
                        { label: 'シーズン(日額)', id: 13 },
                        { label: 'シーズン(月額)', id: 9 },
                        { label: 'シーズン(一括)', id: 3 },
                        { label: '一括', id: 4 },
                        { label: '販売(消耗品)', id: 5 },
                        { label: '販売(管理品)', id: 6 },
                        { label: '付帯', id: 7 },
                        { label: '滅失', id: 8 },
                        { label: '日額(管理なし)', id: 10 },
                        { label: '月額(管理なし)', id: 11 },
                        { label: '一括(管理なし)', id: 12 },
                    ]])
                }
                else {
                    this.options_demand_type.push([...[
                        { label: '日額', id: 1 },
                        { label: '月額', id: 2 },
                        { label: 'シーズン(日額)', id: 13 },
                        { label: 'シーズン(月額)', id: 9 },
                        { label: 'シーズン(一括)', id: 3 },
                    ]])
                }

                // 初回の検索は自社のみ行う
                // 自社管理番号なしは検索しないように
                if (this.manage_type[index] === 1 && this.demand_type[index] !== 10 && this.demand_type[index] !== 11 && this.demand_type[index] !== 12 &&this.demand_type[index] !== 5 && this.demand_type[index] !== 7) {
                    this.searchItem(index)
                }
            })
        },
        getItemNameById(id) {
            let result = ""
			this.itemMasterList.item.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
        },
        cancel() {
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('slip/deleteAPi', {id: this.$route.query.id, type: 1}))
                .then(() => {
                    this.$store.commit('slip/setUpdateParams', null)
					this.$store.dispatch('loading/endLoad')
                    this.$router.push('slip')
				})
        },
        inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
        // 他社借り商品がプルダウンから選択されたなら最新備考を取得する
        getRentalStockNote(index) {
            if (this.manage_type[index] === 2) {
                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('stock/getRentalStockNoteApi', {name: this.item_id[index], customer_id: this.updateParams.customer_id}))
                    .then(() => {
                        this.params[index].note = this.rentalStockNote
                        this.$store.dispatch('loading/endLoad')
                    })
            }
        },
        toggleCheck(index, option_index, flag) {
            this.params[index].option[option_index].flag = flag
            this.$forceUpdate()
        },
        toScroll(index) {
            // 一番最後の行なら強制スクロール
            if (this.params.length - 3 <= index) {
                // 強制で一番下にスクロールさせているので要検討
                setTimeout(() => {
                    const obj = document.getElementsByClassName('contents-scroll-table')[0]
                    obj.scrollTop = obj.scrollHeight
                }, 10)
            }
        },
        // 自社製品まとめて追加モーダルを表示
        openAddModal() {
            this.add_params.manage_type = 1
            this.is_add_modal = true

            // スクロールイベント設定
            this.scroll_event = () => {
                $('#lines')[0].scrollTop = $('#control-number-area')[0].scrollTop
                $('#items')[0].scrollTop = $('#control-number-area')[0].scrollTop
            }
            document.getElementById('control-number-area').addEventListener('scroll', this.scroll_event)
        },
        // 自社製品まとめて追加モーダルを非表示
        closeAddModal() {
            this.is_add_modal = false
            this.add_params = {
                model_number: null,
                manage_type: 1,
                stock_id: null,
                item_id: null,
                demand_type: 1,
                price: null,
                count: null,
                start_date: null,
                end_date: null,
                name: null,
                season_month: null,
                season_1: null,
                season_2: null,
                note: null,
                option: null,
            }
        },
        // 自社をまとめて追加する
        addRowModal() {
            const length = this.params.length
            for (let i = 0; i < this.add_items.length; i++) {
                this.params.push({
                    manage_type: 1,
                    stock_id: null,
                    item_id: null,
                    demand_type: this.add_params.demand_type,
                    price: null,
                    count: null,
                    start_date: this.add_params.start_date,
                    end_date: this.add_params.end_date,
                    name: null,
                    season_month: this.add_params.season_month,
                    note: this.add_params.note,
                    option: null,
                    control_number: this.add_model_number_list[i].split('-')[1],
                    update_flag: true,
                },)
                this.params_flag.push({
                    control_number: true,
                    name: false,
                    name_pulldown: false,
                    name_readonly: false,
                    price: true,
                    count: false,
                    start_date: true,
                    end_date: false,
                    season_month: false,
                    note: false,
                    is_add: false,
                    item_category: false,
                },)
                this.item_category.push(this.add_item_category)
                this.control_number.push(this.add_model_number_list[i].split('-')[1])
                this.manage_type.push(1)
                this.demand_type.push(this.add_params.demand_type)
                this.item_id.push(null)
                this.start_date.push(this.add_params.start_date)
                this.end_date.push(this.add_params.end_date)
                this.season_1.push(this.add_params.season_1)
                this.season_2.push(this.add_params.season_2)
                this.options_demand_type.push([...[
                    { label: '日額', id: 1 },
                    { label: '月額', id: 2 },
                    { label: 'シーズン(日額)', id: 13 },
                    { label: 'シーズン(月額)', id: 9 },
                    { label: 'シーズン(一括)', id: 3 },
                    { label: '一括', id: 4 },
                    { label: '販売(消耗品)', id: 5 },
                    { label: '販売(管理品)', id: 6 },
                    { label: '付帯', id: 7 },
                    { label: '滅失', id: 8 },
                    { label: '日額(管理なし)', id: 10 },
                    { label: '月額(管理なし)', id: 11 },
                    { label: '一括(管理なし)', id: 12 },
                ]])
                this.statusUpdate(length + i, false)
            }
            this.is_add_modal = false
            this.is_add_modal_confirm = false
            this.add_params = {
                model_number: null,
                manage_type: 1,
                stock_id: null,
                item_id: null,
                demand_type: 1,
                price: null,
                count: null,
                start_date: null,
                end_date: null,
                name: null,
                season_month: null,
                season_1: null,
                season_2: null,
                note: null,
                option: null,
            }
        },
        // 他社借りまとめて追加モーダル表示
        openAddRentalModal() {
            this.add_params.manage_type = 2
            this.is_add_rental_modal = true
        },
        // 他社借りまとめて追加モーダルを非表示
        closeAddRentalModal() {
            this.is_add_rental_modal = false
            this.add_params = {
                model_number: null,
                manage_type: 1,
                stock_id: null,
                item_id: null,
                demand_type: 1,
                price: null,
                count: null,
                start_date: null,
                end_date: null,
                name: null,
                season_month: null,
                season_1: null,
                season_2: null,
                note: null,
                option: null,
            }
        },
        // 他社をまとめて追加する
        addRentalRowModal() {
            const length = this.params.length
            for (let i = 0; i < this.add_params.count; i++) {
                this.params.push({
                    manage_type: 2,
                    stock_id: null,
                    item_id: null,
                    demand_type: this.add_params.demand_type,
                    price: this.add_params.price,
                    count: null,
                    start_date: this.add_params.start_date,
                    end_date: this.add_params.end_date,
                    name: this.add_params.name,
                    season_month: this.add_params.season_month,
                    note: this.add_params.note,
                    option: null,
                    update_flag: true,
                },)
                this.params_flag.push({
                    control_number: true,
                    name: false,
                    name_pulldown: false,
                    name_readonly: false,
                    price: true,
                    count: false,
                    start_date: true,
                    end_date: false,
                    season_month: false,
                    note: false,
                    is_add: false,
                    item_category: false,
                },)
                this.control_number.push(null)
                this.manage_type.push(2)
                this.demand_type.push(this.add_params.demand_type)
                this.item_id.push(null)
                this.start_date.push(this.add_params.start_date)
                this.end_date.push(this.add_params.end_date)
                this.season_1.push(this.add_params.season_1)
                this.season_2.push(this.add_params.season_2)
                this.options_demand_type.push([...[
                    { label: '日額', id: 1 },
                    { label: '月額', id: 2 },
                    { label: 'シーズン(日額)', id: 13 },
                    { label: 'シーズン(月額)', id: 9 },
                    { label: 'シーズン(一括)', id: 3 },
                ]])
                this.statusUpdate(length + i, false, false, false)
            }
            this.is_add_rental_modal = false
            this.is_add_rental_modal_confirm = false
            this.add_params = {
                model_number: null,
                manage_type: 1,
                stock_id: null,
                item_id: null,
                demand_type: 1,
                price: null,
                count: null,
                start_date: null,
                end_date: null,
                name: null,
                season_month: null,
                season_1: null,
                season_2: null,
                note: null,
                option: null,
            }
        },
        // 検索用商品マスタ取得(販売商品検索)
		getBillingItemMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemMaster/getItemMasterApi', { billing_type: 1 }))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.itemMasterList.item.filter(item => {
						this.options_item.push({label: item.name, id: item.id})
					})
				})
		},
        // 商品マスタ取得
        getItemMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemMaster/getItemMasterApi'))
				.then(() => {
					this.itemMasterList.item.filter(item => {
						this.options_item_category.push({label: item.name, id: item.id + "-" + ("0000" + item.item_category_id).slice(-4)})
					})
                    this.getItemCategoryMasterList()
				})
		},
        // 商品カテゴリマスタ取得
        getItemCategoryMasterList() {
            this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi')
            .then(() => {
                this.itemCategoryMasterList.category.filter(item => {
                    this.options_item_category.push({label: "(種別: " + item.id + ") " + item.name, id: ("0000" + item.id).slice(-4)})
                })
                this.$store.dispatch('loading/endLoad')
            })
        },
        checkTextarea() {
            // 行数エリアの高さをtextareaに合わせる
            $('#lines').height(this.$refs.textarea.clientHeight)
            $('#items').height(this.$refs.textarea.clientHeight)

            // 行数表示を増やす
            this.lines_num += 1

            // 行数エリアを強制スクロール
            this.$nextTick(() => {
                $('#lines')[0].scrollTop = $('#lines')[0].scrollHeight
            })
        },
        searchAddItemList() {
            if (this.add_item_category !== null && this.add_item_category !== "" && this.add_textarea !== "") {
                let control_numbers = null
                //Chrome, Firefox 用
                if (this.add_textarea.match(/\n/g) !== null) {
                    control_numbers = this.add_textarea.split(/\n/g)
                }
                //IE 用
                else {
                    control_numbers = this.add_textarea.split(/\r\n/g)
                }

                // 先頭０埋めする
                let param = []
                control_numbers.filter(item => {
                    param.push(("0000" + this.add_item_category).slice(-4) + "-" + ("00000" + item).slice(-5))
                })
                this.search_add_params = param
                let error_texts = []
                this.add_modal_error_texts = []
                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('stock/getStockApi', {control_number: this.search_add_params}))
                    .then(() => {
                        this.add_items = this.stockList.list
                        this.add_items.filter((item, index) => {
                            if (item !== null) {
                                if (item.status === 1) {
                                    this.add_model_number_list.push(item.control_number)
                                }
                                else {
                                    error_texts.push((index + 1) + "行目：貸出中")
                                }
                            }
                            else {
                                if (this.search_add_params[index].split('-')[1].match(/[^0-9]/) === null) {
                                    error_texts.push((index + 1) + "行目：存在しない管理番号です")
                                }
                                else {
                                    error_texts.push((index + 1) + "行目：不正な文字列です")
                                }
                            }
                            this.add_modal_error_texts = error_texts
                        })
                    })
                    .then(() => this.$store.dispatch('loading/endLoad'))
            }
        },
        getCategoryName() {
            let result = ""
            this.itemMasterList.item.filter(item => {
                if (("0000" + item.item_category_id).slice(-4) === ("0000" + this.add_item_category).slice(-4)) {
                    result = item.name
                }
            })
            if (result === "") {
                this.itemCategoryMasterList.category.filter(item => {
                    if (("0000" + item.id).slice(-4) === ("0000" + this.add_item_category).slice(-4)) {
                        result = item.name
                    }
                })
            }
            return result
        },
        openAddConfirmModal() {
            // エラー行があるならポップアップを表示する
            if (this.add_modal_error_texts.length > 0) {
                this.is_add_modal_error = true
            }
            else {
                this.is_add_modal_confirm = true
            }
        },
        openRentalAddConfirmModal() {
            // 入力漏れがないかチェック
            let result = false
            // 他社 日額 ＊金額、商品名、開始日時
            if (this.add_params.manage_type === 2 && this.add_params.demand_type === 1) {
                if (this.add_params.price !== null && this.add_params.name !== null && this.add_params.start_date !== null &&
                    this.add_params.price !== "" && this.add_params.name !== "" && this.add_params.start_date !== "") {
                    result = true
                }
            }
            // 他社 月額 ＊金額、商品名、開始日時
            else if (this.add_params.manage_type === 2 && this.add_params.demand_type === 2) {
                if (this.add_params.price !== null && this.add_params.name !== null && this.add_params.start_date !== null &&
                    this.add_params.price !== "" && this.add_params.name !== "" && this.add_params.start_date !== "") {
                    result = true
                }
            }
            // 他社 シーズン ＊金額、商品名、開始日時、終了日時
            else if (this.add_params.manage_type === 2 && (this.add_params.demand_type === 3 || this.add_params.demand_type === 9 || this.add_params.demand_type === 13)) {
                if (this.add_params.price !== null && this.add_params.name !== null && this.add_params.start_date !== null && this.add_params.end_date !== null &&
                    this.add_params.price !== "" && this.add_params.name !== "" && this.add_params.start_date !== "" && this.add_params.end_date !== "") {
                    result = true
                }
            }
            // 他社 滅失 ＊金額、商品名プルダウン、開始日時
            else if (this.add_params.manage_type === 2 && this.add_params.demand_type === 8) {
                if (this.add_params.price !== null && this.add_params.item_id !== null && this.add_params.start_date !== null &&
                    this.add_params.price !== "" && this.add_params.item_id !== "" && this.add_params.start_date !== "") {
                    result = true
                }
            }
            // 追加確認モーダル表示
            if (result) {
                this.is_add_rental_modal_confirm = true
            }
            // エラー
            else {
                this.add_modal_error_texts = []
                this.add_modal_error_texts.push("入力に不備があります。")
                this.is_add_modal_error = true
            }
        },
        getCategoryById(id) {
            let result = ""
            this.itemCategoryMasterList.category.filter(item => {
                if (item.id === Number(id)) {
                    result = item.name
                }
            })
            return result
        },
	},
    computed: {
        language(){
            return ja;
        },
        ...mapState({
            updateParams: state => state.slip.updateParams,
            itemMasterApiStatus: state => state.itemMaster.apiStatus,
            itemMasterList: state => state.itemMaster.itemMasterList,
            customerMasterList: state => state.customerMaster.customerMasterList,
            stockList: state => state.stock.stockList,
            rentalStockNote: state => state.stock.rentalStockNote,
            itemCategoryMasterDetail: state => state.itemCategoryMaster.itemCategoryMasterDetail,
            itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
            itemPrice: state => state.itemMaster.itemPrice,
            slipDetailBaseData: state => state.slip.slipDetailBaseData,
        })
    },
    watch: {
        params: {
            handler() {
                this.saveParams()
            },
            deep: true,
        },
        control_number() {
            this.saveParams()
        },
        demand_type() {
            this.saveParams()
        },
        item_id() {
            this.saveParams()
        },
        start_date() {
            this.saveParams()
        },
        end_date() {
            this.saveParams()
        },
        season_1() {
            this.saveParams()
        },
        season_2() {
            this.saveParams()
        },
        add_textarea(value, old_value) {
            // textarea内の改行コード数を取得
            let num = value.match(/\n/g); //Chrome, Firefox 用
            let old_num = old_value.match(/\n/g);
            if (num === null) {
                num = value.match(/\r\n/g); //IE 用
                old_num = old_value.match(/\r\n/g)
            }

            // old_numのほうが大きければ行数が減っている
            if (old_num > num) {
                this.lines_num = old_num
            }
            if (num === null && old_num === null) {
                this.lines_num = 1
            }
        },
    }
}
</script>

<style scoped src="../assets/css/slip.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped>
.list-table-row td {
    text-overflow: initial;
    max-width: none;
}
.list-table-header th:first-child {
    padding-left: 20px;
}
.list-table-header th {
    padding: 0px 0px 0px 10px;
}
.contents-scroll-table td:first-child {
    padding-left: 20px;
}
.contents-scroll-table td {
    padding: 1vh 0px 1vh 10px;
}
</style>