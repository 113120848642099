<template>
    <div id="Sidebar">
        <div id="Logo">
            <div id="Logo_items">
                <div id="arrow" onclick="javascript:history.back()">
                    <span> ← </span>
                </div>
                <div id="text">
                    <span>リース管理システム</span>
                </div>
            </div>
        </div>
        <div id="Side_Menu" class="Side_Menu">
            <div id="menu-items">
                <template v-if="userData.auth === USER_TYPE.admin">
                    <div id="menu-content" :class="{'hover': $route.path === '/calendar'}" @click="transition('calendar')">
                        <svg class="icons" viewBox="0 0 16 16">
                            <path d="M 2 5 L 2 14 L 14 14 L 14 5 L 2 5 Z M 13 2 L 15 2 C 15.60000038146973 2 16 2.400000095367432 16 3 L 16 15 C 16 15.60000038146973 15.60000038146973 16 15 16 L 1 16 C 0.3999999761581421 16 0 15.60000038146973 0 15 L 0 3 C 0 2.400000095367432 0.4000000059604645 2 1 2 L 3 2 L 3 1 C 3 0.3999999761581421 3.400000095367432 0 4 0 C 4.599999904632568 0 5 0.4000000059604645 5 1 L 5 2 L 11 2 L 11 1 C 11 0.3999999761581421 11.39999961853027 0 12 0 C 12.60000038146973 0 13 0.4000000059604645 13 1 L 13 2 Z M 12 12 L 10 12 L 10 10 L 12 10 L 12 12 Z M 9 12 L 7 12 L 7 10 L 9 10 L 9 12 Z M 12 9 L 10 9 L 10 7 L 12 7 L 12 9 Z M 9 9 L 7 9 L 7 7 L 9 7 L 9 9 Z M 6 12 L 4 12 L 4 10 L 6 10 L 6 12 Z"></path>
                        </svg>
                        <div class="text">
                            <span>カレンダー</span>
                        </div>
                    </div>
                    <div id="menu-content" :class="{'hover': $route.path === '/slip'}" @click="transition('slip')">
                        <svg class="icons" viewBox="0 0 16 16">
                            <path d="M 8 0 C 3.599999904632568 0 0 3.599999904632568 0 8 C 0 12.39999961853027 3.599999904632568 16 8 16 C 12.39999961853027 16 16 12.39999961853027 16 8 C 16 3.599999904632568 12.39999961853027 0 8 0 Z M 8 10 C 6.900000095367432 10 6 9.100000381469727 6 8 C 6 6.900000095367432 6.900000095367432 6 8 6 C 9.100000381469727 6 10 6.900000095367432 10 8 C 10 9.100000381469727 9.100000381469727 10 8 10 Z M 8 2 C 8.899999618530273 2 9.800000190734863 2.200000047683716 10.60000038146973 2.599999904632568 L 9.043999671936035 4.156000137329102 C 8.282999992370605 3.949000120162964 7.716999530792236 3.949000120162964 6.954999923706055 4.156000137329102 L 5.400000095367432 2.599999904632568 C 6.199999809265137 2.200000047683716 7.099999904632568 2 8 2 Z M 2 8 C 2 7.099999904632568 2.200000047683716 6.199999809265137 2.599999904632568 5.400000095367432 L 4.156000137329102 6.956000328063965 C 3.949000120162964 7.717000484466553 3.949000120162964 8.282999992370605 4.156000137329102 9.045000076293945 L 2.599999904632568 10.60000038146973 C 2.200000047683716 9.800000190734863 2 8.899999618530273 2 8 Z M 8 14 C 7.099999904632568 14 6.199999809265137 13.80000019073486 5.400000095367432 13.39999961853027 L 6.956000328063965 11.8439998626709 C 7.717000484466553 12.05099964141846 8.282999992370605 12.05099964141846 9.045000076293945 11.8439998626709 L 10.60000038146973 13.39999961853027 C 9.800000190734863 13.80000019073486 8.899999618530273 14 8 14 Z M 13.39999961853027 10.60000038146973 L 11.8439998626709 9.044000625610352 C 12.05099964141846 8.283000946044922 12.05099964141846 7.717000484466553 11.8439998626709 6.955000877380371 L 13.39999961853027 5.400000095367432 C 13.80000019073486 6.199999809265137 14 7.099999904632568 14 8 C 14 8.899999618530273 13.80000019073486 9.800000190734863 13.39999961853027 10.60000038146973 Z"></path>
                        </svg>
                        <div class="text">
                            <span>伝票管理</span>
                        </div>
                    </div>
                    <div id="slip">
                        <div id="menu-content" :class="{'hover': ($route.path === '/slip_regist_step1' && $route.query.type === slip_type.out) || $route.path === '/slip_regist_step2_out' || ($route.path === '/slip_update_step1' && $route.query.type === slip_type.out) || $route.path === '/slip_update_step2_out'}" @click="transition('slip_regist_step1?type=1')">
                            <div class="text">
                                <span>出庫</span>
                            </div>
                        </div>
                        <div id="menu-content" :class="{'hover': ($route.path === '/slip_regist_step1' && $route.query.type === slip_type.in) || $route.path === '/slip_regist_step2_in' || ($route.path === '/slip_update_step1' && $route.query.type === slip_type.in) || $route.path === '/slip_update_step2_in'}" @click="transition('slip_regist_step1?type=2')">
                            <div class="text">
                                <span>入庫</span>
                            </div>
                        </div>
                        <div id="menu-content" :class="{'hover': ($route.path === '/slip_regist_step1' && $route.query.type === slip_type.special) || $route.path === '/slip_regist_step2_special' || ($route.path === '/slip_update_step1' && $route.query.type === slip_type.special) || $route.path === '/slip_update_step2_special'}" @click="transition('slip_regist_step1?type=3')">
                            <div class="text">
                                <span>特別</span>
                            </div>
                        </div>
                        <div id="menu-content" :class="{'hover': ($route.path === '/slip_regist_step1' && $route.query.type === slip_type.group) || $route.path === '/slip_regist_step2_group' || ($route.path === '/slip_update_step1' && $route.query.type === slip_type.group) || $route.path === '/slip_update_step2_group'}" @click="transition('slip_regist_step1?type=4')">
                            <div class="text">
                                <span>グループ間移動</span>
                            </div>
                        </div>
                    </div>
                </template>
                <div id="menu-content" :class="{'hover': $route.path === '/stock' || $route.path === '/stock_regist' || $route.path === '/stock_history' || $route.path === '/stock_history_detail' || $route.path === '/stock_update'}" @click="transition('stock')">
                    <svg class="icons" viewBox="-3 -219 15.999 15.999">
                        <path d="M 10.99980068206787 -203.0004119873047 L 10.99980068206787 -218.9997100830078 L 12.99960041046143 -218.9997100830078 L 12.99960041046143 -203.0004119873047 L 10.99980068206787 -203.0004119873047 Z M -2.99970006942749 -203.0004119873047 L -2.99970006942749 -218.9997100830078 L -0.9999000430107117 -218.9997100830078 L -0.9999000430107117 -203.0004119873047 L -2.99970006942749 -203.0004119873047 Z M 8.000100135803223 -207.9999084472656 L 8.000100135803223 -218.9997100830078 L 9.999899864196777 -218.9997100830078 L 9.999899864196777 -207.9999084472656 L 8.000100135803223 -207.9999084472656 Z M 2.99970006942749 -207.9999084472656 L 2.99970006942749 -218.9997100830078 L 7.000200271606445 -218.9997100830078 L 7.000200271606445 -207.9999084472656 L 2.99970006942749 -207.9999084472656 Z M 0 -207.9999084472656 L 0 -218.9997100830078 L 1.999800086021423 -218.9997100830078 L 1.999800086021423 -207.9999084472656 L 0 -207.9999084472656 Z"></path>
                    </svg>
                    <div class="text">
                        <span>在庫管理</span>
                    </div>
                </div>
                <div id="menu-content" :class="{'hover': $route.path === '/invoices' || $route.path === '/invoices_regist_step1' || $route.path === '/invoices_regist_step2' || $route.path === '/invoices_detail'}" @click="transition('invoices')">
                    <svg class="icons" viewBox="1 0 14 16">
                        <path d="M 14 0 L 2 0 C 1.399999976158142 0 1 0.4000000059604645 1 1 L 1 16 L 4 14 L 6 16 L 8 14 L 10 16 L 12 14 L 15 16 L 15 1 C 15 0.4000000059604645 14.60000038146973 0 14 0 Z M 12 10 L 4 10 L 4 8 L 12 8 L 12 10 Z M 12 6 L 4 6 L 4 4 L 12 4 L 12 6 Z"></path>
                    </svg>
                    <div class="text">
                        <span>請求管理</span>
                    </div>
                </div>
                <template v-if="userData.auth === USER_TYPE.admin">
                    <div id="menu-content" :class="{'hover': $route.path === '/sleep_regist_step1' || $route.path === '/sleep_regist_step2'}" @click="transition('sleep_regist_step1')">
                        <svg class="icons" viewBox="0 0 16 16">
                            <path d="M 13.30000019073486 5.199999809265137 L 14.40000057220459 3.099999904632568 L 13.00000095367432 1.699999928474426 L 10.90000152587891 2.799999952316284 C 10.60000133514404 2.599999904632568 10.20000171661377 2.5 9.80000114440918 2.399999856948853 L 9 0 L 7 0 L 6.199999809265137 2.299999952316284 C 5.900000095367432 2.400000095367432 5.5 2.5 5.199999809265137 2.700000047683716 L 3.099999904632568 1.600000023841858 L 1.600000023841858 3.099999904632568 L 2.700000047683716 5.199999809265137 C 2.5 5.5 2.400000095367432 5.900000095367432 2.299999952316284 6.199999809265137 L 0 7 L 0 9 L 2.299999952316284 9.800000190734863 C 2.399999856948853 10.19999980926514 2.599999904632568 10.5 2.700000047683716 10.90000057220459 L 1.600000023841858 13 L 3 14.39999961853027 L 5.099999904632568 13.29999923706055 C 5.400000095367432 13.49999904632568 5.799999713897705 13.59999942779541 6.199999809265137 13.69999885559082 L 7 16 L 9 16 L 9.800000190734863 13.69999980926514 C 10.19999980926514 13.59999942779541 10.5 13.39999961853027 10.90000057220459 13.30000019073486 L 13 14.40000057220459 L 14.39999961853027 13.00000095367432 L 13.29999923706055 10.90000152587891 C 13.49999904632568 10.60000133514404 13.59999942779541 10.20000171661377 13.69999885559082 9.80000114440918 L 16 9 L 16 7 L 13.69999980926514 6.199999809265137 C 13.60000038146973 5.900000095367432 13.5 5.5 13.30000019073486 5.199999809265137 Z M 8 11 C 6.300000190734863 11 5 9.699999809265137 5 8 C 5 6.300000190734863 6.300000190734863 5 8 5 C 9.699999809265137 5 11 6.300000190734863 11 8 C 11 9.699999809265137 9.699999809265137 11 8 11 Z"></path>
                        </svg>
                        <div class="text">
                            <span>休止管理</span>
                        </div>
                    </div>
                    <div id="menu-content" @click="toggleMasterSidebar()">
                        <svg class="icons" viewBox="0 0 16 16">
                            <path d="M 13.30000019073486 5.199999809265137 L 14.40000057220459 3.099999904632568 L 13.00000095367432 1.699999928474426 L 10.90000152587891 2.799999952316284 C 10.60000133514404 2.599999904632568 10.20000171661377 2.5 9.80000114440918 2.399999856948853 L 9 0 L 7 0 L 6.199999809265137 2.299999952316284 C 5.900000095367432 2.400000095367432 5.5 2.5 5.199999809265137 2.700000047683716 L 3.099999904632568 1.600000023841858 L 1.600000023841858 3.099999904632568 L 2.700000047683716 5.199999809265137 C 2.5 5.5 2.400000095367432 5.900000095367432 2.299999952316284 6.199999809265137 L 0 7 L 0 9 L 2.299999952316284 9.800000190734863 C 2.399999856948853 10.19999980926514 2.599999904632568 10.5 2.700000047683716 10.90000057220459 L 1.600000023841858 13 L 3 14.39999961853027 L 5.099999904632568 13.29999923706055 C 5.400000095367432 13.49999904632568 5.799999713897705 13.59999942779541 6.199999809265137 13.69999885559082 L 7 16 L 9 16 L 9.800000190734863 13.69999980926514 C 10.19999980926514 13.59999942779541 10.5 13.39999961853027 10.90000057220459 13.30000019073486 L 13 14.40000057220459 L 14.39999961853027 13.00000095367432 L 13.29999923706055 10.90000152587891 C 13.49999904632568 10.60000133514404 13.59999942779541 10.20000171661377 13.69999885559082 9.80000114440918 L 16 9 L 16 7 L 13.69999980926514 6.199999809265137 C 13.60000038146973 5.900000095367432 13.5 5.5 13.30000019073486 5.199999809265137 Z M 8 11 C 6.300000190734863 11 5 9.699999809265137 5 8 C 5 6.300000190734863 6.300000190734863 5 8 5 C 9.699999809265137 5 11 6.300000190734863 11 8 C 11 9.699999809265137 9.699999809265137 11 8 11 Z"></path>
                        </svg>
                        <div class="text">
                            <span>マスター情報管理</span>
                        </div>
                    </div>
                    <div id="master">
                        <div id="menu-content" :class="{'hover': $route.path === '/item_master' || $route.path === '/item_master_regist' || $route.path === '/item_master_update'}" @click="transition('item_master')">
                            <div class="text">
                                <span>商品マスタ</span>
                            </div>
                        </div>
                        <div id="menu-content" :class="{'hover': $route.path === '/item_category_master' || $route.path === '/item_category_master_regist' || $route.path === '/item_category_master_update'}" @click="transition('item_category_master')">
                            <div class="text">
                                <span>商品カテゴリマスタ</span>
                            </div>
                        </div>
                        <div id="menu-content" :class="{'hover': $route.path === '/customer_master' || $route.path === '/customer_master_regist' || $route.path === '/customer_master_update'}" @click="transition('customer_master')">
                            <div class="text">
                                <span>取引先マスタ</span>
                            </div>
                        </div>
                        <div id="menu-content" :class="{'hover': $route.path === '/location_master' || $route.path === '/location_master_regist' || $route.path === '/location_master_update'}" @click="transition('location_master')">
                            <div class="text">
                                <span>設置現場マスタ</span>
                            </div>
                        </div>
                    </div>
                </template>
                <div id="menu-content" @click="logout()">
                    <div class="text">
                        <span>ログアウト</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="user">
            <div style="padding-top: 10px; margin-left: 20px">
                <img class="user-icon" src="../assets/img/user.svg">
                <div class="text" style="vertical-align: middle;">
                    {{ userData.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import { SLIP_TYPE, USER_TYPE } from '../util'
import { mapState } from 'vuex'
export default {
	data() {
		return {
            USER_TYPE: USER_TYPE,
            slip_type: SLIP_TYPE,
            is_open: false,
		}
	},
	created() {

	},
	mounted() {

	},
	methods: {
        toggleMasterSidebar() {
            this.is_open = !this.is_open
            $('#master').css('display', this.is_open ? 'block' : 'none')
        },
        transition(path) {
            // 伝票更新用
            this.$store.commit('slip/setBaseData', null)
            this.current = path
            this.$router.push(path)
        },
        logout() {
            this.$store.dispatch('login/logoutApi')
                .then(() => {
                    localStorage.removeItem('userData')
                    this.$router.push('/')
                })
        }
	},
    computed: {
		...mapState({
			userData: state => state.login.userData,
		})
	}
}
</script>

<style scoped src="../assets/css/sidebar.css"></style>