<template>
	<div>
		<div id="main-text">
			<span>在庫一覧</span>
		</div>
		<template v-if="userData.auth === USER_TYPE.admin">
			<button class="export" @click="getStockCsv()">
				エクスポート
			</button>
		</template>
		<div id="main-contents">
			<div id="list-contents">
				<table class="list-table">
					<tr class="list-table-header">
						<th>管理番号</th>
						<th>貸出先</th>
						<th>貸出先現場</th>
						<th colspan="2">商品</th>
						<th>製品カテゴリ</th>
						<th>事業所名</th>
						<th>ステータス</th>
					</tr>
				</table>
				<div class="list-table-scroller">
					<table class="list-table-contents">
						<template v-if="stockList !== null">
							<template v-for="(item, index) in stockList.list">
								<tr class="list-table-row" :key="'item_master_' + index" @click="toUpdate(item.id)">
									<td>
										<span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
									</td>
									<td>
										<span v-if="item.rental_customer_id === null || item.rental_customer_id === ''" v-tooltip="'-'">-</span>
										<template v-else>
											<span v-tooltip="item.rental_customer_id">{{ item.rental_customer_id }}</span>
										</template>
									</td>
									<td>
										<span v-if="item.rental_location_id === null || item.rental_location_id === ''" v-tooltip="'-'">-</span>
										<template v-else>
											<span v-if="locationMasterApiStatus" v-tooltip="getLocationNameById(item.rental_location_id)">{{ getLocationNameById(item.rental_location_id) }}</span>
										</template>
									</td>
									<td colspan="2">
										<span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.name">{{ item.name }}</span>
									</td>
									<td>
										<span v-if="item.item_category_id === null || item.item_category_id === ''" v-tooltip="'-'">-</span>
										<template v-else>
											<span v-if="itemCategoryMasterApiStatus" v-tooltip="getItemCategoryNameById(item.item_category_id)">{{ getItemCategoryNameById(item.item_category_id) }}</span>
										</template>
									</td>
									<td>
										<span v-if="item.office === null || item.office === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.office">{{ item.office }}</span>
									</td>
									<td>
										<span v-if="item.status === null || item.status === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="getStockStatus(item.status)">{{ getStockStatus(item.status) }}</span>
									</td>
								</tr>
							</template>
						</template>
					</table>
				</div>
				<div class="pager" v-if="page_num !== 0">
					<template v-if="current_page !== 1">
						<span class="page" @click="pager(current_page - 1)">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-if="page_num < 5">
						<template v-for="page in page_num">
							<span :class="{'page': true, 'current': current_page === page}" :key="'page_' + page" @click="pager(page)">
								<span>{{ page }}</span>
							</span>
						</template>
					</template>
					<template v-else>
						<template v-if="current_page < 4">
							<span :class="{'page': true, 'current': current_page === 1}" @click="pager(1)">
								<span>{{ 1 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 2}" @click="pager(2)">
								<span>{{ 2 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 3}" @click="pager(3)">
								<span>{{ 3 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 4}" @click="pager(4)">
								<span>{{ 4 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 5}" @click="pager(5)">
								<span>{{ 5 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 2) <= page_num">
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
							<span class="page" @click="pager(current_page + 2)">
								<span>{{ current_page + 2 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 1) <= page_num">
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
						</template>
						<template v-else-if="current_page === page_num">
							<span class="page" @click="pager(current_page - 4)">
								<span>{{ current_page - 4 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
						</template>
					</template>
					<template v-if="current_page !== page_num">
						<span class="page" @click="pager(current_page + 1)">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
				</div>
			</div>
			<div class="contents-filter">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							管理番号
						</div>
						<div class="input">
							<input type="tel" placeholder="管理番号" maxlength="64" v-model="filter_params.control_number">
						</div>
					</div>
					<div class="filter">
						<div class="title">
							貸出先
						</div>
						<div class="input">
							<v-select :options="options_customer" :reduce="options_customer => options_customer.id" v-model="filter_params.rental_customer_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							製品カテゴリ
						</div>
						<div class="input">
							<v-select :options="options_category" :reduce="options_category => options_category.id" v-model="filter_params.item_category_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							商品
						</div>
						<div class="input">
							<v-select :options="options_item" :reduce="options_item => options_item.id" v-model="filter_params.name">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter" v-if="is_office">
						<div class="title">
							事業所
						</div>
						<div class="input">
							<v-select :options="options_office" v-model="filter_params.office">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							貸出状況
						</div>
						<div>
							<div class="radio">
								<label class="radio-label" for="radio0">
									<input type="radio" name="rental" id="radio0" :value="null" v-model="filter_params.status">指定無
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio1">
									<input type="radio" name="rental" id="radio1" :value="2" v-model="filter_params.status">貸出中
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio2">
									<input type="radio" name="rental" id="radio2" :value="1" v-model="filter_params.status">未貸出
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio3">
									<input type="radio" name="rental" id="radio3" :value="3" v-model="filter_params.status">修理中
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio4">
									<input type="radio" name="rental" id="radio4" :value="4" v-model="filter_params.status">休止中
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio5">
									<input type="radio" name="rental" id="radio5" :value="5" v-model="filter_params.status">販売済み
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-if="userData.auth === USER_TYPE.admin">
			<div class="buttons">
				<router-link to="stock_history">
					<button class="button-prev">登録履歴</button>
				</router-link>
				<router-link to="stock_regist">
					<button class="button-next">新規登録</button>
				</router-link>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { inputNumberMaxLength, STOCK_STATUS_LABEL, USER_TYPE } from '../util';
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			USER_TYPE: USER_TYPE,
			filter_params: {
				control_number: null,
				rental_customer_id: null,
				item_category_id: null,
				name: null,
				office: null,
				status: null,
			},
			options_category: [],
			options_customer: [],
			options_office: [],
			options_item: [],
			page_num: 0,
			current_page: 1,
			page_start: 1,
			page_end: 1,
			flags: {
				category: false,
				customer: false,
				office: false,
			},
			is_office: true,
		}
	},
	created() {
		// 関連ページからの復帰で検索条件を保持
		if (this.stockFilterParams !== null) {
			this.filter_params = this.stockFilterParams
		}
		// リロード時にページを保持するように
		let params = this.filter_params
		params.page = 1
		if (this.$route.query.page !== undefined) {
			this.current_page = Number(this.$route.query.page)
			params.page = this.current_page
		}
		this.getItemCategoryMasterList(params)
		this.getCustomerMasterList(params)
		this.getOfficeList(params)
		this.getItemMasterList()
		this.getLocationList()
	},
	mounted() {

	},
	methods: {
		// 在庫一覧取得
		getStockList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('stock/getStockApi', params))
                .then(() => {
					if (this.stockList.page !== undefined) {
						this.page_num = this.stockList.page

						// 最大ページ数が最大表示数の5を上回っていた場合
						if (this.page_num > 5) {
							this.page_start = this.current_page - 2 < 0 ? 1 : this.current_page - 2
							this.page_end = this.page_end > this.current_page + 2 ? this.page_num : this.page_end > this.current_page + 2
						}
						else {
							this.page_start = 1
							this.page_end = this.page_num
						}
					}
					this.$nextTick(() => this.$store.dispatch('loading/endLoad'))
				})
		},
		// 検索用商品カテゴリ取得
		getItemCategoryMasterList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi', { name: null }))
                .then(() => {
					this.itemCategoryMasterList.category.filter(item => {
						if (item.name !== "") {
							this.options_category.push({label: "(種別: " + item.id + ") " + item.name, id: item.id})
						}
					})
					this.flags.category = true
					if (this.flags.category && this.flags.customer && this.flags.office) {
						if (params !== null) {
							this.getStockList(params)
						}
						else {
							this.getStockList(this.filter_params)
						}
					}
				})
		},
		// 検索用取引先マスタ取得
		getCustomerMasterList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('customerMaster/getCustomerMasterApi', { name: null }))
                .then(() => {
					this.customerMasterList.list.filter(item => {
						if (item.floor !== "") {
							this.options_customer.push({label: item.floor, id: item.id})
						}
					})
					this.flags.customer = true
					if (this.flags.category && this.flags.customer && this.flags.office) {
						if (params !== null) {
							this.getStockList(params)
						}
						else {
							this.getStockList(this.filter_params)
						}
					}
				})
		},
		// 検索用事業所一覧取得
		getOfficeList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
                .then(() => {
					// ユーザ情報のoffice_idがあるなら事業所を固定する
					if (this.userData.office_id !== null) {
						this.officeMasterList.office.map(item => {
							if (this.userData.office_id == item.id) {
								this.filter_params.office = item.name
								this.is_office = false
							}
						})
					}
					this.officeMasterList.office.filter(item => {
						if (item.name !== "") {
							this.options_office.push(item.name)
						}
					})
					this.flags.office = true
					if (this.flags.category && this.flags.customer && this.flags.office) {
						if (params !== null) {
							this.getStockList(params)
						}
						else {
							this.getStockList(this.filter_params)
						}
					}
				})
		},
		// CSVダウンロード
		getStockCsv() {
			const params = {
				control_number: this.filter_params.control_number,
				rental_customer_id: this.filter_params.rental_customer_id,
				item_category_id: this.filter_params.item_category_id,
				name: this.filter_params.name,
				office: this.filter_params.office,
				status: this.filter_params.status,
			}
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('stock/getStockCsvApi', params))
                .then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 更新画面へ
		toUpdate(id) {
			this.$router.push('stock_update?page=' + this.current_page + '&id=' + id)
		},
		// ページ切り替え
		pager(page) {
			this.$router.push('stock?page=' + page)
			this.current_page = page
			let params = this.filter_params
			params.page = this.current_page
			this.getStockList(params)
		},
		// カテゴリIDからカテゴリ名を取得する
		getItemCategoryNameById(id) {
			let result = ""
			this.itemCategoryMasterList.category.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		// 取引先IDから取引先名を取得する
		getCustomerNameById(id) {
			let result = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
		// 出庫ステータスを返す
		getStockStatus(status) {
			return STOCK_STATUS_LABEL[status]
		},
		inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
		getLocationList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('locationMaster/getLocationMasterApi', {}))
                .then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 設置現場IDから設置現場名を取得する
		getLocationNameById(id) {
			let result = "-"
			this.locationMasterList.location.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		// 検索用商品マスタ取得
		getItemMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemMaster/getItemMasterApi'))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.itemMasterList.item.filter(item => {
						this.options_item.push({label: item.name, id: item.id})
					})
				})
		},
	},
	computed: {
		...mapState({
			stockList: state => state.stock.stockList,
			stockFilterParams: state => state.stock.stockFilterParams,
			itemMasterList: state => state.itemMaster.itemMasterList,
			itemCategoryMasterApiStatus: state => state.itemCategoryMaster.apiStatus,
			itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
			customerMasterList: state => state.customerMaster.customerMasterList,
			officeMasterList: state => state.officeMaster.officeMasterList,
			locationMasterApiStatus: state => state.locationMaster.apiStatus,
			locationMasterList: state => state.locationMaster.locationMasterList,
			userData: state => state.login.userData,
		})
	},
	watch: {
		// 検索結果取得
		filter_params: {
			handler(value) {
				let params = value
				params.page = 1
				this.current_page = 1
				this.page_num = 0
				this.getStockList(params)
				this.$store.commit('stock/setStockFilterParams', value)
			},
			deep : true,
		}
	}
}
</script>

<style scoped src="../assets/css/stock.css"></style>
<style scoped src="../assets/css/list_table.css"></style>