<template>
	<div>
		<div id="main-text">
			<span v-if="step1_params.type === 1">休止</span>
            <span v-else>休止解除</span>
		</div>
		<div id="main-contents">
            <div class="step-contents" style="margin-bottom: 20px">
				<div class="step">
					<div class="step-circle" onclick="javascript:history.back()">
						<span><img src="../assets/img/check.png"></span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" onclick="javascript:history.back()">
						Step 1
					</div>
					<div class="step-word" style="font-weight: bold;">
						Step 2
					</div>
				</div>
			</div>
			<div id="list-contents" style="height: calc(100% - 100px); padding-top: 0px">
                <div class="step2-title">
                    {{ customer_name }}
                </div>
                <div style="overflow: auto; height: calc(100% - 60px);">
                    <table class="list-table" style="table-layout: initial;">
                        <thead style="position: sticky; top: 0; z-index: 2;">
                            <tr class="list-table-header">
                                <th>
                                    <label class="check" for="all_check">
                                        <input type="checkbox" id="all_check" style="display: none;" v-model="all_check">
                                        <span class="checkbox" style="background-color: white;"><img v-if="all_check" src="../assets/img/checkbox.png"></span>
                                    </label>
                                </th>
                                <th>伝票番号</th>
                                <th>種別</th>
                                <th>管理番号</th>
                                <th>数量</th>
                                <th>商品</th>
                                <th>開始日</th>
                                <th>終了日</th>
                                <th colspan="2">備考</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="sleep_list.length !== 0">
                                <!-- チェックのみ表示 -->
                                <template v-if="filter_check && !is_search">
                                    <template v-for="(item, index) in sleep_list">
                                        <tr class="list-table-row" :key="'item_master_0' + index" v-if="item.is_check">
                                            <td>
                                                <label class="check" :for="'slip_check0' + index" @click="toggleCheck(index, item.is_check ? false : true)">
                                                    <input type="checkbox" :id="'slip_check0' + index" style="display: none;" :checked="item.is_check">
                                                    <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                </label>
                                            </td>
                                            <!-- 伝票番号 -->
                                            <td>
                                                <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                            </td>
                                            <!-- 種別 -->
                                            <td>
                                                <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                            </td>
                                            <!-- 管理番号 -->
                                            <td>
                                                <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                            </td>
                                            <!-- 数量 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" v-model="item.count">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                </template>
                                            </td>
                                            <!-- 商品 -->
                                            <td>
                                                <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                            </td>
                                            <!-- 対象日 -->
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.end_date"></datepicker>
                                                </div>
                                            </td>
                                            <!-- 備考 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="text" v-model="item.note">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <!-- 検索結果表示 -->
                                <template v-else-if="!filter_check && is_search">
                                    <template v-for="(item, index) in sleep_list">
                                        <tr class="list-table-row" :key="'item_master_1' + index" v-if="item.is_search">
                                            <td>
                                                <label class="check" :for="'slip_check1' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                    <input type="checkbox" :id="'slip_check1' + index" style="display: none;" :checked="item.is_check">
                                                    <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                </label>
                                            </td>
                                            <!-- 伝票番号 -->
                                            <td>
                                                <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                            </td>
                                            <!-- 種別 -->
                                            <td>
                                                <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                            </td>
                                            <!-- 管理番号 -->
                                            <td>
                                                <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                            </td>
                                            <!-- 数量 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" v-model="item.count">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                </template>
                                            </td>
                                            <!-- 商品 -->
                                            <td>
                                                <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                            </td>
                                            <!-- 対象日 -->
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.end_date"></datepicker>
                                                </div>
                                            </td>
                                            <!-- 備考 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="text" v-model="item.note">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <!-- チェック済み検索結果表示 -->
                                <template v-else-if="filter_check && is_search">
                                    <template v-for="(item, index) in sleep_list">
                                        <tr class="list-table-row" :key="'item_master_2' + index" v-if="item.is_check && item.is_search">
                                            <td>
                                                <label class="check" :for="'slip_check2' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                    <input type="checkbox" :id="'slip_check2' + index" style="display: none;" :checked="item.is_check">
                                                    <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                </label>
                                            </td>
                                            <!-- 伝票番号 -->
                                            <td>
                                                <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                            </td>
                                            <!-- 種別 -->
                                            <td>
                                                <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                            </td>
                                            <!-- 管理番号 -->
                                            <td>
                                                <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                            </td>
                                            <!-- 数量 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" v-model="item.count">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                </template>
                                            </td>
                                            <!-- 商品 -->
                                            <td>
                                                <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                            </td>
                                            <!-- 対象日 -->
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.end_date"></datepicker>
                                                </div>
                                            </td>
                                            <!-- 備考 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="text" v-model="item.note">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-for="(item, index) in sleep_list">
                                        <tr class="list-table-row" :key="'item_master_3' + index">
                                            <td>
                                                <label class="check" :for="'slip_check3' + index" @change="toggleCheck(index, item.is_check ? false : true)">
                                                    <input type="checkbox" :id="'slip_check3' + index" style="display: none;" :checked="item.is_check">
                                                    <span class="checkbox" style="background-color: white;"><img v-if="item.is_check" src="../assets/img/checkbox.png"></span>
                                                </label>
                                            </td>
                                            <!-- 伝票番号 -->
                                            <td>
                                                <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                            </td>
                                            <!-- 種別 -->
                                            <td>
                                                <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                            </td>
                                            <!-- 管理番号 -->
                                            <td>
                                                <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                            </td>
                                            <!-- 数量 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="number" v-model="item.count">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                </template>
                                            </td>
                                            <!-- 商品 -->
                                            <td>
                                                <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                            </td>
                                            <!-- 対象日 -->
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.start_date"></datepicker>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="register-datepicker" style="vertical-align: middle; margin-top: 0px;">
                                                    <datepicker :language="language" format="yyyy/MM/dd" v-model="item.end_date"></datepicker>
                                                </div>
                                            </td>
                                            <!-- 備考 -->
                                            <td>
                                                <template v-if="item.rental_stock_id !== null">
                                                    <div class="input" style="margin-top: 0px">
                                                        <input type="text" v-model="item.note">
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                </template>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                </div>
			</div>
			<div class="contents-filter" style="height: calc(100% - 100px);">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							伝票番号
						</div>
						<div class="input">
							<input type="text" placeholder="商品名" v-model="filter_params.slip_id" @blur="searchSleep()">
						</div>
					</div>
					<div class="filter">
						<div class="title">
							管理番号
						</div>
						<div class="input">
							<input type="tel" placeholder="型番" v-model="filter_params.control_number" @blur="searchSleep()">
						</div>
					</div>
                    <div class="filter" style="text-align: right">
                        <label class="check" for="filter_check">
                            <div class="filter-check">
                                選択中のみ表示
                            </div>
                            <input type="checkbox" id="filter_check" style="display: none;" v-model="filter_check">
                            <span class="checkbox" style="background-color: white;"><img v-if="filter_check" style="margin-left: 0px" src="../assets/img/checkbox.png"></span>
                        </label>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-prev" onclick="javascript:history.back()">戻る</button>
            <button class="button-next" @click="preview()">確認</button>
		</div>
        <div id="preview-modal" v-show="is_preview" @click.self="is_preview = false">
            <div id="preview-modal-background">
                <div id="preview-modal-header">
                    <span class="title">確認</span>
                    <img src="../assets/img/close.png" @click="is_preview = false">
                </div>
                <div id="preview-modal-content">
                    <div id="preview-modal-main-contents">
                        <div id="list-contents" style="width: 100%; height: calc(100% - 100px); padding-top: 0px">
                            <div class="step2-title">
                                {{ customer_name }}
                            </div>
                            <table class="list-table">
                                <tr class="list-table-header">
                                    <th>伝票番号</th>
                                    <th>種別</th>
                                    <th>管理番号</th>
                                    <th>数量</th>
                                    <th>商品</th>
                                    <th>開始日</th>
                                    <th>終了日</th>
                                    <th>備考</th>
                                </tr>
                            </table>
                            <div class="list-table-scroller">
                                <table class="list-table-contents">
                                    <template v-if="regist_sleep_list !== null">
                                        <template v-for="(item, index) in regist_sleep_list">
                                            <tr class="list-table-row" :key="'item_master_' + index">
                                                <!-- 伝票番号 -->
                                                <td>
                                                    <span v-if="item.slip_id === null || item.slip_id === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.slip_id">{{ item.slip_id }}</span>
                                                </td>
                                                <!-- 種別 -->
                                                <td>
                                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                                </td>
                                                <!-- 管理番号 -->
                                                <td>
                                                    <span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
                                                </td>
                                                <!-- 数量 -->
                                                <td>
                                                    <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                </td>
                                                <!-- 商品 -->
                                                <td>
                                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                                </td>
                                                <!-- 対象日 -->
                                                <td>
                                                    <span v-if="item.start_date === null || item.start_date === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="dateFormat(item.start_date)">{{ dateFormat(item.start_date) }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="dateFormat(item.end_date)">{{ dateFormat(item.end_date) }}</span>
                                                </td>
                                                <!-- 備考 -->
                                                <td>
                                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="preview-modal-button">
                <button id="no" @click="is_preview = false">戻る</button>
                <button id="yes" @click="register()">登録</button>
            </div>
        </div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        入力に不備があります。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import moment from 'moment';
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex'
import { SLIP_DETAIL_LABEL } from '../util';
export default {
    components: {
        Datepicker,
    },
	data() {
		return {
            slip_detail_label: SLIP_DETAIL_LABEL,
            sleep_list: [],
            regist_sleep_list: [],
            filter_params: {
                location_id: null,
                customer_id: null,
                slip_id: null,
                control_number: null,
                type: null,
            },
            all_check: false,
            filter_check: false,
            is_preview: false,
            is_search: false,
            is_modal: false,
            customer_name: "",
            step1_params: null,
		}
	},
	created() {
        this.step1_params = this.registerParams
        // 取引先名を設定
        this.customerMasterList.list.filter(item => {
            if (item.id === this.step1_params.customer_id) {
                this.customer_name = item.floor
            }
        })
        this.filter_params.location_id = this.step1_params.location_id
        this.filter_params.customer_id = this.step1_params.customer_id
        this.filter_params.type = this.step1_params.type
        this.getSleepList()
	},
	mounted() {

	},
	methods: {
        getSleepList() {
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('sleep/getSlipApi', this.filter_params))
                .then(() => {
                    this.sleepList.filter(item => {
                        let data = item
                        data.is_check = false
                        // STEP１指定日を開始日のデフォルトにする
                        data.start_date = this.registerParams.date
                        data.end_date = null

                        // rental_stock_idがあったら他社商品なので備考を取得する
                        if (item.rental_stock_id !== null) {
                            this.$store.dispatch('stock/getRentalStockNoteApi', {name: item.name, customer_id: this.registerParams.customer_id})
                                .then(() => {
                                    data.note = this.rentalStockNote
                                })
                        }
                        this.sleep_list.push(data)
                    })
                    this.saveParams()
					this.$store.dispatch('loading/endLoad')
				})
        },
        searchSleep() {
            this.is_search = true
            this.sleep_list.filter(item => {
                item.is_search = false
            })
            if (this.filter_params.slip_id !== null && this.filter_params.slip_id !== "") {
                // slip_id, control_number検索
                if (this.filter_params.control_number !== null && this.filter_params.control_number !== "") {
                    this.sleep_list.filter(item => {
                        if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                            item.is_search = true
                        }
                    })
                }
                // slip_id検索
                else {
                    this.sleep_list.filter(item => {
                        if (item.slip_id !== null && item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1) {
                            item.is_search = true
                        }
                    })
                }
            }
            else if (this.filter_params.control_number !== null && this.filter_params.control_number !== "") {
                if (this.filter_params.slip_id !== null && this.filter_params.slip_id !== "") {
                    // control_number, slip_id検索
                    this.sleep_list.filter(item => {
                        if (item.slip_id.toString().indexOf(this.filter_params.slip_id) > -1 && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                            item.is_search = true
                        }
                    })
                }
                // control_number検索
                else {
                    this.sleep_list.filter(item => {
                        if (item.control_number !== null && item.control_number.toString().indexOf(this.filter_params.control_number) > -1) {
                            item.is_search = true
                        }
                    })
                }
            }
            else {
                this.is_search = false
            }
        },
        preview() {
            let error = []
            this.regist_sleep_list = []
            this.sleep_list.filter(item => {
                if (item.start_date !== null && item.start_date !== '' || (item.rental_stock_id !== null && item.count !== null && item.count !== '')) {
                    if (item.is_check) {
                        this.regist_sleep_list.push(item)
                    }
                }
                else {
                    error.push(true)
                }
            })
            if (error.length === 0) {
                this.is_preview = true
            }
            else {
                this.is_modal = true
            }
        },
        register() {
            let params = {
                list: [],
                ...this.step1_params,
            }
            this.regist_sleep_list.filter((item, index) => {
                params.list.push({
                    stock_id: item.stock_id,
                    name: item.name,
                    rental_stock_id: item.rental_stock_id,
                    count: item.count,
                    note: item.note,
                    date: moment(item.start_date).format('YYYY-MM-DD'),
                    end_date: item.end_date !== null ? moment(item.end_date).format('YYYY-MM-DD') : null,
                })
                if (item.in_house_stock_id !== undefined && item.in_house_stock_id !== null) {
                    params.list[index].in_house_stock_id = item.in_house_stock_id
                }
            })
            this.$store.commit('loading/setIsFull', true)
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('sleep/postSlipApi', params))
                .then(() => {
                    this.$store.commit('sleep/setRegisterParams', null)
					this.$store.dispatch('loading/endLoad')
                    this.$store.commit('loading/setIsFull', false)
                    this.$router.push('sleep_regist_step1')
				})
        },
        saveParams() {
            this.$store.commit('sleep/setSleepList', this.sleep_list)
        },
        toggleCheck(index, value) {
            this.sleep_list[index].is_check = value
            this.$forceUpdate()
        },
        dateFormat(date) {
            return moment(date).format('YYYY-MM-DD')
        },
	},
	computed: {
        language(){
            return ja;
        },
        ...mapState({
            sleepList: state => state.sleep.sleepList,
            registerParams: state => state.sleep.registerParams,
            customerMasterList: state => state.customerMaster.customerMasterList,
            stockList: state => state.stock.stockList,
            rentalStockNote: state => state.stock.rentalStockNote,
        })
	},
    watch: {
        sleep_list: {
            handler() {
                this.saveParams()
            },
            deep: true,
        },
        all_check(value) {
            if (this.filter_check && !this.is_search) {
                this.sleep_list.filter(item => {
                    if (!item.is_search && item.is_check) {
                        item.is_check = value
                    }
                })
            }
            else if (!this.filter_check && this.is_search) {
                this.sleep_list.filter(item => {
                    if (item.is_search) {
                        item.is_check = value
                    }
                })
            }
            else if (this.filter_check && this.is_search) {
                this.sleep_list.filter(item => {
                    if (item.is_search && item.is_check) {
                        item.is_check = value
                    }
                })
            }
            else {
                this.sleep_list.filter(item => {
                    item.is_check = value
                })
            }
        },
    }
}
</script>

<style scoped src="../assets/css/sleep.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped src="../assets/css/preview.css"></style>
<style scoped>
.list-table-row {
    cursor: default;
}
.filter-check {
    display: inline-block;
    white-space: nowrap;
	line-height: 20px;
	text-align: center;
	font-family: Source Sans Pro;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: #808495;
    margin-right: 12px;
}
.list-table-row td {
    padding: 2vh 20px;
}
.list-table-row td input[type="number"] {
    width: 50px;
}
.list-table-row td input[type="text"] {
    width: 200px;
}
.register-datepicker {
    width: 100px;
}
</style>