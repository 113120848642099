<template>
	<div>
		<div id="main-text">
			<span>伝票登録（{{SLIP_TYPE_LABEL[$route.query.type]}}）</span>
		</div>
		<div id="main-contents" style="height: auto">
			<div class="step-contents">
				<div class="step">
					<div class="step-circle">
						<span>1</span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle" @click="toNext()">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" style="font-weight: bold;">
						Step 1
					</div>
					<div class="step-word" @click="toNext()">
						Step 2
					</div>
				</div>
			</div>
			<div class="register-contents" style="overflow: auto">
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							登録日
						</div>
						<div class="register-datepicker" style="vertical-align: middle; width: calc(100% - 70px);" ref="date1_1">
							<datepicker ref="datepicker" :language="language" format="yyyy/MM/dd" placeholder="登録日" v-model="moving_date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date1_2" @click="showCalendar('datepicker')">
					</div>
					<div class="register-item">
						<div class="register-title">
							開始・終了日
						</div>
						<div class="register-datepicker" style="vertical-align: middle; width: calc(100% - 70px);" ref="date2_1">
							<datepicker ref="datepicker2" :language="language" format="yyyy/MM/dd" placeholder="開始・終了日" v-model="period"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date2_2" @click="showCalendar('datepicker2')">
					</div>
					<div class="register-item">
						<div class="register-title">
							事業所
						</div>
						<div class="input">
							<v-select :options="options_office" :reduce="options_office => options_office.id" v-model="params.office_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							お客様名
						</div>
						<template v-if="$route.query.type != 4">
							<div class="input" @click="is_restore = false">
								<v-select :options="options_customer" :reduce="options_customer => options_customer.id" v-model="customer_id">
									<template #no-options>データが存在しません</template>
								</v-select>
							</div>
						</template>
						<template v-else>
							<div class="input" style="height: 40px; background-color: #EBEBF2;"></div>
						</template>
					</div>
					<div class="register-item">
						<div class="register-title">
							記入者名
						</div>
						<div class="input">
							<input type="text" placeholder="記入者名" maxlength="64" v-model="params.author">
						</div>
					</div>
					<div class="register-item"></div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							現場名
						</div>
						<template v-if="$route.query.type != 4">
							<div class="input" style="width: calc(100% - 60px); display: inline-block;">
								<v-select :options="options_location" :reduce="options_location => options_location.id" v-model="params.location_id">
									<template #no-options>データが存在しません</template>
								</v-select>
							</div>
							<img src="../assets/img/plus.png" @click="is_popup = !is_popup" ref="popup">
							<div class="popup" v-if="is_popup" ref="popup_contents">
								<div class="input" style="width: 90%;  margin: 10px auto;">
									<input type="text" placeholder="現場名" maxlength="64" v-model="location_name">
								</div>
								<button class="button-next" style="float: right; margin-right: 20px;" @click="submitLocation()">登録</button>
							</div>
						</template>
						<template v-else>
							<div class="input" style="width: calc(100% - 60px); height: 40px; background-color: #EBEBF2;"></div>
						</template>
					</div>
					<div class="register-item">
						<div class="register-title">
							運送会社
						</div>
						<template v-if="$route.query.type != 3">
							<div class="input">
								<input type="text" placeholder="運送会社" maxlength="64" v-model="params.carrier">
							</div>
						</template>
						<template v-else>
							<div class="input" style="height: 40px; background-color: #EBEBF2;"></div>
						</template>
					</div>
					<div class="register-item"></div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							お客様担当
						</div>
						<div class="input">
							<input type="text" placeholder="お客様担当" maxlength="64" v-model="params.manager">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							運転者名・車番
						</div>
						<template v-if="$route.query.type != 3">
							<div class="input">
								<input type="text" placeholder="運転者名・車番" maxlength="64" v-model="params.driver">
							</div>
						</template>
						<template v-else>
							<div class="input" style="height: 40px; background-color: #EBEBF2;"></div>
						</template>
					</div>
					<div class="register-item"></div>
				</div>
			</div>
		</div>
		<div class="buttons">
			<button class="button-next" @click="toNext()">次へ</button>
		</div>
		<div id="modal" v-show="is_modal_validation">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal_validation = false">
                    <div id="modal-text">
						<span>
							{{ error_message }}
						</span>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal_validation = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import moment from 'moment';
import { ja } from "vuejs-datepicker/dist/locale";
import { SLIP_TYPE, SLIP_TYPE_LABEL } from '../util';
import { mapState } from 'vuex';
import 'vue-select/dist/vue-select.css';
export default {
    components: {
        Datepicker,
		vSelect,
    },
	data() {
		return {
			SLIP_TYPE_LABEL: SLIP_TYPE_LABEL,
			params: {
				office_id: null,
				customer_id: null,
				user_id: null,
				location_id: null,
				slip_type: this.$route.query.type,
				moving_date: null,
				period: null,
				author: null,
				carrier: null,
				manager: null,
				driver: null,
			},
			required_list: { // 入力必須リスト
				'office_id': "事業所",
				'moving_date': "登録日",
			},
			customer_id: null,
			moving_date: null,
			period: null,
			options_office: [],
			options_customer: [],
			options_location: [],
			is_modal_validation: false,
			error_params: [],
			flags: {
				customer: false,
				office: false,
			},
			is_popup: false,
			location_name: null,
			error_message: "",
			closePopupHandler: null,
			eventHandler: null,
			is_restore: true,
		}
	},
	created() {
		if (this.$route.query.type != 4) {
			this.required_list.customer_id = "お客様名"
			this.required_list.location_id = "現場名"
		}
		if (this.$route.query.type == 3) {
			this.required_list = {
				'office_id': "事業所",
				'moving_date': "登録日",
				'period': "開始・終了日",
				'author': "記入者名",
				'manager': "お客様担当",
				'customer_id': "お客様名",
				'location_id': "現場名"
			}
		}
		else if (this.$route.query.type == 3) {
			this.required_list = {
				'office_id': "事業所",
				'moving_date': "登録日",
				'period': "開始・終了日",
				'author': "記入者名",
				'carrier': "運送会社",
				'manager': "お客様担当",
				'driver': "運転者名・車番",
			}
		}
		this.params.user_id = this.userData.id
		this.params.author = this.userData.name
		this.getOfficeList()
		this.getCustomerMasterList()
	},
	mounted() {
		if (this.$route.query.type != 4) {
			window.addEventListener('click', this.closePopupHandler = (event) => {
				if (!this.$refs.popup.contains(event.target) && (this.is_popup && !this.$refs.popup_contents.contains(event.target))) {
					this.is_popup = false
				}
			})
		}
		window.addEventListener('click', this.eventHandler = (event) => {
			if (!this.$refs.date1_1.contains(event.target) && !this.$refs.date1_2.contains(event.target)) {
				this.$refs.date1_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
					}
				})
			}
			if (!this.$refs.date2_1.contains(event.target) && !this.$refs.date2_2.contains(event.target)) {
				this.$refs.date2_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker2.showCalendar()
					}
				})
			}
		})
	},
	methods: {
		// 検索用事業所一覧取得
		getOfficeList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
                .then(() => {
					this.officeMasterList.office.filter(item => {
						if (item.name !== "") {
							this.options_office.push({label: item.name, id: item.id})
						}
					})
					this.flags.office = true
					if (this.flags.customer && this.flags.office) {
						if (this.registerParams !== null) {
							this.params = this.registerParams
							this.customer_id = this.params.customer_id
							this.moving_date = this.params.moving_date.split(' ')[0]
							this.period = this.params.period
						}
					}
					this.$store.dispatch('loading/endLoad')
				})
		},
		// 検索用取引先マスタ取得
		getCustomerMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('customerMaster/getCustomerMasterApi', { name: null }))
                .then(() => {
					this.customerMasterList.list.filter(item => {
						this.options_customer.push({label: item.floor, id: item.id})
					})
					this.flags.customer = true
					if (this.flags.customer && this.flags.office) {
						if (this.registerParams !== null) {
							this.params = this.registerParams
							this.customer_id = this.params.customer_id
							this.moving_date = this.params.moving_date.split(' ')[0]
							this.period = this.params.period
						}
					}
					this.$store.dispatch('loading/endLoad')
				})
		},
		// 検索用事業所一覧取得
		getLocationList(id = null) {
			let customer_name = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === this.params.customer_id) {
					customer_name = item.floor
				}
			})
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('locationMaster/getLocationMasterApi', {customer_name: customer_name}))
                .then(() => {
					this.options_location = []
					this.locationMasterList.location.filter(item => {
						if (item.name !== "") {
							this.options_location.push({label: item.name, id: item.id})
						}
					})
					if (id !== null) {
						this.params.location_id = id
					}
					this.$store.dispatch('loading/endLoad')
				})
		},
		toNext() {
			this.error_params = []
			// 入力チェック
			Object.keys(this.params).filter(key => {
				Object.keys(this.required_list).filter(required_key => {
					if (required_key === key && (this.params[key] === null || this.params[key] === "")) {
						this.error_params.push(this.required_list[required_key])
					}
				})
			})
			if (this.error_params.length === 0) {
				this.params.slip_type = this.$route.query.type
				this.$store.commit('slip/setRegisterParams', this.params)
				if (this.$route.query.type === SLIP_TYPE.out) {
					this.$router.push('slip_regist_step2_out')
				}
				else if (this.$route.query.type === SLIP_TYPE.in) {
					this.$router.push('slip_regist_step2_in')
				}
				else if (this.$route.query.type === SLIP_TYPE.special) {
					this.$router.push('slip_regist_step2_special')
				}
				else if (this.$route.query.type === SLIP_TYPE.group) {
					this.$router.push('slip_regist_step2_group')
				}
			}
			else {
				this.error_message = ""
				this.error_params.filter((item, index) => {
					this.error_message += item.toString()
					if ((index + 1) !== this.error_params.length) {
						this.error_message += "、 "
					}
				})
				this.error_message += "が入力されていません。"
				this.is_modal_validation = true
			}
		},
		// 設置現場の追加
		submitLocation() {
			if (this.params.customer_id === null) {
				this.error_message = "お客様名を入力してください。"
				this.is_modal_validation = true
			}
			else if (this.location_name === null || this.location_name === "") {
				this.error_message = "追加する現場名を入力してください。"
				this.is_modal_validation = true
			}
			else {
				let params = {
					customer_id: this.params.customer_id,
					name: this.location_name,
				}
				this.$store.dispatch('loading/startLoad')
					.then(() => this.$store.dispatch('locationMaster/postLocationMasterApi', params))
					.then(() => {
						this.is_popup = false
						this.getLocationList(this.slipAddLocationId)
					})
			}
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
		},
	},
    computed: {
        language(){
            return ja;
        },
		...mapState({
			registerParams: state => state.slip.registerParams,
			userData: state => state.login.userData,
			customerMasterList: state => state.customerMaster.customerMasterList,
			officeMasterList: state => state.officeMaster.officeMasterList,
			locationMasterList: state => state.locationMaster.locationMasterList,
			slipAddLocationId: state => state.locationMaster.slipAddLocationId,
		})
    },
	watch: {
		moving_date(value) {
			this.params.moving_date = moment(value).format('YYYY-MM-DD')
			this.$refs.date1_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
				// カレンダー展開状態かどうか
				if (element.style.display === '') {
					this.$refs.datepicker.showCalendar()
					this.$refs.datepicker.showCalendar()
				}
			})

		},
		period(value) {
			this.params.period = moment(value).format('YYYY-MM-DD')
			this.$refs.date2_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
				// カレンダー展開状態かどうか
				if (element.style.display === '') {
					this.$refs.datepicker2.showCalendar()
					this.$refs.datepicker2.showCalendar()
				}
			})
		},
		customer_id(value) {
			this.params.customer_id = value
			if (!this.is_restore) {
				this.params.location_id = null
			}
			this.getLocationList()
		},
		$route() {
			if (this.$route.query.type == 1 || this.$route.query.type == 2) {
				this.required_list.customer_id = "お客様名"
				this.required_list.location_id = "現場名"

				if (this.closePopupHandler === null) {
					window.addEventListener('click', this.closePopupHandler = (event) => {
						if (this.$refs.popup !== undefined) {
							if (!this.$refs.popup.contains(event.target) && (this.is_popup && !this.$refs.popup_contents.contains(event.target))) {
								this.is_popup = false
							}
						}
					})
				}
			}
			else if (this.$route.query.type == 3) {
				if (this.required_list.customer_id !== undefined) {
					this.required_list = {
						'office_id': "事業所",
						'moving_date': "登録日",
						'period': "開始・終了日",
						'author': "記入者名",
						'manager': "お客様担当",
						'customer_id': "お客様名",
						'location_id': "現場名"
					}
				}
			}
			else if (this.$route.query.type == 4) {
				if (this.required_list.customer_id !== undefined) {
					this.required_list = {
						'office_id': "事業所",
						'moving_date': "登録日",
						'period': "開始・終了日",
						'author': "記入者名",
						'carrier': "運送会社",
						'manager': "お客様担当",
						'driver': "運転者名・車番",
					}
				}
			}
		}
	},
	destroyed() {
		if (this.closePopupHandler != null) {
			window.removeEventListener('click', this.closePopupHandler)
		}
		window.removeEventListener('click', this.eventHandler)
	},
}
</script>

<style scoped src="../assets/css/slip.css"></style>
<style scoped src="../assets/css/modal.css"></style>