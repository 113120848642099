import Vue from 'vue'
import Vuex from 'vuex'

import loading from './loading'
import itemMaster from './itemMaster'
import itemCategoryMaster from './itemCategoryMaster'
import customerMaster from './customerMaster'
import locationMaster from './locationMaster'
import officeMaster from './officeMaster'
import calendar from './calendar'
import stock from './stock'
import login from './login'
import userList from './userList'
import slip from './slip'
import sleep from './sleep'
import invoices from './invoices'
import error from './error'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		loading,
		itemMaster,
		itemCategoryMaster,
		customerMaster,
		locationMaster,
		officeMaster,
		calendar,
		stock,
		login,
		userList,
		slip,
		sleep,
		invoices,
		error,
	}
})

export default store