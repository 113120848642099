<template>
    <div>
        <div id="main-text">
            <span>請求カレンダー</span>
        </div>
        <div id="main-contents">
            <div class="calendar-header">
                <div id="calendar-filter">
                    <div class="filter-button" @click="todayWeek()">
                        <span>Today</span>
                    </div>
                    <div class="filter-button" @click="prevWeek()">
                        <span>Back</span>
                    </div>
                    <div class="filter-button" @click="nextWeek()">
                        <span>Next</span>
                    </div>
                </div>
                <div id="calendar-filter">
                    <div class="filter-button" @click="toggleType(true)" :style="{'background-color': is_invoices ? '#3B86FF' : '#FFFFFF'}">
                        <span :style="{'color': is_invoices ? '#FFFFFF' : '#4D4F5C'}">請求</span>
                    </div>
                    <div class="filter-button" @click="toggleType(false)" :style="{'background-color': !is_invoices ? '#3B86FF' : '#FFFFFF', 'border-right': 'none'}">
                        <span :style="{'color': !is_invoices ? '#FFFFFF' : '#4D4F5C'}">入金</span>
                    </div>
                </div>
                <div class="calendar-date">
                    <span id="year">{{ current_year }}年</span>
                    <span id="month-day">{{ header_start_day }} - {{ header_last_day }}</span>
                </div>
            </div>
            <div class="calendar-contents">
                <table>
                    <tr>
                        <th v-for="(day, day_index) in calendar.header" :key="'day_' + day_index" :style="day.is_holiday ? 'color: red' : ''">{{ day.date }}</th>
                    </tr>
                    <template v-if="is_invoices">
                        <tr v-for="(data, data_index) in calendar.invoices_data" :key="'tr_invoices_' + data_index">
                            <td v-for="(item, item_index) in data" :key="'item_invoices_' + item_index" :class="{'hidden': item.is_today}">
                                <template v-if="item.invoices.name !== ''">
                                    <span :class="{'button-black': item.invoices.flag, 'button-blue': !item.invoices.flag}" v-tooltip="item.invoices.name" @click="toInvoicesRegist(item.invoices)">
                                        {{ item.invoices.name }}
                                    </span>
                                </template>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr v-for="(data, data_index) in calendar.payment_data" :key="'tr_payment_' + data_index">
                            <td v-for="(item, item_index) in data" :key="'item_payment_' + item_index" :class="{'hidden': item.is_today}">
                                <template v-if="item.payment.name !== ''">
                                    <span :class="{'button-black': item.payment.flag, 'button-blue': !item.payment.flag}" v-tooltip="item.payment.name" @click="toInvoices(item.payment)">
                                        {{ item.payment.name }}
                                    </span>
                                </template>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment';
export default {
	data() {
		return {
            max_row: 50,
            calendar: {
                header: [],
                invoices_data: [],
                payment_data: [],
            },
            is_invoices: true, // true: invoices, false: payment
            current_year: 0,
            current_month: 0,
            header_start_day: "",
            header_last_day: "",
            current_week: 0,
            holiday: [],
            today: "",
            get_holiday_year: [],
		}
	},
	created() {
        moment.locale("ja");

        // 年取得
        this.current_year = moment().year()
        // 月取得
        this.current_month = moment().month() + 1

        this.today = moment().format('YYYY/MM/DD')

        // 最初に今年の祝日を取得
        this.getCalendarHoliday(this.current_year, true)
	},
	mounted() {

	},
	methods: {
        // 選択年の祝日取得
        async getCalendarHoliday(year, is_first = false) {
            if (this.holiday.indexOf(year) === -1) {
                this.$store.dispatch('loading/startLoad')
                    .then(async () => {
                        await this.$store.dispatch('calendar/getHolidayApi', {year: year})
                        if (this.calendarApiStatus) {
                            this.holidayData.filter(data => {
                                if (this.holiday.indexOf(data.date) === -1) {
                                    this.holiday.push(data.date)
                                }
                            })
                            if (is_first) {
                                // 去年、来年の祝日を取得
                                this.getCalendarHoliday(this.current_year - 1)
                                this.getCalendarHoliday(this.current_year + 1)
                                this.get_holiday_year = [this.current_year, this.current_year - 1, this.current_year + 1]

                                // 今月、先月、来月の入金データを取得
                                this.getCalendarPayment(moment().add(0, 'month').startOf('week').format("YYYY-MM"))
                                this.getCalendarPayment(moment().add(1, 'month').startOf('week').format("YYYY-MM"))
                                this.getCalendarPayment(moment().add(-1, 'month').startOf('week').format("YYYY-MM"))

                                // 今月、先月、来月の請求データを取得
                                this.getCalendarInvoices(moment().add(0, 'month').startOf('week').format("YYYY-MM"))
                                this.getCalendarInvoices(moment().add(1, 'month').startOf('week').format("YYYY-MM"))
                                this.getCalendarInvoices(moment().add(-1, 'month').startOf('week').format("YYYY-MM"))
                            }
                            else {
                                // カレンダー構築
                                this.getDayData(this.current_week)
                            }
                        }
                    })
                    
            }
        },
        // 該当年月の請求日取得
        async getCalendarInvoices(date) {
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('calendar/getCalendarInvoicesApi', {date: date}))
                .then(() => this.getDayData(this.current_week))
                .then(() => {
                    if (this.is_invoices) {
                        this.$store.dispatch('loading/endLoad')
                    }
                })
        },
        // 該当年月の請求日取得
        async getCalendarPayment(date) {
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('calendar/getCalendarPaymentApi', {date: date}))
                .then(() => this.getDayData(this.current_week))
                .then(() => {
                    if (!this.is_invoices) {
                        this.$store.dispatch('loading/endLoad')
                    }
                })
        },
        // カレンダー構築処理
        getDayData(add_week = 0, is_next = null) {
            // カレンダーデータをリセット
            this.calendar = {
                header: [],
                invoices_data: [],
                payment_data: [],
            }

            // 年取得
            let is_year_changed = false
            if (this.current_year !== moment().add(add_week, 'week').year()) {
                this.current_year = moment().add(add_week, 'week').year()
                is_year_changed  =true
            }
            // 月取得
            let is_month_changed = false
            if (this.current_month !== (moment().add(add_week, 'week').month() + 1)) {
                this.current_month = moment().add(add_week, 'week').month() + 1
                is_month_changed = true
            }

            // 今週の開始取得（ヘッダー表示用）
            this.header_start_day =  moment().add(add_week, 'week').startOf('week').format("MM月DD日")
            // 今週の終わり取得（ヘッダー表示用）
            this.header_last_day = moment().add(add_week, 'week').endOf('week').format("MM月DD日")

            // ヘッダーセット
            for (let i = 0; i < 7; i++) {
                this.calendar.header.push({
                    date: moment(moment().add(add_week, 'week').startOf('week')).add(i, 'days').format("MM/DD (ddd)"),
                    is_holiday: this.holiday.indexOf(moment(moment().add(add_week, 'week').startOf('week')).add(i, 'days').format("YYYY-MM-DD")) !== -1 || moment(moment().add(add_week, 'week').startOf('week')).add(i, 'days').day() === 0 || moment(moment().add(add_week, 'week').startOf('week')).add(i, 'days').day() === 6,
                })
            }

            // 請求データ、入金データで一番長いlengthを取得する
            let length = 0
            let invoices_length = this.invoicesData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)] !== undefined ? this.invoicesData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)].length : 0
            let payment_length = this.paymentData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)] !== undefined ? this.paymentData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)].length : 0
            if (invoices_length < payment_length) {
                if (this.max_row < payment_length) {
                    length = payment_length
                }
                else {
                    length = this.max_row
                }
            }
            else {
                if (this.max_row < invoices_length) {
                    length = invoices_length
                }
                else {
                    length = this.max_row
                }
            }

            // 日ごとの空データをセットする
            let invoices = []
            let payment = []
            for (let row = 0; row < length; row++) {
                for (let i = 0; i < 7; i++) {
                    let data = {
                        day: this.calendar.header[i].date.split('/')[1].split(' ')[0],
                        is_today: this.today === this.current_year + "/" + moment(moment().add(add_week, 'week').startOf('week')).add(i, 'days').format("MM/DD"),
                        invoices: {
                            name: "",
                            id: 0,
                            flag: false,
                            customer_id: null,
                            date: null,
                        },
                        payment: {
                            name: "",
                            id: 0,
                            flag: false,
                            customer_id: null,
                            start_date: null,
                            end_date: null,
                            date: null,
                        },
                    }
                    // 請求情報をセットする
                    if (this.invoicesData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)] !== undefined) {
                        invoices.push(data)
                    }
                    // 入金情報をセットする
                    if (this.paymentData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)] !== undefined) {
                        payment.push(data)
                    }
                }
                // カレンダーデータに追加
                this.calendar.invoices_data.push(invoices)
                this.calendar.payment_data.push(payment)
                invoices = []
                payment = []
            }

            // 請求、入金データの設定
            this.setInvoicesData()
            this.setPaymentData()

            // 年が変更された場合は祝日取得APIを叩く
            if (is_year_changed) {
                // 常に±１のデータを持っておく
                if (this.get_holiday_year.indexOf(this.current_year + 1) === -1) {
                    this.getCalendarHoliday(this.current_year + 1)
                }
                else if (this.get_holiday_year.indexOf(this.current_year - 1) === -1) {
                    this.getCalendarHoliday(this.current_year - 1)
                }
            }
            // 月が変更された場合は請求/入金取得APIを叩く
            if (is_month_changed) {
                if (is_next) {
                    this.getCalendarInvoices(moment().add(add_week + 1, 'week').startOf('week').format("YYYY-MM"))
                    this.getCalendarPayment(moment().add(add_week + 1, 'week').startOf('week').format("YYYY-MM"))
                }
                else if (is_next === false) {
                    this.getCalendarInvoices(moment().add(add_week - 1, 'week').startOf('week').format("YYYY-MM"))
                    this.getCalendarPayment(moment().add(add_week - 1, 'week').startOf('week').format("YYYY-MM"))
                }
            }
		},
        setInvoicesData() {
            let result = this.calendar.invoices_data
            let data = this.calendar.invoices_data

            data.filter((weekly, weekly_index) => {
                weekly.filter((day, day_index) => {
                    if (day.invoices.id === 0) {
                        let today_data = this.invoicesData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)].filter(data => data.date === day.day)
                        if (today_data[weekly_index] !== undefined && today_data[weekly_index].date === day.day) {
                            result[weekly_index][day_index].invoices.name = today_data[weekly_index].name
                            result[weekly_index][day_index].invoices.id = today_data[weekly_index].invoice_id
                            result[weekly_index][day_index].invoices.customer_id = today_data[weekly_index].customer_id
                            result[weekly_index][day_index].invoices.date = this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month) + '-' + today_data[weekly_index].date
                        }
                    }
                })
            })
            this.calendar.invoices_data = result
        },
        setPaymentData() {
            let result = this.calendar.payment_data
            let data = this.calendar.payment_data
            data.filter((weekly, weekly_index) => {
                weekly.filter((day, day_index) => {
                    if (day.payment.id === 0) {
                        let today_data = this.paymentData[this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month)].filter(data => data.date === day.day)
                        if (today_data[weekly_index] !== undefined && today_data[weekly_index].date === day.day) {
                            result[weekly_index][day_index].payment.name = today_data[weekly_index].name
                            result[weekly_index][day_index].payment.id = today_data[weekly_index].invoice_id
                            result[weekly_index][day_index].payment.flag = today_data[weekly_index].flag
                            result[weekly_index][day_index].payment.customer_id = today_data[weekly_index].customer_id
                            result[weekly_index][day_index].payment.start_date = today_data[weekly_index].start_date
                            result[weekly_index][day_index].payment.end_date = today_data[weekly_index].end_date
                            result[weekly_index][day_index].payment.date = this.current_year + "-" + (this.current_month.toString().length === 1 ? '0' + this.current_month : this.current_month) + '-' + today_data[weekly_index].date
                        }
                    }
                })
            })
            this.calendar.payment_data = result
        },
        // 請求/入金の表示切り替え
        toggleType(type) {
            this.is_invoices = type
        },
        // 現在の週ページへ飛ぶ
        todayWeek() {
            this.current_week = 0
            this.getDayData(this.current_week)
        },
        // 次の週へ飛ぶ
        nextWeek() {
            this.current_week++
            this.getDayData(this.current_week, true)
        },
        // 前の週へ飛ぶ
        prevWeek() {
            this.current_week--
            this.getDayData(this.current_week, false)
        },
        // 請求書詳細画面へ
        toInvoices(data) {
            this.$router.push('invoices?id=' + data.id + '&customer_id=' + data.customer_id + '&start_date=' + data.start_date + '&end_date=' + data.end_date)
        },
        // 請求書新規作成画面へ
        toInvoicesRegist(data) {
            this.$router.push('invoices_regist_step1?customer_id=' + data.customer_id + '&date=' + data.date)
        }
	},
    destroyed() {
        // 画面遷移した場合はカレンダーデータを破棄する
        this.$store.commit('calendar/resetData')
    },
    computed: {
        ...mapState({
            calendarApiStatus: state => state.calendar.apiStatus,
            holidayData: state => state.calendar.holidayData,
            invoicesData: state => state.calendar.invoicesData,
            paymentData: state => state.calendar.paymentData,
        })
    }
}
</script>

<style scoped src="../assets/css/calendar.css"></style>