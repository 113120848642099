<template>
	<div>
		<div id="main-text">
			<span>請求書登録</span>
		</div>
		<div id="main-contents" style="height: auto;">
			<div class="step-contents">
				<div class="step">
					<div class="step-circle" onclick="javascript:history.back()">
						<span><img src="../assets/img/check.png"></span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" onclick="javascript:history.back()">
						Step 1
					</div>
					<div class="step-word" style="font-weight: bold;">
						Step 2
					</div>
				</div>
			</div>
            <div class="table-and-preview">
                <div class="money-table">
                    <table>
                        <tr>
                            <th>当月売上額</th>
                            <th>消費税</th>
                            <th>当月ご請求額</th>
                        </tr>
                        <tr>
                            <td>{{ result_1.toLocaleString() }}</td>
                            <td>{{ result_2.toLocaleString() }}</td>
                            <td>{{ result_3.toLocaleString() }}</td>
                        </tr>
                    </table>
                </div>
                <button class="preview" @click="is_preview = true">
                    プレビュー
                </button>
            </div>
			<div class="register-step2-contents">
                <div class="register-button">
                    <router-link to="slip">
                        <button>
                            伝票登録
                        </button>
                    </router-link>
                </div>
                <table class="list-table slip-regist-step2-table">
                    <tr class="list-table-header">
                        <th>日付</th>
                        <th colspan="2">商品名</th>
                        <th style="width: 5vw">型式</th>
                        <th>タイプ</th>
                        <th>請求種別</th>
                        <th colspan="2">貸出期間</th>
                        <th>日数</th>
                        <th>数量</th>
                        <th>単価</th>
                        <th>税率</th>
                        <th>金額</th>
                        <th>消費税額</th>
                        <th colspan="2">備考</th>
                    </tr>
                </table>
                <div class="table-scroller" style="height: 35vh">
                    <table class="contents-scroll-table">
                        <template v-for="(item, index) in invoices_list">
                            <tr class="list-table-row" style="cursor: default;" :key="'tr_' + index">
                                <td>
                                    <!-- 日付 -->
                                    <span v-if="item.date === null || item.date === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="setFormat(item.date, true)">{{ setFormat(item.date, true) }}</span>
                                </td>
                                <td colspan="2">
                                    <!-- 商品名 -->
                                    <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                </td>
                                <td style="width: 5vw">
                                    <!-- 型式 -->
                                    <span v-if="item.model_number === null || item.model_number === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.model_number">{{ item.model_number }}</span>
                                </td>
                                <td>
                                    <!-- タイプ -->
                                    <span v-if="item.type === null || item.type === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="type_label[item.type - 1]">{{ type_label[item.type - 1] }}</span>
                                </td>
                                <td>
                                    <!-- 請求種別 -->
                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                    <span v-else style="text-overflow: initial;" v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                </td>
                                <td colspan="2">
                                    <!-- 貸出期間 -->
                                    <span v-if="item.start_date === null || item.start_date === '' || item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true)">{{ setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true) }}</span>
                                </td>
                                <!-- 休止中なら日数と数量を潰す -->
                                <template v-if="item.pause_status === 1 || item.pause_status === 3">
                                    <td colspan="2">
                                        <span><div class="stop">{{ item.pause_status === 1 ? '休止中' : '修理中' }}</div></span>
                                    </td>
                                </template>
                                <template v-else>
                                    <td>
                                        <!-- 日数 -->
                                        <span v-if="item.days === null || item.days === ''"></span>
                                        <span v-else v-tooltip="item.days">{{ item.days }}</span>
                                    </td>
                                    <td>
                                        <!-- 数量 -->
                                        <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                    </td>
                                </template>
                                <td>
                                    <!-- 単価 -->
                                    <span v-if="item.price === null || item.price === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.price">{{ item.price }}</span>
                                </td>
                                <td>
                                    <!-- 税率 -->
                                    <span v-if="item.tax === null || item.tax === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.tax + '%'">{{ item.tax + '%' }}</span>
                                </td>
                                <td>
                                    <!-- 金額 -->
                                    <span v-if="item.result === null || item.result === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.result">{{ item.result }}</span>
                                </td>
                                <td>
                                    <!-- 消費税額 -->
                                    <span v-if="item.result_tax === null || item.result_tax === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.result_tax">{{ item.result_tax }}</span>
                                </td>
                                <td colspan="2">
                                    <!-- 備考 -->
                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                </td>
                            </tr>
                            <template v-if="item.options !== null">
                                <tr v-for="(option, option_index) in item.options" class="list-table-row option" style="cursor: default;" :key="'tr_' + index + '_' + option_index">
                                    <td>
                                        <!-- 日付 -->
                                        <span v-if="item.date === null || item.date === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="setFormat(item.date, true)">{{ setFormat(item.date, true) }}</span>
                                    </td>
                                    <td colspan="2">
                                        <!-- 商品名 -->
                                        <span v-if="option.name === null || option.name === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="option.name">{{ option.name }}</span>
                                    </td>
                                    <td style="width: 5vw">
                                        <!-- 型式 -->
                                        <span v-tooltip="'-'">-</span>
                                    </td>
                                    <td>
                                        <!-- タイプ -->
                                        <span v-tooltip="'-'">-</span>
                                    </td>
                                    <td>
                                        <!-- 請求種別 -->
                                        <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                        <span v-else style="text-overflow: initial;" v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                    </td>
                                    <td colspan="2">
                                        <!-- 貸出期間 -->
                                        <span v-if="item.start_date === null || item.start_date === '' || item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true)">{{ setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true) }}</span>
                                    </td>
                                    <!-- 休止中なら日数と数量を潰す -->
                                    <template v-if="item.pause_status === 1 || item.pause_status === 3">
                                        <td colspan="2">
                                            <span><div class="stop">{{ item.pause_status === 1 ? '休止中' : '修理中' }}</div></span>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td>
                                            <!-- 日数 -->
                                            <span v-if="item.days === null || item.days === ''"></span>
                                            <span v-else v-tooltip="item.days">{{ item.days }}</span>
                                        </td>
                                        <td style="text-overflow: initial; padding: 1vh 10px;">
                                            <!-- 数量 -->
                                            <input type="number" style="width: 2vw;" @input="option.count = inputNumberMaxLength(option.count, 9, index, option_index)" v-model="option.count">
                                        </td>
                                    </template>
                                    <!-- 単価 -->
                                    <template v-if="item.pause_status === 1 || item.pause_status === 3">
                                        <td>
                                            <span v-if="option.price === null || option.price === ''" v-tooltip="'-'">-</span>
                                            <span v-else v-tooltip="option.price">{{ option.price }}</span>
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td style="text-overflow: initial; padding: 1vh 10px;">
                                            <input type="number" style="width: 2.5vw;" @input="option.price = inputNumberMaxLength(option.price, 9, index, option_index)" v-model="option.price">
                                        </td>
                                    </template>
                                    <td>
                                        <!-- 税率 -->
                                        <template v-if="option.tax !== undefined && option.tax !== null">
                                            <span v-if="option.tax === null || option.tax === ''" v-tooltip="'-'">-</span>
                                            <span v-else v-tooltip="option.tax + '%'">{{ option.tax + '%' }}</span>
                                        </template>
                                        <template v-else>
                                            <span v-if="item.tax === null || item.tax === ''" v-tooltip="'-'">-</span>
                                            <span v-else v-tooltip="item.tax + '%'">{{ item.tax + '%' }}</span>
                                        </template>
                                    </td>
                                    <td>
                                        <!-- 金額 -->
                                        <span v-if="option.result === null || option.result === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="option.result">{{ option.result }}</span>
                                    </td>
                                    <td>
                                        <!-- 消費税額 -->
                                        <span v-if="option.result_tax === null || option.result_tax === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="option.result_tax">{{ option.result_tax }}</span>
                                    </td>
                                    <td colspan="2">
                                        <!-- 備考 -->
                                        <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                        <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </table>
                </div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-prev" onclick="javascript:history.back()">戻る</button>
            <button class="button-next" @click="register()">登録</button>
		</div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        入力に不備があります。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="preview-modal" v-show="is_preview" @click.self="is_preview = false">
            <div id="preview-modal-background">
                <div id="preview-modal-content">
                    <div id="preview-modal-main-contents">
                        <div>
                            <div class="invoices-title">
                                御請求書
                            </div>
                            <div class="date">
                                {{ setFormat(registerParams.date) }}
                            </div>
                            <div class="right-side">
                                <!-- <img src="../assets/img/stamp.jpg" style="width: 100px; position: absolute; top: 40px; right: 300px;"> -->
                                <div class="name">
                                    宏陽株式会社
                                </div>
                                <div class="location">
                                    <div>札幌市東区東苗穂8条3丁目9-11</div>
                                    <div>電話（011）214-9083</div>
                                    <div>FAX （011）214-9082</div>
                                    <div>取引銀行 商工中金 札幌支店 (普)1111111</div>
                                </div>
                            </div>
                            <div class="left-side" v-if="invoicesRegisterList !== null">
                                <div class="location">
                                    <div>{{ invoicesRegisterList.list.zip_code }}</div>
                                    <div>{{ invoicesRegisterList.list.address }}</div>
                                </div>
                                <div class="name">
                                    {{ invoicesRegisterList.list.name }}
                                </div>
                            </div>
                            <div class="table-and-preview">
                                <div class="money-table">
                                    <table>
                                        <tr>
                                            <th>当月売上額</th>
                                            <th>消費税</th>
                                            <th>当月ご請求額</th>
                                        </tr>
                                        <tr>
                                            <td>{{ result_1.toLocaleString() }}</td>
                                            <td>{{ result_2.toLocaleString() }}</td>
                                            <td>{{ result_3.toLocaleString() }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <table class="list-table slip-regist-step2-table">
                                <tr class="list-table-header">
                                    <th>日付</th>
                                    <th colspan="2">商品名</th>
                                    <th style="width: 5vw">型式</th>
                                    <th>タイプ</th>
                                    <th>請求種別</th>
                                    <th colspan="2">貸出期間</th>
                                    <th>日数</th>
                                    <th>数量</th>
                                    <th>単価</th>
                                    <th>税率</th>
                                    <th>金額</th>
                                    <th>消費税額</th>
                                    <th colspan="2">備考</th>
                                </tr>
                            </table>
                            <div class="table-scroller" style="height: 50vh;">
                                <table class="contents-scroll-table">
                                    <template v-for="(item, index) in invoices_list">
                                        <tr class="list-table-row" style="cursor: default;" :key="'tr_' + index">
                                            <td>
                                                <!-- 日付 -->
                                                <span v-if="item.date === null || item.date === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="setFormat(item.date, true)">{{ setFormat(item.date, true) }}</span>
                                            </td>
                                            <td colspan="2">
                                                <!-- 商品名 -->
                                                <span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.name">{{ item.name }}</span>
                                            </td>
                                            <td style="width: 5vw">
                                                <!-- 型式 -->
                                                <span v-if="item.model_number === null || item.model_number === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.model_number">{{ item.model_number }}</span>
                                            </td>
                                            <td>
                                                <!-- タイプ -->
                                                <span v-if="item.type === null || item.type === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="type_label[item.type - 1]">{{ type_label[item.type - 1] }}</span>
                                            </td>
                                            <td>
                                                <!-- 請求種別 -->
                                                <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                <span v-else style="text-overflow: initial;" v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                            </td>
                                            <td colspan="2">
                                                <!-- 貸出期間 -->
                                                <span v-if="item.start_date === null || item.start_date === '' || item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true)">{{ setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true) }}</span>
                                            </td>
                                            <template v-if="item.pause_status === 1 || item.pause_status === 3">
                                                <td colspan="2">
                                                    <span><div class="stop">{{ item.pause_status === 1 ? '休止中' : '修理中' }}</div></span>
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td>
                                                    <!-- 日数 -->
                                                    <span v-if="item.days === null || item.days === ''"></span>
                                                    <span v-else v-tooltip="item.days">{{ item.days }}</span>
                                                </td>
                                                <td>
                                                    <!-- 数量 -->
                                                    <span v-if="item.count === null || item.count === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.count">{{ item.count }}</span>
                                                </td>
                                            </template>
                                            <td>
                                                <!-- 単価 -->
                                                <span v-if="item.price === null || item.price === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.price">{{ item.price }}</span>
                                            </td>
                                            <td>
                                                <!-- 税率 -->
                                                <span v-if="item.tax === null || item.tax === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.tax + '%'">{{ item.tax + '%' }}</span>
                                            </td>
                                            <td>
                                                <!-- 金額 -->
                                                <span v-if="item.result === null || item.result === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.result">{{ item.result }}</span>
                                            </td>
                                            <td>
                                                <!-- 消費税額 -->
                                                <span v-if="item.result_tax === null || item.result_tax === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.result_tax">{{ item.result_tax }}</span>
                                            </td>
                                            <td colspan="2">
                                                <!-- 備考 -->
                                                <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                            </td>
                                        </tr>
                                        <template v-if="item.options !== null">
                                            <tr v-for="(option, option_index) in item.options" class="list-table-row" style="cursor: default;" :key="'tr_' + index + '_' + option_index">
                                                <td>
                                                    <!-- 日付 -->
                                                    <span v-if="item.date === null || item.date === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="setFormat(item.date, true)">{{ setFormat(item.date, true) }}</span>
                                                </td>
                                                <td colspan="2">
                                                    <!-- 商品名 -->
                                                    <span v-if="option.name === null || option.name === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="option.name">{{ option.name }}</span>
                                                </td>
                                                <td style="width: 5vw">
                                                    <!-- 型式 -->
                                                    <span v-tooltip="'-'">-</span>
                                                </td>
                                                <td>
                                                    <!-- タイプ -->
                                                    <span v-if="item.type === null || item.type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="type_label[item.type - 1]">{{ type_label[item.type - 1] }}</span>
                                                </td>
                                                <td>
                                                    <!-- 請求種別 -->
                                                    <span v-if="item.slip_detail_type === null || item.slip_detail_type === ''" v-tooltip="'-'">-</span>
                                                    <span v-else style="text-overflow: initial;" v-tooltip="slip_detail_label[item.slip_detail_type]">{{ slip_detail_label[item.slip_detail_type] }}</span>
                                                </td>
                                                <td colspan="2">
                                                    <!-- 貸出期間 -->
                                                    <span v-if="item.start_date === null || item.start_date === '' || item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true)">{{ setFormat(item.start_date, true) + ' ~ ' + setFormat(item.end_date, true) }}</span>
                                                </td>
                                                <template v-if="item.pause_status === 1 || item.pause_status === 3">
                                                    <td colspan="2">
                                                        <span><div class="stop">{{ item.pause_status === 1 ? '休止中' : '修理中' }}</div></span>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td>
                                                        <!-- 日数 -->
                                                        <span v-if="item.days === null || item.days === ''"></span>
                                                        <span v-else v-tooltip="item.days">{{ item.days }}</span>
                                                    </td>
                                                    <td>
                                                        <!-- 数量 -->
                                                        <span v-if="option.count === null || option.count === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="option.count">{{ option.count }}</span>
                                                    </td>
                                                </template>
                                                <td>
                                                    <!-- 単価 -->
                                                    <span v-if="option.price === null || option.price === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="option.price">{{ option.price }}</span>
                                                </td>
                                                <td>
                                                    <!-- 税率 -->
                                                    <template v-if="option.tax !== undefined && option.tax !== null">
                                                        <span v-if="option.tax === null || option.tax === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="option.tax + '%'">{{ option.tax + '%' }}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span v-if="item.tax === null || item.tax === ''" v-tooltip="'-'">-</span>
                                                        <span v-else v-tooltip="item.tax + '%'">{{ item.tax + '%' }}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    <!-- 金額 -->
                                                    <span v-if="option.result === null || option.result === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="option.result">{{ option.result }}</span>
                                                </td>
                                                <td>
                                                    <!-- 消費税額 -->
                                                    <span v-if="option.result_tax === null || option.result_tax === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="option.result_tax">{{ option.result_tax }}</span>
                                                </td>
                                                <td colspan="2">
                                                    <!-- 備考 -->
                                                    <span v-if="item.note === null || item.note === ''" v-tooltip="'-'">-</span>
                                                    <span v-else v-tooltip="item.note">{{ item.note }}</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { inputNumberMaxLength, SLIP_DETAIL_LABEL } from '../util'
export default {
	data() {
		return {
            slip_detail_label: SLIP_DETAIL_LABEL,
            invoices_list: [],
            is_preview: false,
            result_1: 0, // 当月売上額
            result_2: 0, // 消費税合計
            result_3: 0, // 当月売上額 + 消費税合計
            type_label: [
                "新規",
                "繰越",
                "返却,"
            ],
            is_modal: false,
		}
	},
	created() {
        this.getInvoicesDetailList()
	},
	mounted() {

	},
	methods: {
        register() {
            let params = {
                date: this.registerParams.date,
                customer_id: this.registerParams.customer_id,
                start_date: moment(this.registerParams.start_date).format('YYYY-MM-DD'),
                end_date: moment(this.registerParams.end_date).format('YYYY-MM-DD'),
                options: [],
            }
            this.invoices_list.filter(item => {
                if (item.options !== undefined && item.options !== null) {
                    item.options.filter(option => {
                        params.options.push({
                            id: option.id,
                            price: option.price,
                            count: option.count,
                            name: option.name,
                            tax: option.tax,
                        })
                    })
                }
            })

            // 入力チェック
            let error = []
            if (params.date === undefined || params.date === null || params.date === "") {
                error.push(true)
            }
            if (params.customer_id === undefined || params.customer_id === null  || params.customer_id === "") {
                error.push(true)
            }
            if (params.start_date === undefined || params.start_date === null  || params.start_date === "") {
                error.push(true)
            }
            if (params.end_date === undefined || params.end_date === null  || params.end_date === "") {
                error.push(true)
            }
            params.options.filter(item => {
                if (item.id === undefined || item.id === null || item.id === "") {
                    error.push(true)
                }
                if (item.price === undefined || item.price === null || item.price === "") {
                    error.push(true)
                }
                if (item.count === undefined || item.count === null || item.count === "") {
                    error.push(true)
                }
            })

            if (error.length === 0) {
                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('invoices/postInvoices', params))
                    .then(() => {
                        this.$store.dispatch('loading/endLoad')
                        this.$router.push('invoices')
                    })
            }
            else {
                this.is_modal = true
            }
        },
        getInvoicesDetailList() {
            const params = {
                customer_id: this.registerParams.customer_id,
                start_date: this.registerParams.start_date,
                end_date: this.registerParams.end_date,
            }
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('invoices/getInvoicesRegisterListApi', params))
                .then(() => {
                    if (this.invoicesRegisterList.list.length !== 0) {
                        this.invoicesRegisterList.list.data.filter((item, index) => {
                            this.invoices_list.push(item)
                            if (item.slip_detail_type == 8 || item.count < 0) {
                                this.invoices_list[index].result = 0
                            }
                            else {
                                if (item.days !== null) {
                                    this.invoices_list[index].result = item.pause_status === 1 ? 0 : ((item.price * item.count) * item.days)
                                }
                                else {
                                    this.invoices_list[index].result = item.pause_status === 1 ? 0 : (item.price * item.count)
                                }
                            }
                            let tax = Math.floor((this.invoices_list[index].result * (item.tax === 8 ? 108 : 110) / 100)) - this.invoices_list[index].result
                            // 数量がマイナスなら消費税マイナス請求
                            if (item.count < 0) {
                                if (item.days !== null) {
                                    this.invoices_list[index].result_tax = item.pause_status === 1 ? 0 : ((item.price * item.count) * item.days)
                                }
                                else {
                                    this.invoices_list[index].result_tax = item.pause_status === 1 ? 0 : (item.price * item.count)
                                }
                            }
                            else {
                                this.invoices_list[index].result_tax = item.pause_status === 1 ? 0 : Math.ceil(tax)
                            }
                            this.result_1 += this.invoices_list[index].result
                            this.result_2 += this.invoices_list[index].result_tax
                            if (item.options !== undefined && item.options !== null) {
                                let options = []
                                item.options.filter((option, options_index) => {
                                    options.push(option)
                                    options[options_index].result = item.pause_status === 1 ? 0 : (option.price * option.count)
                                    let tax = 0
                                    if (option.tax !== undefined && option.tax !== null) {
                                        tax = Math.floor((options[options_index].result * (option.tax === 8 ? 108 : 110) / 100)) - options[options_index].result
                                    }
                                    else {
                                        tax = Math.floor((options[options_index].result * (item.tax === 8 ? 108 : 110) / 100)) - options[options_index].result
                                    }
                                    options[options_index].result_tax = item.pause_status === 1 ? 0 : Math.ceil(tax)
                                    this.result_1 += options[options_index].result
                                    this.result_2 += options[options_index].result_tax
                                })
                                this.invoices_list[index].options = options
                                options = []
                            }
                        })
                        this.result_3 = this.result_1 + this.result_2
                    }
                    this.$store.dispatch('loading/endLoad')
				})
        },
        setFormat(date, is_month_day = false) {
            return is_month_day ? moment(date).format('MM/DD') : moment(date).format('YYYY年MM月DD日')
        },
        inputNumberMaxLength(value, max, index, option_index) {
            const input_result = inputNumberMaxLength(value, max)
            // オプションの金額、消費税額を再計算
            this.invoices_list[index].options[option_index].result = this.invoices_list[index].options[option_index].count * this.invoices_list[index].options[option_index].price
            let tax = 0
            if (this.invoices_list[index].options[option_index].tax !== null) {
                tax = Math.floor((this.invoices_list[index].options[option_index].result * (this.invoices_list[index].options[option_index].tax === 8 ? 108 : 110) / 100)) - this.invoices_list[index].options[option_index].result
            }
            else {
                tax = Math.floor((this.invoices_list[index].options[option_index].result * (this.invoices_list[index].tax === 8 ? 108 : 110) / 100)) - this.invoices_list[index].options[option_index].result
            }
            this.invoices_list[index].options[option_index].result_tax = this.invoices_list[index].pause_status === 1 ? 0 : Math.ceil(tax)
            //合計金額などを再計算
            this.result_1 = 0
            this.result_2 = 0
            this.invoices_list.filter(item => {
                this.result_1 += item.result
                this.result_2 += item.result_tax
                if (item.options !== null) {
                    item.options.filter(option => {
                        this.result_1 += option.result
                        this.result_2 += option.result_tax
                    })
                }
            })
            this.result_3 = this.result_1 + this.result_2
			return input_result
		},
	},
    computed: {
        ...mapState({
            registerParams: state => state.invoices.registerParams,
            invoicesRegisterList: state => state.invoices.invoicesRegisterList,
        })
    }
}
</script>

<style scoped src="../assets/css/invoices.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped src="../assets/css/preview.css"></style>
<style scoped>
.money-table table {
    margin: auto;
    border-collapse: collapse;
}
.money-table th {
    width: 170px;
    white-space: nowrap;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #A3A6B4;
    background-color: #F5F6FA;
    text-align: center;
    padding: 5px 0;
    border: 1px solid #BCBCCB;
    border-bottom: none;
}
.money-table td {
    width: 160px;
    white-space: nowrap;
    text-align: right;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #4D4F5C;
    background-color: #FFFFFF;
    padding: 10px 10px 10px 0;
    border: 1px solid #BCBCCB;
    border-top: none;
}
.contents-scroll-table td {
    height: 40px;
}
.contents-scroll-table .stop {
	background-color: #F70202;
	white-space: nowrap;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #fff;
	text-align: center;
	padding: 8px 35px;
	border-radius: 5px;
}
.option td {
	background-color: #FF6565;
    color: #FFFFFF;
}
.option td input {
    width: 20px;
	background-color: #FFFFFF;
    text-overflow: initial;
}
.slip-regist-step2-table th:first-child,
.contents-scroll-table td:first-child {
    width: auto;
}
</style>