<template>
	<div>
		<div id="main-text">
			<span>伝票登録（特別）</span>
		</div>
		<div id="main-contents" style="height: auto;">
			<div class="step-contents">
				<div class="step">
					<div class="step-circle" onclick="javascript:history.back()">
						<span><img src="../assets/img/check.png"></span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" onclick="javascript:history.back()">
						Step 1
					</div>
					<div class="step-word" style="font-weight: bold;">
						Step 2
					</div>
				</div>
			</div>
			<div class="register-step2-contents" style="width: calc(100% - 460px); margin: 40px auto;">
                <div class="slip-register-step2-title">
                    {{ getItemCategoryNameById(registerParams.customer_id) }}
                </div>
                <div class="register-button">
                    <button @click="addRow()">
                        追加
                    </button>
                </div>
                <table class="list-table slip-regist-step2-table">
                    <tr class="list-table-header">
                        <th>No.</th>
                        <th>金額</th>
                        <th colspan="2">名称</th>
                        <th>数量</th>
                        <th>日付</th>
                        <th colspan="2">備考</th>
                        <th></th>
                    </tr>
                </table>
                <div class="table-scroller">
                    <table class="contents-scroll-table">
                        <template v-for="(item, index) in params">
                            <tr class="list-table-row" style="cursor: default;" :key="'row_' + index">
                                <td>{{ index + 1 }}</td>
                                <td>
                                    <input type="number" @input="item.price = inputNumberMaxLength(item.price, 9)" v-model="item.price">
                                </td>
                                <td colspan="2">
                                    <input type="text" maxlength="64" v-model="item.special_name">
                                </td>
                                <td>
                                    <input type="number" @input="item.count = inputNumberMaxLength(item.count, 9)" v-model="item.count">
                                </td>
                                <td>
                                    {{ registerParams.period }}
                                </td>
                                <td colspan="2">
                                    <input type="text" maxlength="200" v-model="item.note">
                                </td>
                                <img :style="{'opacity': params.length === 1 ? 0 : 1}" class="table-close-button" src="../assets/img/delete.png" @click="deleteRow(index)">
                            </tr>
                        </template>
                    </table>
                </div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-prev" onclick="javascript:history.back()">戻る</button>
            <button class="button-next" @click="register()">登録</button>
		</div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        入力に不備があります。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { inputNumberMaxLength } from '../util'
export default {
	data() {
		return {
            params: [
                {
                    price: null,
                    count: null,
                    special_name: null,
                    note: null,
                }
            ],
            is_modal: false,
		}
	},
	created() {

	},
	mounted() {

	},
	methods: {
        register() {
            let params = {
                slip: this.params,
                ...this.registerParams,
            }

            // 入力チェック
            let error = []
            params.slip.filter(item => {
                if (item.price === null && item.special_name === null && item.count === null && item.start_date === null) {
                    error.push(true)
                }
                else {
                    item.start_date = this.registerParams.period
                }
            })

            if (error.length === 0) {
                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('slip/postSlipSpecialApi', params))
                    .then(() => {
                        this.$store.commit('slip/setRegisterParams', null)
                        this.$store.dispatch('loading/endLoad')
                        this.$router.push('slip')
                    })
            }
            else {
                this.is_modal = true
            }
        },
        addRow() {
            this.params.push({
                price: null,
                count: null,
                start_date: null,
                special_name: null,
                note: null,
            })
        },
        deleteRow(index) {
            this.params.splice(index, 1)
        },
        // 取引先IDから取引先名を取得する
		getItemCategoryNameById(id) {
			let result = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
        inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
	},
    computed: {
        ...mapState({
            registerParams: state => state.slip.registerParams,
            customerMasterList: state => state.customerMaster.customerMasterList,
        })
    }
}
</script>

<style scoped src="../assets/css/slip.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped>
.list-table-row td {
    text-overflow: initial;
}
.contents-scroll-table input {
    width: calc(100% - 20px);
}
</style>