import Vue from 'vue'
import Router from "vue-router";
import Login from "./components/Login.vue"
import Calendar from "./components/Calendar.vue"
import CustomerMaster from "./components/CustomerMaster.vue"
import CustomerMasterRegist from "./components/CustomerMasterRegist.vue"
import CustomerMasterUpdate from "./components/CustomerMasterUpdate.vue"
import Invoices from "./components/Invoices.vue"
import InvoicesDetail from "./components/InvoicesDetail.vue"
import InvoicesRegistStep1 from "./components/InvoicesRegistStep1.vue"
import InvoicesRegistStep2 from "./components/InvoicesRegistStep2.vue"
import ItemCategoryMaster from "./components/ItemCategoryMaster.vue"
import ItemCategoryMasterRegist from "./components/ItemCategoryMasterRegist.vue"
import ItemCategoryMasterUpdate from "./components/ItemCategoryMasterUpdate.vue"
import ItemMaster from "./components/ItemMaster.vue"
import ItemMasterRegist from "./components/ItemMasterRegist.vue"
import ItemMasterUpdate from "./components/ItemMasterUpdate.vue"
import LocationMaster from "./components/LocationMaster.vue"
import LocationMasterRegist from "./components/LocationMasterRegist.vue"
import LocationMasterUpdate from "./components/LocationMasterUpdate.vue"
import SleepRegistStep1 from "./components/SleepRegistStep1.vue"
import SleepRegistStep2 from "./components/SleepRegistStep2.vue"
import Slip from "./components/Slip.vue"
import SlipRegistStep1 from "./components/SlipRegistStep1.vue"
import SlipRegistStep2Out from "./components/SlipRegistStep2Out.vue"
import SlipRegistStep2In from "./components/SlipRegistStep2In.vue"
import SlipRegistStep2Special from "./components/SlipRegistStep2Special.vue"
import SlipRegistStep2Group from "./components/SlipRegistStep2Group.vue"
import SlipUpdateStep1 from "./components/SlipUpdateStep1.vue"
import SlipUpdateStep2Out from "./components/SlipUpdateStep2Out.vue"
import SlipUpdateStep2In from "./components/SlipUpdateStep2In.vue"
import SlipUpdateStep2Special from "./components/SlipUpdateStep2Special.vue"
import SlipUpdateStep2Group from "./components/SlipUpdateStep2Group.vue"
import Stock from "./components/Stock.vue"
import StockRegist from "./components/StockRegist.vue"
import StockHistory from "./components/StockHistory.vue"
import StockHistoryDetail from "./components/StockHistoryDetail.vue"
import StockUpdate from "./components/StockUpdate.vue"

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "",
      component: Login
    },
    {
      path: "/calendar",
      name: "calendar",
      component: Calendar
    },
    {
      path: "/customer_master",
      name: "customer_master",
      component: CustomerMaster
    },
    {
      path: "/customer_master_regist",
      name: "customer_master_regist",
      component: CustomerMasterRegist
    },
    {
      path: "/customer_master_update",
      name: "customer_master_update",
      component: CustomerMasterUpdate
    },
    {
      path: "/invoices",
      name: "invoices",
      component: Invoices
    },
    {
      path: "/invoices_detail",
      name: "invoices_detail",
      component: InvoicesDetail
    },
    {
      path: "/invoices_regist_step1",
      name: "invoices_regist_step1",
      component: InvoicesRegistStep1
    },
    {
      path: "/invoices_regist_step2",
      name: "invoices_regist_step2",
      component: InvoicesRegistStep2
    },
    {
      path: "/item_category_master",
      name: "item_category_master",
      component: ItemCategoryMaster
    },
    {
      path: "/item_category_master_regist",
      name: "item_category_master_regist",
      component: ItemCategoryMasterRegist
    },
    {
      path: "/item_category_master_update",
      name: "item_category_master_update",
      component: ItemCategoryMasterUpdate
    },
    {
      path: "/item_master",
      name: "item_master",
      component: ItemMaster
    },
    {
      path: "/item_master_regist",
      name: "item_master_regist",
      component: ItemMasterRegist
    },
    {
      path: "/item_master_update",
      name: "item_master_update",
      component: ItemMasterUpdate
    },
    {
      path: "/location_master",
      name: "location_master",
      component: LocationMaster
    },
    {
      path: "/location_master_regist",
      name: "location_master_regist",
      component: LocationMasterRegist
    },
    {
      path: "/location_master_update",
      name: "location_master_update",
      component: LocationMasterUpdate
    },
    {
      path: "/sleep_regist_step1",
      name: "sleep_regist_step1",
      component: SleepRegistStep1
    },
    {
      path: "/sleep_regist_step2",
      name: "sleep_regist_step2",
      component: SleepRegistStep2
    },
    {
      path: "/slip",
      name: "slip",
      component: Slip
    },
    {
      path: "/slip_regist_step1",
      name: "slip_regist_step1",
      component: SlipRegistStep1
    },
    {
      path: "/slip_regist_step2_out",
      name: "slip_regist_step2_out",
      component: SlipRegistStep2Out
    },
    {
      path: "/slip_regist_step2_in",
      name: "slip_regist_step2_in",
      component: SlipRegistStep2In
    },
    {
      path: "/slip_regist_step2_special",
      name: "slip_regist_step2_special",
      component: SlipRegistStep2Special
    },
    {
      path: "/slip_regist_step2_group",
      name: "slip_regist_step2_group",
      component: SlipRegistStep2Group
    },
    {
      path: "/slip_update_step1",
      name: "slip_update_step1",
      component: SlipUpdateStep1
    },
    {
      path: "/slip_update_step2_out",
      name: "slip_update_step2_out",
      component: SlipUpdateStep2Out
    },
    {
      path: "/slip_update_step2_in",
      name: "slip_update_step2_in",
      component: SlipUpdateStep2In
    },
    {
      path: "/slip_update_step2_special",
      name: "slip_update_step2_special",
      component: SlipUpdateStep2Special
    },
    {
      path: "/slip_update_step2_group",
      name: "slip_update_step2_group",
      component: SlipUpdateStep2Group
    },
    {
      path: "/stock",
      name: "stock",
      component: Stock
    },
    {
      path: "/stock_regist",
      name: "stock_regist",
      component: StockRegist
    },
    {
      path: "/stock_history",
      name: "stock_history",
      component: StockHistory
    },
    {
      path: "/stock_history_detail",
      name: "stock_history_detail",
      component: StockHistoryDetail
    },
    {
      path: "/stock_update",
      name: "stock_update",
      component: StockUpdate
    },
  ]
});