export const error_codes = {
    // ログイン失敗
    LOGIN_ERROR: 1000010,

    // 在庫ステータスに貸出中があった場合
    STOCK_DELETE_ERROR: 1000020,

    // 在庫ステータスに販売済みがあった場合
    STOCK_DELETE_STATUS_5_ERROR: 1000021,

    // データがないのにファイルDL系処理を行った場合
    FILE_DL_ERROR: 1000030,

    // 出庫日より前に入庫日が入力された場合
    SLIP_IN_ERROR: 1000040,

    // グループ伝票で同じ事業所を選択エラー
    SLIP_GROUP_ERROR: 1000050,

    // 伝票削除エラー
    SLIP_DELETE_ERROR: 1000060,

    // ユーザ権限エラー
    AUTH_ERROR: 1000070,
}

const state = {
    apiStatus: null,
    errorCode: null,
    errorMessage: null,
}

const getters = {
    apiStatus: state => {
        return state.apiStatus
    },
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setErrorCode(state, code) {
        state.errorCode = code
    },
    setErrorMessage(state, message) {
        state.errorMessage = message
    }
}

export default {
	namespaced: true,
	state,
    getters,
	mutations,
}