<template>
	<div>
		<div id="main-text">
			<span>在庫登録</span>
		</div>
		<div id="main-contents" style="height: auto">
			<div class="register-contents" style="margin: 100px auto 0px auto;">
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							仕入れ日
						</div>
						<div class="register-datepicker" ref="date1">
							<datepicker ref="datepicker" :language="language" format="yyyy年MM月dd日" placeholder="請求日" v-model="purchase_date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date2" @click="showCalendar('datepicker')">
					</div>
					<div class="register-item"></div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							製品カテゴリ
						</div>
						<div class="input readonly">
							<input type="text" readonly v-model="category_name">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							商品
						</div>
						<div class="input">
							<v-select :options="options_item" :reduce="options_item => options_item.id" v-model="name">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							型番
						</div>
						<div class="input readonly">
							<input type="text" readonly v-model="params.model_number">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							仕入れ元
						</div>
						<div class="input">
							<input type="text" placeholder="仕入れ元" maxlength="64" v-model="params.importer">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							仕入れ単価
						</div>
						<div class="input">
							<input type="number" placeholder="仕入れ単価" @input="params.purchase_price = inputNumberMaxLength(params.purchase_price, 9)" v-model="params.purchase_price">
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							数量
						</div>
						<div class="input">
							<input type="number" placeholder="数量" @input="params.count = inputNumberMaxLength(params.count, 9)" v-model="params.count">
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							事業所
						</div>
						<div class="input">
							<v-select :options="options_office" :reduce="options_office => options_office.id" v-model="params.office_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							入力者
						</div>
						<div class="input readonly">
							<input type="text" readonly :value="user_name">
						</div>
					</div>
				</div>
				<div class="update-text" style="width: calc(100% - 240px);">
                    <div class="update-text-title">備考</div>
                    <textarea v-model="params.note" maxlength="200"></textarea>
                </div>
			</div>
		</div>
		<div class="buttons">
			<button class="button-next" @click="register()">登録</button>
		</div>
		<div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        以下の項目が入力されていません。<br>
						<span v-for="(item, index) in error_params" :key="'error_' + index">
							・{{item}}<br>
						</span>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
		<div id="modal" v-show="is_category_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_category_error = false">
                    <div id="modal-text">
                        選択した商品に該当するカテゴリが存在しませんでした。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_category_error = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex';
import moment from 'moment';
import { inputNumberMaxLength } from '../util';
export default {
    components: {
        Datepicker,
		vSelect,
    },
	data() {
		return {
			params: {
				item_id: null,
				item_category_id: null, // 必須
				office_id: null, // 必須
				user_id: 0, // ログイン時に取得したIDをセットする
				name: null, // 必須
				model_number: null, // 必須
				count: null, // 必須
				purchase_date: null, // 必須
				importer: null, // 必須
				purchase_price: null,
				note: null,
				status: 1,
			},
			required_list: { // 入力必須リスト
				'item_category_id': "製品カテゴリ",
				'office_id': "事業所",
				'name': "商品名",
				'model_number': "型番",
				'count': "数量",
				'purchase_date': "仕入れ日",
				'importer': "仕入れ元"
			},
			purchase_date: null,
			name: null,
			item_category_id: 0,
			options_category: [],
			options_item: [],
			options_office: [],
			is_modal: false,
			error_params: [],
			user_name: "",
			eventHandler: false,
			category_name: "",
			is_category_error: false,
		}
	},
	created() {
		this.params.user_id = this.userData.id
		this.user_name = this.userData.name
		this.getItemMasterList()
		this.getOfficeList()
	},
	mounted() {
		window.addEventListener('click', this.eventHandler = (event) => {
			if (!this.$refs.date1.contains(event.target) && !this.$refs.date2.contains(event.target)) {
				document.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
					}
				})
			}
		})
	},
	methods: {
		register() {
			this.error_params = []
			// 入力チェック
			Object.keys(this.params).filter(key => {
				Object.keys(this.required_list).filter(required_key => {
					if (required_key === key && (this.params[key] === null || this.params[key] === "")) {
						this.error_params.push(this.required_list[required_key])
					}
				})
			})
			if (this.error_params.length === 0) {
				this.$store.dispatch('loading/startLoad')
					.then(() => this.$store.dispatch('stock/postStockApi', this.params))
					.then(() => {
						this.$store.dispatch('loading/endLoad')
						this.$router.push('stock')
					})
			}
			else {
				this.is_modal = true
			}
		},
		// 検索用商品カテゴリ取得
		getItemCategoryMasterList(id) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi', { id: id }))
                .then(() => {
					if (this.itemCategoryMasterList.category !== undefined && this.itemCategoryMasterList.category[0] !== undefined) {
						this.category_name = this.itemCategoryMasterList.category[0].name
						this.params.item_category_id = this.itemCategoryMasterList.category[0].id
					}
					// 該当のカテゴリがなかった場合
					else {
						this.category_name = ""
						this.params.item_category_id = null
						this.is_category_error = true
					}
					this.$store.dispatch('loading/endLoad')
					// this.itemCategoryMasterList.category.filter(item => {
					// 	if (item.name !== "") {
					// 		this.options_category.push({label: item.name, id: item.id})
					// 	}
					// })
				})
		},
		// 検索用商品マスタ取得
		getItemMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemMaster/getItemMasterApi', {}))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.itemMasterList.item.filter(item => {
						if (item.name !== "") {
							this.options_item.push({label: item.name, id: item.id})
						}
					})
				})
		},
		// 検索用事業所一覧取得
		getOfficeList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.officeMasterList.office.filter(item => {
						if (item.name !== "") {
							this.options_office.push({label: item.name, id: item.id})
						}
					})
				})
		},
		inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
		},
	},
    computed: {
        language(){
            return ja;
        },
		...mapState({
			userData: state => state.login.userData,
			stockApiStatus: state => state.stock.apiStatus,
			stockList: state => state.stock.stockList,
			itemMasterApiStatus: state => state.itemMaster.apiStatus,
			itemMasterList: state => state.itemMaster.itemMasterList,
			itemCategoryMasterApiStatus: state => state.itemCategoryMaster.apiStatus,
			itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
			officeMasterApiStatus: state => state.officeMaster.apiStatus,
			officeMasterList: state => state.officeMaster.officeMasterList,
		})
    },
	watch: {
		// 請求日
		purchase_date(value) {
			this.params.purchase_date = moment(value).format('YYYY-MM-DD')
			this.$refs.datepicker.showCalendar()
			this.$refs.datepicker.showCalendar()
		},
		// 製品カテゴリー
		// item_category_id(value) {
		// 	this.params.item_category_id = value
		// 	this.getItemMasterList()
		// },
		// 商品名
		name(value) {
			this.params.item_id = value
			this.itemMasterList.item.filter(item => {
				if (item.id === value) {
					this.params.name = item.name
					this.params.model_number = item.model_number
					this.getItemCategoryMasterList(item.item_category_id)
				}
			})
		}
	},
	destroyed() {
		window.removeEventListener('click', this.eventHandler)
	}
}
</script>

<style scoped src="../assets/css/stock.css"></style>
<style scoped src="../assets/css/modal.css"></style>