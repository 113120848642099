<template>
	<div>
		<div id="main-text">
			<span>在庫登録履歴一覧</span>
		</div>
		<div id="main-contents" style="height: auto">
			<div class="register-contents" style="height: 60vh;">
				<div id="list-contents" style="width: auto;">
					<table class="list-table">
						<tr class="list-table-header">
							<th>登録日</th>
							<th>製品名</th>
							<th>事業所名</th>
							<th>登録者</th>
							<th>管理番号</th>
						</tr>
					</table>
					<div class="list-table-scroller" style="height: 90%;">
						<table class="list-table-contents">
							<template v-if="stockHistoryDetail !== null">
								<template v-for="(item, index) in stockHistoryDetail">
									<tr class="list-table-row" :key="'item_master_' + index">
										<td>
											<span v-if="item.create_date === null || item.create_date === ''" v-tooltip="'-'">-</span>
											<span v-else v-tooltip="item.create_date">{{ item.create_date }}</span>
										</td>
										<td>
											<span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
											<span v-else v-tooltip="item.name">{{ item.name }}</span>
										</td>
										<td>
											<span v-if="item.office_id === null || item.office_id === ''" v-tooltip="'-'">-</span>
											<span v-else v-tooltip="getOfficeNameById(item.office_id)">{{ getOfficeNameById(item.office_id) }}</span>
										</td>
										<td>
											<span v-if="item.user_id === null || item.user_id === ''" v-tooltip="'-'">-</span>
											<span v-else v-tooltip="getUserNameById(item.user_id)">{{ getUserNameById(item.user_id) }}</span>
										</td>
										<td>
											<span v-if="item.control_number === null || item.control_number === ''" v-tooltip="'-'">-</span>
											<span v-else v-tooltip="item.control_number">{{ item.control_number }}</span>
										</td>
									</tr>
								</template>
							</template>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
			<button class="button-cancel" @click="is_modal = true">取消</button>
		</div>
		<div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        取消処理を行うと、元に戻すことは出来ません。
                        本当に取消処理を行って宜しいですか？
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="is_modal = false">いいえ</button>
                    <button id="yes" @click="deleteStockHistoryDetail()">はい</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
            is_modal: false,
			flags: {
				customer: false,
				office: false,
				user: false,
			}
		}
	},
	created() {
		this.getStockHistoryDetailList()
	},
	mounted() {

	},
	methods: {
		// 在庫履歴一覧取得
		async getStockHistoryDetailList() {
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('stock/getStockHistoryDetailApi', {id: this.$route.query.id}))
				.then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 取引先マスタ取得
		async getCustomerMaster() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('customerMaster/getCustomerMasterApi', { name: null }))
				.then(() => {
					this.flags.customer = true
					if (this.flags.customer && this.flags.office && this.flags.user) {
						this.getStockHistoryDetailList()
					}
				})
		},
		// 事業所マスタ取得
		async getOfficeMaster() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
				.then(() => {
					this.flags.office = true
					if (this.flags.customer && this.flags.office && this.flags.user) {
						this.getStockHistoryDetailList()
					}
				})
		},
		// ユーザー一覧取得
		async getUserList() {
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('userList/getUserListApi', {}))
				.then(() => {
					this.flags.user = true
					if (this.flags.customer && this.flags.office && this.flags.user) {
						this.getStockHistoryDetailList()
					}
				})
		},
		// 取引先IDから取引先名を取得する
		getCustomerNameById(id) {
			let result = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
		// ユーザーIDからユーザー名を取得する
		getUserNameById(id) {
			let result = ""
			this.userList.user.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		// 事業所IDから事業所名を取得する
		getOfficeNameById(id) {
			let result = ""
			this.officeMasterList.office.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		deleteStockHistoryDetail() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('stock/deleteStockApi', {id: this.$route.query.id, type: 2}))
				.then(() => {
					this.$store.dispatch('loading/endLoad')
					this.$router.push('stock_history')
				})
		},
	},
	computed: {
		...mapState({
			stockApiStatus: state => state.stock.apiStatus,
			stockHistoryDetail: state => state.stock.stockHistoryDetail,
			customerMasterApiStatus: state => state.customerMaster.apiStatus,
			customerMasterList: state => state.customerMaster.customerMasterList,
			officeMasterApiStatus: state => state.officeMaster.apiStatus,
			officeMasterList: state => state.officeMaster.officeMasterList,
			userList: state => state.userList.userList,
		})
    },
}
</script>

<style scoped src="../assets/css/stock.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped>
.contents-header {
	padding: 0;
}
.date,
.product-name,
.location,
.name,
.number {
	display: inline-block;
	width: 170px;
	padding-right: 10px;
}
.date {
	margin-left: 20px;
}
.product-name {
	width: 350px;
}
.row {
	cursor: default;
}
.register-contents {
	width: calc(100% - 200px);
	margin: auto;
}
</style>