<template>
	<div>
		<div id="main-text">
			<span>請求一覧</span>
		</div>
		<template v-if="userData.auth === USER_TYPE.admin">
			<button class="export" @click="getInvoicesListCsv()">
				エクスポート
			</button>
			<button class="export" @click="getInvoiceEarningsCsv()">
				売上エクスポート
			</button>
		</template>
		<div id="main-contents">
			<div id="list-contents">
				<table class="list-table">
					<tr class="list-table-header">
						<th>請求日</th>
						<th>取引先名</th>
						<th>対象期間</th>
						<th>請求額（税抜）</th>
						<th>請求額（税込）</th>
						<th>入金</th>
					</tr>
				</table>
				<div class="list-table-scroller">
					<table class="list-table-contents">
						<template v-if="invoicesList !== null">
							<template v-for="(item, index) in invoicesList.list">
								<tr class="list-table-row" :key="'item_master_' + index" @click="toDetail(item)">
									<td>
										<span v-if="item.invoices_date === null || item.invoices_date === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.invoices_date">{{ item.invoices_date }}</span>
									</td>
									<td>
										<span v-if="item.customer_id === null || item.customer_id === ''" v-tooltip="'-'">-</span>
										<template v-else>
											<span v-if="customerMasterApiStatus" v-tooltip="getCustomerNameById(item.customer_id)">{{ getCustomerNameById(item.customer_id) }}</span>
										</template>
									</td>
									<td>
										<span v-if="item.start_date === null || item.start_date === '' || item.end_date === null || item.end_date === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.start_date + ' ~ ' + item.end_date">{{ item.start_date + ' ~ ' + item.end_date }}</span>
									</td>
									<td>
										<span v-if="item.untax_price === null || item.untax_price === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="'￥' + item.untax_price">{{ '￥' + item.untax_price }}</span>
									</td>
									<td>
										<span v-if="item.intax_price === null || item.intax_price === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="'￥' + item.intax_price">{{ '￥' + item.intax_price }}</span>
									</td>
									<td>
										<button v-if="item.payment_flag === 2" class="table-button-disable" @click.stop="invoices(item.id)">入金済み</button>
										<button v-else class="table-button" @click.stop="invoices(item.id)">入金処理</button>
									</td>
								</tr>
							</template>
						</template>
					</table>
				</div>
				<div class="pager" v-if="page_num !== 0">
					<template v-if="current_page !== 1">
						<span class="page" @click="pager(current_page - 1)">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-if="page_num < 5">
						<template v-for="page in page_num">
							<span :class="{'page': true, 'current': current_page === page}" :key="'page_' + page" @click="pager(page)">
								<span>{{ page }}</span>
							</span>
						</template>
					</template>
					<template v-else>
						<template v-if="current_page < 4">
							<span :class="{'page': true, 'current': current_page === 1}" @click="pager(1)">
								<span>{{ 1 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 2}" @click="pager(2)">
								<span>{{ 2 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 3}" @click="pager(3)">
								<span>{{ 3 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 4}" @click="pager(4)">
								<span>{{ 4 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 5}" @click="pager(5)">
								<span>{{ 5 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 2) <= page_num">
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
							<span class="page" @click="pager(current_page + 2)">
								<span>{{ current_page + 2 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 1) <= page_num">
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
						</template>
						<template v-else-if="current_page === page_num">
							<span class="page" @click="pager(current_page - 4)">
								<span>{{ current_page - 4 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
						</template>
					</template>
					<template v-if="current_page !== page_num">
						<span class="page" @click="pager(current_page + 1)">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
				</div>
			</div>
			<div class="contents-filter">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							請求日
						</div>
						<div class="datepicker" ref="date1_1">
                            <datepicker clear-button ref="datepicker" :language="language" format="yyyy年MM月dd日" placeholder="請求日" v-model="invoices_date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date1_2" @click="showCalendar('datepicker')">
					</div>
					<div class="filter">
						<div class="title">
							取引先
						</div>
						<div class="input">
							<v-select :options="options_customer" :reduce="options_customer => options_customer.id" v-model="filter_params.customer_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							対象期間（FROM）
						</div>
						<div class="datepicker" ref="date2_1">
                            <datepicker clear-button ref="datepicker2" :language="language" format="yyyy年MM月dd日" placeholder="対象期間（FROM）" v-model="start_date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date2_2" @click="showCalendar('datepicker2')">
					</div>
					<div class="filter">
						<div class="title">
							対象期間（TO）
						</div>
						<div class="datepicker" ref="date3_1">
                            <datepicker clear-button ref="datepicker3" :language="language" format="yyyy年MM月dd日" placeholder="対象期間（TO）" v-model="end_date"></datepicker>
						</div>
						<img src="../assets/img/date.png" ref="date3_2" @click="showCalendar('datepicker3')">
					</div>
					<div class="filter" v-if="is_office">
						<div class="title">
							担当事業所
						</div>
						<div class="input">
                            <v-select :options="options_office" :reduce="options_office => options_office.id" v-model="filter_params.office_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							入金状況
						</div>
						<div>
							<div class="radio">
								<label class="radio-label" for="radio0">
									<input type="radio" name="rental" id="radio0" :value="null" v-model="filter_params.status">指定無
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio1">
									<input type="radio" name="rental" id="radio1" :value="2" v-model="filter_params.status">入金済
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio2">
									<input type="radio" name="rental" id="radio2" :value="1" v-model="filter_params.status">未入金
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<template v-if="userData.auth === USER_TYPE.admin">
			<div class="buttons">
				<router-link to="invoices_regist_step1">
					<button class="button-next">新規登録</button>
				</router-link>
			</div>
		</template>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { ja } from "vuejs-datepicker/dist/locale";
import { mapState } from 'vuex';
import moment from 'moment';
import { USER_TYPE } from '../util';
export default {
    components: {
        Datepicker,
		vSelect,
    },
	data() {
		return {
			USER_TYPE: USER_TYPE,
			filter_params: {
				invoices_date: null,
				customer_id: null,
				start_date: null,
				end_date: null,
				office_id: null,
				status: 0,
			},
			invoices_date: null,
			start_date: null,
			end_date: null,
			options_customer: [],
			options_office: [],
			page_num: 0,
			current_page: 1,
			page_start: 1,
			page_end: 1,
			flags: {
				customer: false,
				office: false,
			},
			eventHandler: null,
			is_restore: false,
			is_office: true,
		}
	},
	created() {
		// ユーザ情報のoffice_idがあるなら事業所を固定する
		if (this.userData.office_id !== null) {
			this.filter_params.office_id = this.userData.office_id
			this.is_office = false
		}

		if (this.$route.query.start_date !== undefined) {
			this.filter_params.start_date = this.$route.query.start_date
			this.start_date = this.$route.query.start_date
		}
		if (this.$route.query.end_date !== undefined) {
			this.filter_params.end_date = this.$route.query.end_date
			this.end_date = this.$route.query.end_date
		}
		// 関連ページからの復帰で検索条件を保持
		if (this.invoicesFilterParams !== null) {
			this.filter_params = this.invoicesFilterParams
			this.start_date = this.filter_params.start_date
			this.end_date = this.filter_params.end_date
			this.invoices_date = this.filter_params.invoices_date
		}
		// リロード時にページを保持するように
		let params = this.filter_params
		params.page = 1
		if (this.$route.query.page !== undefined) {
			this.current_page = Number(this.$route.query.page)
			params.page = this.current_page
		}
		this.getCustomerMasterList(params)
		this.getOfficeList(params)
	},
	mounted() {
		window.addEventListener('click', this.eventHandler = (event) => {
			if (!this.$refs.date1_1.contains(event.target) && !this.$refs.date1_2.contains(event.target)) {
				this.$refs.date1_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
					}
				})
			}
			if (!this.$refs.date2_1.contains(event.target) && !this.$refs.date2_2.contains(event.target)) {
				this.$refs.date2_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker2.showCalendar()
					}
				})
			}
			if (!this.$refs.date3_1.contains(event.target) && !this.$refs.date3_2.contains(event.target)) {
				this.$refs.date3_1.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker3.showCalendar()
					}
				})
			}
		})
	},
	methods: {
		toDetail(data) {
			if (this.userData.auth === USER_TYPE.admin) {
				if (data.old_flag !== undefined && data.old_flag) {
					this.$router.push('invoices_detail?id=' + data.id + '&date=' + data.invoices_date + '&customer_id=' + data.customer_id + '&old=true')
				}
				else {
					this.$router.push('invoices_detail?id=' + data.id + '&date=' + data.invoices_date + '&customer_id=' + data.customer_id + '&start_date=' + data.start_date + '&end_date=' + data.end_date)
				}
			}
		},
		// 請求一覧取得
		getInvoicesList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('invoices/getInvoicesApi', params))
                .then(() => {
					this.$store.dispatch('loading/endLoad')
					if (this.invoicesList !== null && this.invoicesList.page !== undefined) {
						this.page_num = this.invoicesList.page

						// 最大ページ数が最大表示数の5を上回っていた場合
						if (this.page_num > 5) {
							this.page_start = this.current_page - 2 < 0 ? 1 : this.current_page - 2
							this.page_end = this.page_end > this.current_page + 2 ? this.page_num : this.page_end > this.current_page + 2
						}
						else {
							this.page_start = 1
							this.page_end = this.page_num
						}
					}
				})
		},
		// 検索用取引先マスタ取得
		getCustomerMasterList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('customerMaster/getCustomerMasterApi', { name: null }))
                .then(() => {
					this.customerMasterList.list.filter(item => {
						if (item.floor !== "") {
							this.options_customer.push({label: item.floor, id: item.id})
						}
					})
					this.flags.customer = true
					if (this.flags.customer && this.flags.office) {
						if (this.$route.query.customer_id !== undefined) {
							this.filter_params.customer_id = Number(this.$route.query.customer_id)
						}
						this.getInvoicesList(params)
						this.is_restore = true
					}
				})
		},
		// 事業所一覧取得
		getOfficeList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
                .then(() => {
					this.officeMasterList.office.filter(item => {
						if (item.name !== "") {
							this.options_office.push({label: item.name, id: item.id})
						}
					})
					this.flags.office = true
					if (this.flags.customer && this.flags.office) {
						this.getInvoicesList(params)
						this.is_restore = true
					}
				})
		},
		// ページ切り替え
		pager(page) {
			this.$router.push('invoices?page=' + page)
			this.current_page = page
			let params = this.filter_params
			params.page = this.current_page
			this.getInvoicesList(params)
		},
		// 取引先IDから取引先名を取得する
		getCustomerNameById(id) {
			let result = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
		// 請求処理
		invoices(id) {
			if (this.userData.auth === USER_TYPE.admin) {
				this.$store.dispatch('loading/startLoad')
					.then(() => this.$store.dispatch('invoices/postUpdateInvoices', {id: id}))
					.then(() => {
						let params = this.filter_params
						params.page = this.current_page
						this.getInvoicesList(params)
					})
			}
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
			if (ref === 'datepicker3') {
				this.toEndScroll()
			}
		},
		toEndScroll() {
			setTimeout(() => {
				this.$nextTick(() => {
					const obj = document.getElementsByClassName('contents-filter')[0]
					obj.scrollTop = obj.scrollHeight
				})
			}, 10)
		},
		// 請求一覧CSV取得
		getInvoicesListCsv() {
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('invoices/getInvoicesCSVApi', this.filter_params))
				.then(() => this.$store.dispatch('loading/endLoad'))
		},
		// 売上取得
		getInvoiceEarningsCsv() {
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('invoices/getInvoicesEarningsCSVApi'))
				.then(() => this.$store.dispatch('loading/endLoad'))
		},
	},
    computed: {
        language(){
            return ja;
        },
		...mapState({
			invoicesList: state => state.invoices.invoicesList,
			invoicesFilterParams: state => state.invoices.filterParams,
			customerMasterList: state => state.customerMaster.customerMasterList,
			officeMasterList: state => state.officeMaster.officeMasterList,
			customerMasterApiStatus: state => state.customerMaster.apiStatus,
			userData: state => state.login.userData,
		})
    },
	watch: {
		// 検索結果取得
		filter_params: {
			handler(value) {
				let params = value
				params.page = 1
				this.current_page = 1
				this.page_num = 0
				this.getInvoicesList(params)
				this.$store.commit('invoices/setFilterParams', value)
			},
			deep : true,
		},
		start_date(value) {
			if (value === null) {
				this.filter_params.start_date = null
			}
			else {
				this.filter_params.start_date = moment(value).format('YYYY-MM-DD')
			}
			if (this.is_restore) {
				this.$refs.datepicker2.showCalendar()
				this.$refs.datepicker2.showCalendar()
			}
		},
		end_date(value) {
			if (value === null) {
				this.filter_params.end_date = null
			}
			else {
				this.filter_params.end_date = moment(value).format('YYYY-MM-DD')
			}
			if (this.is_restore) {
				this.$refs.datepicker3.showCalendar()
				this.$refs.datepicker3.showCalendar()
			}
		},
		invoices_date(value) {
			if (value === null) {
				this.filter_params.invoices_date = null
			}
			else {
				this.filter_params.invoices_date = moment(value).format('YYYY-MM-DD')
			}
			if (this.is_restore) {
				this.$refs.datepicker.showCalendar()
				this.$refs.datepicker.showCalendar()
			}
		},
	},
	destroyed() {
		window.removeEventListener('click', this.eventHandler)
	}
}
</script>

<style scoped src="../assets/css/invoices.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped>
.list-table-row td:last-child {
	text-overflow: initial;
}
</style>