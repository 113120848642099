import { saveAs } from 'file-saver'
import { getApi, OK, postApi, strArray } from '../util'
import Encoding from 'encoding-japanese'

const state = {
    apiStatus: null,
    customerMasterList: null,
    customerMasterDetail: null,
    filterParams: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setCustomerMasterList(state, data) {
        state.customerMasterList = data
    },
    setCustomerMasterDetail(state, data) {
        state.customerMasterDetail = data
    },
    setFilterParams(state, data) {
        state.filterParams = data
    },
}

const actions = {
    // カスタマーマスタ一覧取得
    async getCustomerMasterApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setCustomerMasterList', null)
        const response = await getApi('get_mst_customer_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setCustomerMasterList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // カスタマーマスタ作成、更新
    async postCustomerMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_mst_customer', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // カスタマーマスタ削除
    async deleteCustomerMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('delete_mst_customer', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // カスタマーマスタ詳細取得
    async getCustomerMasterDetailApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_mst_customer_detail', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setCustomerMasterDetail', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // カスタマーマスタCSV取得
    async getCustomerMasterCsvApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('import_customer_csv', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            let mineType = 'text/csv'
            const name = response.data.data.name
            const shiftJisCodeList = Encoding.convert(strArray(response.data.data.csv), 'sjis', 'unicode')
            const uInt8List = new Uint8Array(shiftJisCodeList)
            const blob = new Blob([uInt8List], { type: mineType })
            saveAs(blob, name)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}