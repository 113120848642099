import { getApi, OK, postApi } from '../util'

const state = {
    apiStatus: null,
    slipList: null,
    registerParams: null,
    updateParams: null,
    warehousingSlipList: null,
    filterParams: null,
    slipDetailBaseData: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setSlipList(state, data) {
        state.slipList = data
    },
    setRegisterParams(state, data) {
        state.registerParams = data
    },
    setUpdateParams(state, data) {
        state.updateParams = data
    },
    setWarehousingSlipList(state, data) {
        state.warehousingSlipList = data
    },
    setFilterParams(state, data) {
        state.filterParams = data
    },
    setBaseData(state, data) {
        state.slipDetailBaseData = data
    },
}

const actions = {
    // 伝票一覧取得
    async getSlipApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setSlipList', null)
        context.commit('setRegisterParams', null)
        context.commit('setUpdateParams', null)
        const response = await getApi('get_slip_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setSlipList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 入庫伝票一覧取得
    async getWarehousingSlipApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_warehousing_slip_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setWarehousingSlipList', response.data.data.list)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 伝票登録
    async postSlipApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_slip', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 伝票詳細取得
    async getSlipDetailApi(context, params) {
        context.commit('setApiStatus', null)
        let api = "get_slip_detail"
        if (params.type == 2) {
            api = "get_warehousing_slip_detail"
        }
        else if (params.type == 3) {
            api = "get_special_slip_detail"
        }
        else if (params.type == 4) {
            api = "get_group_slip_detail"
        }

        const response = await getApi(api, params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setUpdateParams', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 伝票登録入庫
    async postSlipWarehousingApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_warehousing_slip', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 伝票登録特別
    async postSlipSpecialApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_special_slip', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 伝票登録グループ間
    async postSlipGroupApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_group_slip', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 出庫削除
    async deleteAPi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('delete_slip', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 入庫削除
    async deleteInAPi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('delete_warehousing_slip', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}