import { getApi, OK } from '../util'

const state = {
    apiStatus: null,
    userList: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setUserList(state, data) {
        state.userList = data
    },
}

const actions = {
    // ユーザ一覧取得
    async getUserListApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setUserList', null)
        const response = await getApi('get_user_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setUserList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}