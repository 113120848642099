<template>
	<div>
		<div id="main-text">
			<span>伝票一覧</span>
		</div>
		<div id="main-contents">
			<div id="list-contents">
				<table class="list-table">
					<tr class="list-table-header">
						<th colspan="2">管理番号</th>
						<th>タイプ</th>
						<th colspan="2">取引先名</th>
						<th colspan="2">設置現場</th>
						<th>事業所名</th>
						<th colspan="2">登録日</th>
					</tr>
				</table>
				<div class="list-table-scroller">
					<table class="list-table-contents">
						<template v-if="slipList !== null">
							<template v-for="(item, index) in slipList.list">
								<tr class="list-table-row" :key="'item_master_' + index" @click="toUpdate(item.id, item.type, item.customer_id, item.location_id)">
									<td colspan="2">
										<span v-if="item.id === null || item.id === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.id">{{ item.id }}</span>
									</td>
									<td>
										<span v-if="item.type === null || item.type === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="SLIP_TYPE_LABEL[item.type]">{{ SLIP_TYPE_LABEL[item.type] }}</span>
									</td>
									<td colspan="2">
										<span v-if="item.customer_id === null || item.customer_id === ''" v-tooltip="'-'">-</span>
										<template v-else>
											<span v-if="customerApiStatus" v-tooltip="getCustomerNameById(item.customer_id)">{{ getCustomerNameById(item.customer_id) }}</span>
										</template>
									</td>
									<td colspan="2">
										<span v-if="item.location_id === null || item.location_id === ''" v-tooltip="'-'">-</span>
										<template v-else>
											<span v-if="locationApiStatus" v-tooltip="getLocationNameById(item.location_id)">{{ getLocationNameById(item.location_id) }}</span>
										</template>
									</td>
									<td>
										<span v-if="item.office === null || item.office === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.office">{{ item.office }}</span>
									</td>
									<td colspan="2">
										<span v-if="item.created_at === null || item.created_at === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.created_at">{{ item.created_at }}</span>
									</td>
								</tr>
							</template>
						</template>
					</table>
				</div>
				<div class="pager" v-if="page_num !== 0">
					<template v-if="current_page !== 1">
						<span class="page" @click="pager(current_page - 1)">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-if="page_num < 5">
						<template v-for="page in page_num">
							<span :class="{'page': true, 'current': current_page === page}" :key="'page_' + page" @click="pager(page)">
								<span>{{ page }}</span>
							</span>
						</template>
					</template>
					<template v-else>
						<template v-if="current_page < 4">
							<span :class="{'page': true, 'current': current_page === 1}" @click="pager(1)">
								<span>{{ 1 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 2}" @click="pager(2)">
								<span>{{ 2 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 3}" @click="pager(3)">
								<span>{{ 3 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 4}" @click="pager(4)">
								<span>{{ 4 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 5}" @click="pager(5)">
								<span>{{ 5 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 2) <= page_num">
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
							<span class="page" @click="pager(current_page + 2)">
								<span>{{ current_page + 2 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 1) <= page_num">
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
						</template>
						<template v-else-if="current_page === page_num">
							<span class="page" @click="pager(current_page - 4)">
								<span>{{ current_page - 4 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
						</template>
					</template>
					<template v-if="current_page !== page_num">
						<span class="page" @click="pager(current_page + 1)">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
				</div>
			</div>
			<div class="contents-filter">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							管理番号
						</div>
						<div class="input">
							<input type="tel" placeholder="管理番号" @input="filter_params.control_number = inputNumberMaxLength(filter_params.control_number, 64)" v-model="filter_params.control_number">
						</div>
					</div>
					<div class="filter">
						<div class="title">
							伝票タイプ
						</div>
						<div class="input">
							<v-select :options="options_type" :reduce="options_type => options_type.id" v-model="filter_params.type">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							取引先名
						</div>
						<div class="input">
							<v-select :options="options_customer" :reduce="options_customer => options_customer.id" v-model="customer_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							設置現場
						</div>
						<div class="input">
							<v-select :options="options_location" :reduce="options_location => options_location.id" v-model="filter_params.location_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							事業所
						</div>
						<div class="input">
							<v-select :options="options_office" :reduce="options_office => options_office.id" v-model="filter_params.office_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="filter">
						<div class="title">
							登録日
						</div>
						<div class="datepicker" ref="date1">
                            <datepicker clear-button ref="datepicker" :language="language" format="yyyy年MM月dd日" placeholder="登録日" v-model="created_at"></datepicker>
						</div>
                        <img src="../assets/img/date.png" ref="date2" @click="showCalendar('datepicker')">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { mapState } from 'vuex';
import { inputNumberMaxLength, SLIP_TYPE_LABEL } from '../util';
import moment from 'moment';
export default {
    components: {
        Datepicker,
		vSelect,
    },
	data() {
		return {
			type_of_route: {
				'out': 'slip_update_step1_out',
				'in': 'slip_update_step1_in',
				'special': 'slip_update_step1_special',
				'group': 'slip_update_step1_group',
			},
			filter_params: {
				control_number: null,
				customer_id: null,
				type: null,
				office_id: null,
				location_id: null,
				created_at: null,
			},
			customer_id: null,
			created_at: null,
			options_customer: [],
			options_office: [],
			options_location: [],
			options_type: [
				{ label: '出庫', id: 1 },
				{ label: '入庫', id: 2 },
				{ label: '特別', id: 3 },
				{ label: 'グループ間', id: 4 }
			],
			page_num: 0,
			current_page: 1,
			page_start: 1,
			page_end: 1,
			flags: {
				customer: false,
				office: false,
				location: false,
			},
			SLIP_TYPE_LABEL: SLIP_TYPE_LABEL,
			eventHandler: false,
		}
	},
	created() {
		// 伝票更新用
		this.$store.commit('slip/setBaseData', null)

		// 関連ページからの復帰で検索条件を保持
		if (this.slipFilterParams !== null) {
			this.filter_params = this.slipFilterParams
			this.customer_id = this.filter_params.customer_id
		}
		// リロード時にページを保持するように
		let params = this.filter_params
		params.page = 1
		if (this.$route.query.page !== undefined) {
			this.current_page = Number(this.$route.query.page)
			params.page = this.current_page
		}
		this.getCustomerMasterList(params)
		this.getOfficeList(params)
		this.getLocationList(params)
	},
	mounted() {
		window.addEventListener('click', this.eventHandler = (event) => {
			if (!this.$refs.date1.contains(event.target) && !this.$refs.date2.contains(event.target)) {
				document.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
					}
				})
			}
		})
	},
	methods: {
		// 伝票一覧取得
		async getSlipList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('slip/getSlipApi', params))
                .then(() => {
					this.$store.dispatch('loading/endLoad')
					if (this.slipList.page !== undefined) {
						this.page_num = this.slipList.page

						// 最大ページ数が最大表示数の5を上回っていた場合
						if (this.page_num > 5) {
							this.page_start = this.current_page - 2 < 0 ? 1 : this.current_page - 2
							this.page_end = this.page_end > this.current_page + 2 ? this.page_num : this.page_end > this.current_page + 2
						}
						else {
							this.page_start = 1
							this.page_end = this.page_num
						}
					}
				})
		},
		// 検索用取引先マスタ取得
		getCustomerMasterList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('customerMaster/getCustomerMasterApi', { name: null }))
                .then(() => {
					this.customerMasterList.list.filter(item => {
						if (item.floor !== "") {
							this.options_customer.push({label: item.floor, id: item.id})
						}
					})
					this.flags.customer = true
					if (this.flags.customer && this.flags.office && this.flags.location) {
						if (params !== null) {
							this.getSlipList(params)
						}
						else {
							this.getSlipList(this.filter_params)
						}
					}
				})
		},
		// 検索用事業所一覧取得
		getOfficeList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
                .then(() => {
					this.officeMasterList.office.filter(item => {
						if (item.name !== "") {
							this.options_office.push(item.name)
						}
					})
					this.flags.office = true
					if (this.flags.customer && this.flags.office && this.flags.location) {
						if (params !== null) {
							this.getSlipList(params)
						}
						else {
							this.getSlipList(this.filter_params)
						}
					}
				})
		},
		// 検索用事業所一覧取得
		getLocationList(params = null) {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('locationMaster/getLocationMasterApi', {}))
                .then(() => {
					this.locationMasterList.location.filter(item => {
						if (item.name !== "") {
							this.options_location.push({label: item.name, id: item.id})
						}
					})
					this.flags.location = true
					if (this.flags.customer && this.flags.office && this.flags.location) {
						if (params !== null) {
							this.getSlipList(params)
						}
						else {
							this.getSlipList(this.filter_params)
						}
					}
				})
		},
		// 更新画面へ
		toUpdate(id, type, customer_id, location_id) {
			this.$router.push('slip_update_step1?id=' + id + '&type=' + type + '&customer_id=' + customer_id + '&location_id=' + location_id)
		},
		// ページ切り替え
		pager(page) {
			this.$router.push('slip?page=' + page)
			this.current_page = page
			let params = this.filter_params
			params.page = this.current_page
			this.getSlipList(params)
		},
		// 取引先IDから取引先名を取得する
		getCustomerNameById(id) {
			let result = "-"
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
		// 設置現場IDから設置現場名を取得する
		getLocationNameById(id) {
			let result = "-"
			this.locationMasterList.location.filter(item => {
				if (item.id === id) {
					result = item.name
				}
			})
			return result
		},
		inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
			this.toEndScroll()
		},
		toEndScroll() {
			setTimeout(() => {
				this.$nextTick(() => {
					const obj = document.getElementsByClassName('contents-filter')[0]
					obj.scrollTop = obj.scrollHeight
				})
			}, 10)
		},
		// 取引先指定で現場検索する
		getCustomerByLocationList() {
			let param = {}
			this.customerMasterList.list.filter(item => {
				if (item.id === this.customer_id) {
					param.customer_name = item.floor
				}
			})
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('locationMaster/getLocationMasterApi', param))
                .then(() => {
					this.options_location = []
					this.locationMasterList.location.filter(item => {
						if (item.name !== "") {
							this.options_location.push({label: item.name, id: item.id})
						}
					})
					this.$store.dispatch('loading/endLoad')
				})
		},
	},
	computed: {
        language(){
            return ja;
        },
		...mapState({
			slipList: state => state.slip.slipList,
			slipFilterParams: state => state.slip.filterParams,
			customerApiStatus: state => state.customerMaster.apiStatus,
			customerMasterList: state => state.customerMaster.customerMasterList,
			officeMasterList: state => state.officeMaster.officeMasterList,
			locationApiStatus: state => state.locationMaster.apiStatus,
			locationMasterList: state => state.locationMaster.locationMasterList,
		})
    },
	watch: {
		// 検索結果取得
		filter_params: {
			handler(value) {
				let params = value
				params.page = 1
				this.current_page = 1
				this.page_num = 0
				this.getSlipList(params)
				this.$store.commit('slip/setFilterParams', value)
			},
			deep : true,
		},
		customer_id(value) {
			this.filter_params.customer_id = value
			this.getCustomerByLocationList(value)
		},
		created_at(value) {
			if (value === null) {
				this.filter_params.created_at = null
			}
			else {
				this.filter_params.created_at = moment(value).format('YYYY-MM-DD')
			}
			// 選択後カレンダーが閉じないので２個設置
			this.$refs.datepicker.showCalendar()
			this.$refs.datepicker.showCalendar()
		}
	},
	destroyed() {
		window.removeEventListener('click', this.eventHandler)
	}
}
</script>

<style scoped src="../assets/css/slip.css"></style>
<style scoped src="../assets/css/list_table.css"></style>