<template>
	<div>
		<div id="main-text">
			<span>休止・休止解除</span>
		</div>
		<div id="main-contents">
			<div class="step-contents">
				<div class="step">
					<div class="step-circle">
						<span>1</span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" style="font-weight: bold;">
						Step 1
					</div>
					<div class="step-word">
						Step 2
					</div>
				</div>
			</div>
			<div class="register-contents" style="overflow: auto">
				<div class="register-items-contents">
					<div class="register-item">
						<div class="register-title">
							お客様名
						</div>
						<div class="input">
							<v-select :options="options_customer" :reduce="options_customer => options_customer.id" v-model="customer_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
					<div class="register-item">
						<div class="register-title">
							現場名
						</div>
						<div class="input">
							<v-select :options="options_location" :reduce="options_location => options_location.id" v-model="params.location_id">
								<template #no-options>データが存在しません</template>
							</v-select>
						</div>
					</div>
				</div>
				<div class="register-items-contents">
					<div class="register-item" style="display: inline-block;">
						<div class="register-title">
							指定日
						</div>
						<div class="register-datepicker" ref="date1">
							<datepicker ref="datepicker" :language="language" format="yyyy年MM月dd日" placeholder="指定日" v-model="date" />
						</div>
						<img src="../assets/img/date.png" ref="date2" @click="showCalendar('datepicker')">
					</div>
					<div class="register-item">
						<div class="register-title">
							処理
						</div>
						<div class="input" style="border: none">
							<div class="radio">
								<label class="radio-label" for="radio0">
									<input type="radio" name="rental" id="radio0" :value="1" v-model="params.type" checked>休止
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
							<div class="radio">
								<label class="radio-label" for="radio1">
									<input type="radio" name="rental" id="radio1" :value="2" v-model="params.type">休止解除
									<span class="outer"><span class="inner"></span></span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
			<button class="button-next" @click="toNext()">次へ</button>
		</div>
		<div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        以下の項目が入力されていません。<br>
						<span v-for="(item, index) in error_params" :key="'error_' + index">
							・{{item}}<br>
						</span>
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { ja } from "vuejs-datepicker/dist/locale";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { mapState } from 'vuex';
import moment from 'moment';
export default {
    components: {
        Datepicker,
		vSelect,
    },
	data() {
		return {
			params: {
				customer_id: null,
				location_id: null,
				type: 1,
				date: null,
			},
			required_list: { // 入力必須リスト
				'customer_id': "お客様名",
				'location_id': "現場名",
				'date': "請求日",
			},
			customer_id: null,
			date: null,
			options_customer: [],
			options_location: [],
			is_modal: false,
			error_params: [],
		}
	},
	created() {
		this.getCustomerMasterList()
	},
	mounted() {
		window.addEventListener('click', this.eventHandler = (event) => {
			if (!this.$refs.date1.contains(event.target) && !this.$refs.date2.contains(event.target)) {
				document.getElementsByClassName('vdp-datepicker__calendar').forEach(element => {
					// カレンダー展開状態かどうか
					if (element.style.display === '') {
						this.$refs.datepicker.showCalendar()
					}
				})
			}
		})
	},
	methods: {
		getCustomerMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('customerMaster/getCustomerMasterApi', { name: null }))
                .then(() => {
					this.customerMasterList.list.filter(item => {
						this.options_customer.push({label: item.floor, id: item.id})
					})
					if (this.registerParams !== null) {
						this.params = this.registerParams
						this.customer_id = this.params.customer_id
						this.date = this.params.date
					}
					this.$store.dispatch('loading/endLoad')
				})
		},
		getLocationList() {
			let customer_name = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === this.params.customer_id) {
					customer_name = item.floor
				}
			})
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('locationMaster/getLocationMasterApi', {customer_name: customer_name}))
                .then(() => {
					this.options_location = []
					this.locationMasterList.location.filter(item => {
						if (item.name !== "") {
							this.options_location.push({label: item.name, id: item.id})
						}
					})
					this.$store.dispatch('loading/endLoad')
				})
		},
		toNext() {
			this.error_params = []
			// 入力チェック
			Object.keys(this.params).filter(key => {
				Object.keys(this.required_list).filter(required_key => {
					if (required_key === key && (this.params[key] === null || this.params[key] === "")) {
						this.error_params.push(this.required_list[required_key])
					}
				})
			})
			if (this.error_params.length === 0) {
				this.$store.commit('sleep/setRegisterParams', this.params)
				this.$router.push('sleep_regist_step2')
			}
			else {
				this.is_modal = true
			}
		},
		showCalendar(ref) {
			this.$refs[ref].showCalendar();
		},
	},
    computed: {
        language(){
            return ja;
        },
		...mapState({
			registerParams: state => state.sleep.registerParams,
			customerMasterList: state => state.customerMaster.customerMasterList,
			locationMasterList: state => state.locationMaster.locationMasterList,
		})
    },
	watch: {
		customer_id(value) {
			this.params.customer_id = value
			this.params.location_id = null
			this.getLocationList()
		},
		date(value) {
			this.params.date = moment(value).format('YYYY-MM-DD')
			this.$refs.datepicker.showCalendar();
			this.$refs.datepicker.showCalendar();
		},
	},
	destroyed() {
		window.removeEventListener('click', this.eventHandler)
	}
}
</script>

<style scoped src="../assets/css/sleep.css"></style>