const state ={
    loading: false,
    isFull: false,
};

const getters ={
    loading: state => state.loading ? state.loading: '',
    isFull: state => state.isFull ? state.isFull: '',
};

const mutations ={
    setLoading(state,loading){
        state.loading = loading;
    },
    setIsFull(state, data) {
        state.isFull = data
    },
};
const actions = {
    async startLoad(context){
        context.commit('setLoading', true);
    },
    async endLoad(context){
        context.commit('setLoading', false);
    }
};

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}