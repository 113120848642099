<template>
	<div id="login">
		<div id="login-background">
			<img id="login-img" src="../assets/img/login.png">
		</div>
		<div id="text" class="text">
			<div class="text-contents">
				<div id="Logo">
					<span>リース管理システム</span>
				</div>
				<div id="copyright__all_rights_reserved">
					<span>copyright© 宏陽株式会社 all rights reserved.</span>
				</div>
				<div id="label">
					<div class="label">
						<input type="mail" placeholder="メールアドレス" v-model="params.email">
					</div>
					<div class="label">
						<input type="password" placeholder="パスワード" v-model="params.password">
					</div>
				</div>
				<div id="btn_login">
					<button @click="login()">
						<span id="login-text">ログイン</span>
					</button>
				</div>
			</div>
		</div>
		<div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        ログインに失敗しました。<br>
						メールアドレス、またはパスワードが間違っている可能性があります。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">はい</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { USER_TYPE } from '../util'
export default {
	data() {
		return {
			USER_TYPE: USER_TYPE,
			params: {
				email: null,
				password: null,
			},
			is_modal: false,
		}
	},
	created() {

	},
	mounted() {

	},
	methods: {
		login() {
			this.$store.commit('loading/setIsFull', true)
			this.$store.dispatch('loading/startLoad')
				.then(() => this.$store.dispatch('login/loginApi', this.params))
                .then(() => {
					if (this.isLoginError) {
						this.is_modal = true
					}
					else {
						localStorage.setItem('userData', JSON.stringify({
							id: this.userData.id,
							name: this.userData.name,
							auth: this.userData.auth,
							office_id: this.userData.office_id,
						}))
						if (this.userData.auth === USER_TYPE.admin) {
							this.$router.push('calendar')
						}
						else {
							this.$router.push('stock')
						}
					}
					this.$store.commit('loading/setIsFull', false)
					this.$store.dispatch('loading/endLoad')
				})
		}
	},
	computed: {
		...mapState({
			loginApiStatus: state => state.login.apiStatus,
			userData: state => state.login.userData,
			isLoginError: state => state.login.isLoginError,
		})
	}
}
</script>

<style scoped src="../assets/css/login.css"></style>
<style scoped src="../assets/css/modal.css"></style>