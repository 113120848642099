import { getApi, OK, postApi } from '../util'

const state = {
    apiStatus: null,
    sleepList: null,
    registerParams: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setSleepList(state, data) {
        state.sleepList = data
    },
    setRegisterParams(state, data) {
        state.registerParams = data
    },
}

const actions = {
    // 休止一覧取得
    async getSlipApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setSleepList', null)
        const response = await getApi('get_pause_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setSleepList', response.data.data.list)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 休止登録
    async postSlipApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_pause', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}