import { getApi, OK, postApi } from '../util'

const state = {
    apiStatus: null,
    itemCategoryMasterList: null,
    itemCategoryMasterDetail: null,
    filterParams: null,
}

const mutations = {
    setApiStatus(state, data) {
        state.apiStatus = data
    },
    setItemCategoryMasterList(state, data) {
        state.itemCategoryMasterList = data
    },
    setItemCategoryMasterDetail(state, data) {
        state.itemCategoryMasterDetail = data
    },
    setFilterParams(state, data) {
        state.filterParams = data
    },
}

const actions = {
    // 商品カテゴリマスタ一覧取得
    async getItemCategoryMasterApi(context, params) {
        context.commit('setApiStatus', null)
        context.commit('setItemCategoryMasterList', null)
        const response = await getApi('get_mst_item_category_list', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setItemCategoryMasterList', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品カテゴリマスタ詳細取得
    async getItemCategoryMasterDetailApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await getApi('get_mst_item_category_detail', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            context.commit('setItemCategoryMasterDetail', response.data.data)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品カテゴリマスタ作成、更新
    async postItemCategoryMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('send_mst_item_category', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
    // 商品カテゴリマスタ削除
    async deleteItemCategoryMasterApi(context, params) {
        context.commit('setApiStatus', null)
        const response = await postApi('delete_mst_item_category', params)
        if (response.data.code === OK) {
            context.commit('setApiStatus', true)
            return false
        }

        // 共通エラー処理
        context.commit('error/setApiStatus', true, { root: true })
        context.commit('error/setErrorCode', response.data.code, { root: true })
    },
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}