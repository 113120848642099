<template>
	<div>
		<div id="main-text">
			<span>伝票更新（グループ間移動）</span>
            <span class="step2-slip-no">No:{{ $route.query.id }}</span>
		</div>
		<div id="main-contents" style="height: auto;">
			<div class="step-contents">
				<div class="step">
					<div class="step-circle" onclick="javascript:history.back()">
						<span><img src="../assets/img/check.png"></span>
					</div>
					<span class="step-border"></span>
					<div class="step-circle">
						<span>2</span>
					</div>
				</div>
				<div class="step-child">
					<div class="step-word" onclick="javascript:history.back()">
						Step 1
					</div>
					<div class="step-word" style="font-weight: bold;">
						Step 2
					</div>
				</div>
			</div>
			<div class="register-step2-contents" style="width: calc(100% - 460px); margin: 40px auto;">
                <div class="slip-register-step2-title">
                    {{ getItemCategoryNameById(updateParams.customer_id) }}
                </div>
                <div class="register-button">
                    <button @click="addRow()">
                        追加
                    </button>
                </div>
                <table class="list-table slip-regist-step2-table">
                    <tr class="list-table-header">
                        <th>No.</th>
                        <th colspan="2">商品種別</th>
                        <th>管理番号</th>
                        <th>商品名</th>
                        <th>出庫事業所</th>
                        <th>入庫事業所</th>
                        <th></th>
                    </tr>
                </table>
                <div class="table-scroller">
                    <table class="contents-scroll-table">
                        <template v-for="(item, index) in params">
                            <tr class="list-table-row" style="cursor: default;" :key="'row_' + index">
                                <td>{{ index + 1 }}</td>
                                <td colspan="2">
                                    <v-select class="v-select-border" :clearable="false" :options="options_item_category" :reduce="options_item_category => options_item_category.id" v-model="item.item_category" @click="searchItem(index)">
                                        <template #no-options>データが存在しません</template>
                                    </v-select>
                                </td>
                                <td>
                                    <input type="number" v-model="item.control_number" @input="item.control_number = inputNumberMaxLength(item.control_number, 5)" @blur="searchItem(index)">
                                </td>
                                <td>
                                    <span v-if="item.name !== null || item.name !== ''" v-tooltip="item.name">{{ item.name }}</span>
                                </td>
                                <td>
                                    <v-select class="v-select-border" :options="options_office" :reduce="options_office => options_office.id" v-model="item.shipping_office_id">
                                        <template #no-options>データが存在しません</template>
                                    </v-select>
                                </td>
                                <td>
                                    <v-select class="v-select-border" :options="options_office" :reduce="options_office => options_office.id" v-model="item.warehousing_office_id">
                                        <template #no-options>データが存在しません</template>
                                    </v-select>
                                </td>
                                <img :style="{'opacity': params.length === 1 ? 0 : 1}" class="table-close-button" src="../assets/img/delete.png" @click="deleteRow(index)">
                            </tr>
                        </template>
                    </table>
                </div>
			</div>
		</div>
		<div class="buttons">
            <button class="button-cancel" v-if="is_cancel" @click="is_cancel_modal = true">取消</button>
            <button class="button-prev" onclick="javascript:history.back()">戻る</button>
            <button class="button-next" @click="register()">更新</button>
		</div>
        <div id="modal" v-show="is_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_modal = false">
                    <div id="modal-text">
                        入力に不備があります。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_modal = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_cancel_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_cancel_modal = false">
                    <div id="modal-text">
                        取消処理を行うと、元に戻すことは出来ません。
                        本当に取消処理を行って宜しいですか？
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" @click="is_cancel_modal = false">いいえ</button>
                    <button id="yes" @click="cancel()">はい</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_control_modal">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_control_modal = false">
                    <div id="modal-text">
                        入力した管理番号は存在しません。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_control_modal = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_stock_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_stock_error = false">
                    <div id="modal-text">
                        入力した在庫は貸出中です。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_stock_error = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_stock_repair_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_stock_repair_error = false">
                    <div id="modal-text">
                        入力した在庫は修理中です。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_stock_repair_error = false">閉じる</button>
                </div>
            </div>
        </div>
        <div id="modal" v-show="is_stock_delete_error">
            <div id="modal-background">
                <div id="modal-content">
                    <div id="modal-title">
                        注意
                    </div>
                    <img src="../assets/img/close.png" @click="is_stock_delete_error = false">
                    <div id="modal-text">
                        対象在庫は滅失処理済の為、選択出来ません。
                    </div>
                </div>
                <div id="modal-buttons">
                    <button id="no" style="width: 100%" @click="is_stock_delete_error = false">閉じる</button>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { mapState } from 'vuex'
import { inputNumberMaxLength } from '../util';
export default {
    components: {
        vSelect,
    },
	data() {
		return {
            params: [
                {
                    item_category: null,
                    control_number: null,
                    stock_id: null,
                    shipping_office_id: null,
                    warehousing_office_id: null,
                    name: null,
                }
            ],
            options_office: [],
            options_item_category: [],
            is_modal: false,
            is_cancel: false,
            is_cancel_modal: false,
            delete_ids: [],
            is_control_modal: false,
            is_stock_error: false,
            is_stock_repair_error: false,
            is_stock_delete_error: false,
		}
	},
	created() {
        this.getOfficeList()
        this.getItemMasterList()
        this.restoreParams()
	},
	mounted() {

	},
	methods: {
        register() {
            let params = {
                slip: this.params,
                ...this.updateParams,
            }

            // 入力チェック
            let error = []
            params.slip.filter(item => {
                if (item.stock_id === null && item.shipping_office_id === null && item.warehousing_office_id === null) {
                    error.push(true)
                }
            })

            if (error.length === 0) {
                this.delete_ids.filter(item => {
                    if (item !== undefined && item !== null) {
                        this.$store.dispatch('loading/startLoad')
                            .then(() => this.$store.dispatch('slip/deleteAPi', {id: item, type: 2}))
                            .then(() => {
                                this.$store.commit('slip/setUpdateParams', null)
                                this.$store.dispatch('loading/endLoad')
                            })
                    }
                })

                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('slip/postSlipGroupApi', params))
                    .then(() => {
                        if (this.slipApiStatus) {
                            this.$store.commit('slip/setUpdateParams', null)
                            this.$store.dispatch('loading/endLoad')
                            this.$router.push('slip')
                        }
                    })
            }
            else {
                this.is_modal = true
            }
        },
        addRow() {
            this.params.push({
                control_number: null,
                stock_id: null,
                shipping_office_id: null,
                warehousing_office_id: null,
            })
        },
        deleteRow(index) {
            this.params.splice(index, 1)
            this.params.splice(index, 1)
        },
        // 取引先IDから取引先名を取得する
		getItemCategoryNameById(id) {
			let result = ""
			this.customerMasterList.list.filter(item => {
				if (item.id === id) {
					result = item.floor
				}
			})
			return result
		},
        searchItem(index) {
            // 管理番号が入力されているなら検索する
            if (this.params[index].control_number !== null && this.params[index].control_number !== "") {
                this.$store.dispatch('loading/startLoad')
                    .then(() => this.$store.dispatch('stock/getStockApi', {control_number: this.params[index].control_number}))
                    .then(() => {
                        if (this.stockList.list.length > 0) {
                            if (this.stockList.list[0].status === 1) {
                                this.params[index].stock_id = this.stockList.list[0].id
                                this.params[index].name = this.stockList.list[0].name
                            }
                            else if (this.stockList.list[0].status === 2) {
                                // 貸出済み在庫なのでエラー
                                this.is_stock_error = true
                                this.params[index].stock_id = null
                                this.params[index].name = null
                            }
                            else if (this.stockList.list[0].status === 3) {
                                // 修理中在庫なのでエラー
                                this.is_stock_repair_error = true
                                this.params[index].stock_id = null
                                this.params[index].name = null
                            }
                            else if (this.stockList.list[0].status === 6) {
                                // 滅失在庫なのでエラー
                                this.is_stock_delete_error = true
                                this.params[index].stock_id = null
                                this.params[index].name = null
                            }
                        }
                        else {
                            this.is_control_modal = true
                        }
                        this.$store.dispatch('loading/endLoad')
                    })
            }
        },
        // 検索用事業所一覧取得
		getOfficeList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('officeMaster/getOfficeMasterApi', {}))
                .then(() => {
					this.officeMasterList.office.filter(item => {
                        if (item.name !== "") {
                            this.options_office.push({label: item.name, id: item.id})
                        }
					})
                    this.$store.dispatch('loading/endLoad')
				})
		},
        restoreParams() {
            this.params = []
            let update = []
            this.updateParams.slip.filter((item, index) => {
                this.params.push({
                    id: item.id,
                    shipping_office_id: item.shipping_office_id,
                    warehousing_office_id: item.warehousing_office_id,
                })
                if (item.control_number !== undefined && item.control_number.length === 10) {
                    this.params[index].item_category = item.control_number.split('-')[0]
                    this.params[index].control_number = item.control_number.split('-')[1]
                }
                else {
                    this.params[index].item_category = item.item_category
                    this.params[index].control_number = item.control_number
                }
                this.searchItem(index)
                update.push(item.update_flag)
            })
            // update_flagがすべてtrueなら取消が可能
            if (update.indexOf(false) === -1) {
                this.is_cancel = true
            }
        },
        cancel() {
            this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('slip/deleteAPi', {id: this.$route.query.id, type: 1}))
                .then(() => {
                    this.$store.commit('slip/setUpdateParams', null)
					this.$store.dispatch('loading/endLoad')
                    this.$router.push('slip')
				})
        },
        inputNumberMaxLength(value, max) {
			return inputNumberMaxLength(value, max)
		},
        // 商品マスタ取得
        getItemMasterList() {
			this.$store.dispatch('loading/startLoad')
                .then(() => this.$store.dispatch('itemMaster/getItemMasterApi'))
				.then(() => {
					this.itemMasterList.item.filter(item => {
						this.options_item_category.push({label: item.name, id: item.id + "-" + ("0000" + item.item_category_id).slice(-4)})
					})
                    this.getItemCategoryMasterList()
				})
		},
        // 商品カテゴリマスタ取得
        getItemCategoryMasterList() {
            this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi')
            .then(() => {
                this.itemCategoryMasterList.category.filter(item => {
                    this.options_item_category.push({label: "(種別: " + item.id + ") " + item.name, id: ("0000" + item.id).slice(-4)})
                })
                this.$store.dispatch('loading/endLoad')
            })
        },
	},
    computed: {
        ...mapState({
            updateParams: state => state.slip.updateParams,
            customerMasterList: state => state.customerMaster.customerMasterList,
            stockList: state => state.stock.stockList,
            officeMasterList: state => state.officeMaster.officeMasterList,
            slipApiStatus: state => state.slip.apiStatus,
            itemMasterList: state => state.itemMaster.itemMasterList,
            itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
        })
    }
}
</script>

<style scoped src="../assets/css/slip.css"></style>
<style scoped src="../assets/css/list_table.css"></style>
<style scoped src="../assets/css/modal.css"></style>
<style scoped>
.list-table-row td {
    text-overflow: initial;
}
</style>