<template>
	<div>
		<div id="main-text">
			<span>商品カテゴリーマスタ</span>
		</div>
		<div id="main-contents">
			<div id="list-contents">
				<table class="list-table">
					<tr class="list-table-header">
						<th>カテゴリー名</th>
					</tr>
				</table>
				<div class="list-table-scroller">
					<table class="list-table-contents">
						<template v-if="itemCategoryMasterList !== null">
							<template v-for="(item, index) in itemCategoryMasterList.category">
								<tr class="list-table-row" :key="'item_category_master_' + index" @click="toUpdate(item.id)">
									<td>
										<span v-if="item.name === null || item.name === ''" v-tooltip="'-'">-</span>
										<span v-else v-tooltip="item.name">{{ item.name }}</span>
									</td>
								</tr>
							</template>
						</template>
					</table>
				</div>
				<div class="pager" v-if="page_num !== 0">
					<template v-if="current_page !== 1">
						<span class="page" @click="pager(current_page - 1)">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/left_arrow.png">
						</span>
					</template>
					<template v-if="page_num < 5">
						<template v-for="page in page_num">
							<span :class="{'page': true, 'current': current_page === page}" :key="'page_' + page" @click="pager(page)">
								<span>{{ page }}</span>
							</span>
						</template>
					</template>
					<template v-else>
						<template v-if="current_page < 4">
							<span :class="{'page': true, 'current': current_page === 1}" @click="pager(1)">
								<span>{{ 1 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 2}" @click="pager(2)">
								<span>{{ 2 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 3}" @click="pager(3)">
								<span>{{ 3 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 4}" @click="pager(4)">
								<span>{{ 4 }}</span>
							</span>
							<span :class="{'page': true, 'current': current_page === 5}" @click="pager(5)">
								<span>{{ 5 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 2) <= page_num">
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
							<span class="page" @click="pager(current_page + 2)">
								<span>{{ current_page + 2 }}</span>
							</span>
						</template>
						<template v-else-if="(current_page + 1) <= page_num">
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
							<span class="page" @click="pager(current_page + 1)">
								<span>{{ current_page + 1 }}</span>
							</span>
						</template>
						<template v-else-if="current_page === page_num">
							<span class="page" @click="pager(current_page - 4)">
								<span>{{ current_page - 4 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 3)">
								<span>{{ current_page - 3 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 2)">
								<span>{{ current_page - 2 }}</span>
							</span>
							<span class="page" @click="pager(current_page - 1)">
								<span>{{ current_page - 1 }}</span>
							</span>
							<span class="page current">
								<span>{{ current_page }}</span>
							</span>
						</template>
					</template>
					<template v-if="current_page !== page_num">
						<span class="page" @click="pager(current_page + 1)">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
					<template v-else>
						<span class="page" style="background-color: #BCBCCB;">
							<img src="../assets/img/right_arrow.png">
						</span>
					</template>
				</div>
			</div>
			<div class="contents-filter">
				<div class="contents">
					<div class="filter-title">
						詳細検索
					</div>
					<div class="filter">
						<div class="title">
							カテゴリー名
						</div>
						<div class="input">
							<input type="text" placeholder="カテゴリー名" maxlength="64" v-model="filter_params.name">
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="buttons">
			<router-link to="item_category_master_regist">
				<button class="button-next">新規登録</button>
			</router-link>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	data() {
		return {
			filter_params: {
				name: null,
			},
			page_num: 0,
			current_page: 1,
			page_start: 1,
			page_end: 1,
		}
	},
	created() {
		// 関連ページからの復帰で検索条件を保持
		if (this.itemCategoryFilterParams !== null) {
			this.filter_params = this.itemCategoryFilterParams
		}
		// リロード時にページを保持するように
		let params = this.filter_params
		params.page = 1
		if (this.$route.query.page !== undefined) {
			this.current_page = Number(this.$route.query.page)
			params.page = this.current_page
		}
		this.getItemCategoryMasterList(params)
	},
	mounted() {

	},
	methods: {
		// 商品カテゴリマスタ一覧取得
		async getItemCategoryMasterList(params) {
			this.$store.dispatch('loading/startLoad')
                .then(async () => {
					await this.$store.dispatch('itemCategoryMaster/getItemCategoryMasterApi', params)
					if (this.itemCategoryMasterApiStatus) {
						if (this.itemCategoryMasterList.page !== undefined) {
							this.page_num = this.itemCategoryMasterList.page

							// 最大ページ数が最大表示数の5を上回っていた場合
							if (this.page_num > 5) {
								this.page_start = this.current_page - 2 < 0 ? 1 : this.current_page - 2
								this.page_end = this.page_end > this.current_page + 2 ? this.page_num : this.page_end > this.current_page + 2
							}
							else {
								this.page_start = 1
								this.page_end = this.page_num
							}
						}
					}
				})
                .then(() => this.$store.dispatch('loading/endLoad'))
		},
		// ページ切り替え
		pager(page) {
			this.$router.push('item_category_master?page=' + page)
			this.current_page = page
			let params = this.filter_params
			params.page = this.current_page
			this.getItemCategoryMasterList(params)
		},
		// 更新画面へ
		toUpdate(id) {
			this.$router.push('item_category_master_update?id=' + id)
		},
	},
	computed: {
		...mapState({
			itemCategoryMasterApiStatus: state => state.itemCategoryMaster.apiStatus,
			itemCategoryMasterList: state => state.itemCategoryMaster.itemCategoryMasterList,
			itemCategoryFilterParams: state => state.itemCategoryMaster.filterParams,
		})
	},
	watch: {
		// 検索結果取得
		filter_params: {
			handler(value) {
				let params = value
				params.page = 1
				this.current_page = 1
				this.page_num = 0
				this.getItemCategoryMasterList(params)
				this.$store.commit('itemCategoryMaster/setFilterParams', value)
			},
			deep : true,
		}
	}
}
</script>

<style scoped src="../assets/css/item_category_master.css"></style>
<style scoped src="../assets/css/list_table.css"></style>